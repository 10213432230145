import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Injectable()
export class FiliaisService extends BaseService {
    constructor(private http: HttpClient, 
                spinner: NgxSpinnerService, 
                private ngxLoader: NgxUiLoaderService) { 
        super();
        this.spinner = spinner; 
    }

    getFiliais(): Observable<any[]> {
        return this.http
            .get<any[]>(this.UrlCDN + "cidades/cidades.json");
    }

    getFilial(): Observable<any> {
        this.ngxLoader.start('filialSpinner');        
        return this.http
            .get<any>(`${this.UrlCdn}/filial/${this.FilialId}.json`)
            .pipe(res => {
                this.ngxLoader.stop('filialSpinner');
                return res;
            });
    }

    getFilialMapa(): Observable<any> {
        return this.http
            .get<any>("../../../../assets/mapafilial/mapafilial26.json");
    }

    getSetoresMapa(): Observable<any> {
        return this.http
            .get<any>("../../../../assets/mapafilial/setoresSaoPaulo.json");
    }

    getDisponibilidadeSetoresMapa() : Observable<any> {
        this.ngxLoader.start('disponibilidadeSpinner');
        //this.spinner.show('disponibilidadeSpinner');        
        return this.http.get(`${this.BaseUrlFunction}geojson/disponibilidade/v1/setor?code=${this.FunctionCodeAuthorizationGeoJson}`)
                    .pipe(
                        res => {
                            
                            return res;
                        }
                    );
    }

    getTarifasByArea(idArea: number){
        this.ngxLoader.start('filialSpinner');        
        return this.http
            .get<any>(`${this.UrlCdn}/areas/${idArea}.json`)
            .pipe(res => {
                this.ngxLoader.stop('filialSpinner');
                return res;
            });
    }

    getTermoDeUso(lang: string){
        return this.http.get(`${this.UrlCdn}/zadsp/termosdeuso-zad-${lang}.html`, {responseType: 'text'})
    }  

    getRegrasZADSP(){
        return this.http.get(`${this.UrlCdn}/zadsp/regras-descricao.json`);
    }

    getOpcoesCompras(){
        return this.http.get(`${this.UrlCdn}/zadsp/opcoes-compra.json`);
    }

    getConsultaCadPage(){
        var consultaCadWindow = window.open(`${this.UrlCdn}/zadsp/ConsultaCad.html`,  "_blank", "width=auto,height=auto,scrollbars=1");
        consultaCadWindow.open();       
    }

    getConsultaCadCetPage(){
        var consultaCadWindow = window.open(environment.UrlConsultaAutenticacaoCadCet,  "_blank", "width=auto,height=auto,scrollbars=1");
        consultaCadWindow.open();        
    }
}