import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BoletoImprimir } from 'src/app/model/boleto';

@Component({
    selector: 'app-boleto-pago',
    templateUrl: './boleto-pago.component.html'
})

export class BoletoPagoComponent {

    @Input()
    boletoPago: BoletoImprimir;

    constructor(public activeModal: NgbActiveModal){
    }

    formatData (data) {
        
        if(data.length == 8){
            let ano = data.toString().substring(0, 4);
            let mes = data.toString().substring(4, 6);
            let dia = data.toString().substring(6, 8);

            let novaData = new Date(ano, parseInt(mes) - 1, dia);
            return novaData; 
        } else {
            return null;
        }
    }
}