import { CommonModule } from '@angular/common';
import { LgpdComponent } from '../lgpd/lgpd.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { LanguageTranslationModule } from '../language-translation/language-translation.module';
import { HeaderComponent } from '../header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
//import { GoogleMapsModule, GoogleMap } from '@angular/google-maps';
//import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgBrazil } from 'ng-brazil';
//import { AgmCoreModule } from '@agm/core';

import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { NgxMaskModule, IConfig } from 'ngx-mask';

//import { NgxUiLoaderModule } from 'ngx-ui-loader';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

import { CadastroBasicoClienteComponent } from '../../components/cadastroCliente/cadastro-basico-cliente.component';
import { DadosBasicoClienteComponent } from '../../components/dadosBasicoCliente/dados-basico-cliente.component';
import { CadastroCompletoClientePFComponent } from '../../components/cadastroCompletoCliente/cadastro-completo-cliente-pf.component';
import { PoliticaPrivacidadeComponent } from '../../components/politicaPrivacidade/politica-privacidade.component';
import { TermoUsoComponent } from '../../components/termosUso/termo-uso.component';
import { TelefonesListaComponent } from '../../components/telefones/telefones-lista.component';
import { TelefoneCadastroComponent } from '../../components/telefones/telefone-cadastro.component';
import { CartaoListaComponent } from '../../components/cartao/cartao-lista.component';
import { CartaoCadastroComponent } from '../../components/cartao/cartao-cadastro.component';
import { SaldoListaComponent } from '../../components/saldo/saldo-lista.component';
import { CadastroCADComponent } from '../../components/cad/cadastro-cad.component';
import { CompraCreditoComponent } from '../../components/credito/compra-credito.component';
import { UtilizacaoCADComponent } from '../../components/cad/utilizacao-cad.component';
import { AtivacaoCADComponent } from '../../components/cad/ativacao-cad.component';
import { VeiculoListaComponent } from '../../components/veiculo/veiculo-lista.component';
import { VeiculoCadastroComponent } from '../../components/veiculo/veiculo-cadastro.component';
import { ExtratoListaComponent } from '../../components/extrato/extrato-lista.component';
import { ExtratoDetalheComponent } from '../../components/extrato/extrato-detalhe.component';
import { TextDirective } from '../../text-only.directive';
import { NumberDirective } from '../../number-only.directive';
import { AtivacaoCADVeiculoComponent } from '../../components/cad/ativacao-cad-veiculo.component';
import { AtivacaoCADSetorComponent } from '../../components/cad/ativacao-cad-setor.component';
import { SearchComponent } from '../../components/cad/ativacao-cad-search.component';
import { CadastroFrotistaComponent } from '../../components/cadastroFrotista/cadastroFrotista.component';
import { FAQComponent } from '../../components/faq/faq.component';
import { FooterComponent } from '../footer/footer.component';
import { CartaoListaOpcaoComponent } from '../../components/cartao/cartao-lista-opcao.component';
import { UsuarioFrotistaComponent } from '../../components/cadastroFrotista/usuarioFrotista.component';
import { EdicaoFrotistaComponent } from '../../components/cadastroFrotista/edicaoFrotista.component';
import { ExtratoFrotistaAdminComponent } from '../../components/extratoFrotistaAdmin/extrato-lista-frotista-admin.component';
import { ExtratoDetalheFrotistaAdminComponent } from '../../components/extratoFrotistaAdmin/extrato-detalhe-frotista-admin.component';
import { NbDatepickerModule, NbCardModule } from '@nebular/theme';
import { BoletoPrintComponent } from '../../components/boleto/boleto-print.component';
import { BoletoListaComponent } from '../../components/boleto/boleto-lista.component';
import { BoletoPagoComponent } from '../../components/boleto/boleto-pago.component';
import { CadastroCompletoSenhaComponent } from '../../components/cadastroCompletoCliente/cadastro-completo-senha.component';
import { AgmCoreModule } from '@agm/core';
import { MaterialModule } from '../material/material-module';
import { IsNullPipe } from '../../pipes/null-pipe';
import { RegraZadInformativoComponent } from '../../components/regraZadInformativo/regra-zad-informativo.component';
import { AuthInterceptor } from '../../guard/auth.interceptor';
import { NgxCurrencyModule } from "ngx-currency";
import { CadastroLeadFrotistaComponent } from '../../components/cadastroLeadFrotista/cadastro-lead-frotista.component';
import { EsqueceuSenhaComponent } from '../../components/esqueceuSenha/esqueceu-senha.component';
import { RelatorioMovimentacoesComponent } from '../../components/relatorio/relatorio-movimentacoes.component';
import { ConsultaCadComponent } from 'src/app/login/consulta-cad.component';
import { RelatorioMovimentacoesDetalheComponent } from '../../components/relatorio/relatorio-movimentacoes-detalhe.component';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(ptBr);

const maskConfig: Partial<IConfig> = {
    validation: false,
  };

@NgModule({
  imports: [
    CommonModule
    ,HttpClientModule
    ,RouterModule
    ,NgbModule
    ,NbCardModule
    ,FormsModule
    ,ReactiveFormsModule
    ,LanguageTranslationModule
    ,TextMaskModule
    //,GoogleMapsModule
    //,GooglePlaceModule
    ,NgBrazil
    ,NgxCurrencyModule
    ,NgWizardModule.forRoot(ngWizardConfig)
    ,NbDatepickerModule
    ,NgxMaskModule.forRoot(maskConfig)
    ,AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBJr4KGZ0a7WvWTvSuCY1ZQCVjebp98j5M'      
    })
    ,MaterialModule
    //,NgxUiLoaderModule    
   ],
  declarations: [
    NumberDirective,
    TextDirective,
    CadastroBasicoClienteComponent,
    CadastroLeadFrotistaComponent,
    EsqueceuSenhaComponent,
    HeaderComponent,
    FooterComponent,
    LgpdComponent,
    DadosBasicoClienteComponent,
    CadastroCompletoClientePFComponent,
    PoliticaPrivacidadeComponent,
    TermoUsoComponent,  
    ConsultaCadComponent, 
    RegraZadInformativoComponent,
    TelefonesListaComponent,
    TelefoneCadastroComponent,
    CartaoListaComponent,
    CartaoCadastroComponent,
    SaldoListaComponent,
    CadastroCADComponent,
    CompraCreditoComponent,
    UtilizacaoCADComponent,
    AtivacaoCADComponent,
    VeiculoListaComponent,
    VeiculoCadastroComponent,
    ExtratoListaComponent,
    ExtratoDetalheComponent,
    AtivacaoCADVeiculoComponent,
    AtivacaoCADSetorComponent,
    SearchComponent,
    CadastroFrotistaComponent,
    UsuarioFrotistaComponent,
    FAQComponent,
    CartaoListaOpcaoComponent,
    EdicaoFrotistaComponent,
    ExtratoFrotistaAdminComponent,
    ExtratoDetalheFrotistaAdminComponent,
    BoletoPrintComponent,
    BoletoListaComponent,
    BoletoPagoComponent,
    CadastroCompletoSenhaComponent,
    IsNullPipe,
    RelatorioMovimentacoesComponent,
    RelatorioMovimentacoesDetalheComponent   
  ],
  exports: [
    CadastroBasicoClienteComponent,
    CadastroLeadFrotistaComponent,
    EsqueceuSenhaComponent,
    HeaderComponent,
    FooterComponent,
    LgpdComponent,
    DadosBasicoClienteComponent,
    CadastroCompletoClientePFComponent,
    PoliticaPrivacidadeComponent,
    TermoUsoComponent,  
    ConsultaCadComponent,
    RegraZadInformativoComponent,
    TelefonesListaComponent,
    TelefoneCadastroComponent,
    CartaoListaComponent,
    CartaoCadastroComponent,
    SaldoListaComponent,
    CadastroCADComponent,
    CompraCreditoComponent,
    UtilizacaoCADComponent,
    AtivacaoCADComponent,
    VeiculoListaComponent,
    VeiculoCadastroComponent,
    ExtratoListaComponent,
    ExtratoDetalheComponent,
    AtivacaoCADVeiculoComponent,
    AtivacaoCADSetorComponent,
    SearchComponent,
    CadastroFrotistaComponent,
    UsuarioFrotistaComponent,
    FAQComponent,
    CartaoListaOpcaoComponent,
    EdicaoFrotistaComponent,
    ExtratoFrotistaAdminComponent,
    ExtratoDetalheFrotistaAdminComponent,
    BoletoPrintComponent,
    BoletoListaComponent,
    BoletoPagoComponent,
    CadastroCompletoSenhaComponent,
    RelatorioMovimentacoesComponent,
    RelatorioMovimentacoesDetalheComponent
  ],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' }
  ]
})

export class CustomModule {
  
}