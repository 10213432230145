<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'SearchCadLabel' | translate}}</h4>
</div>
<div class="modal-body" style="font-size: 12px;">
    <div class="container">
        <div [innerHTML]="htmlBuscaCadPage" ></div>       
    </div>
</div>
<div class="modal-footer mt-4">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
</div>