export class Compra {
    compra: {
        dispositivo: {
            fingerprint: string;
            uuid: string;
            lat: string;
            lng: string;
            network: {
                ip: string;
                subnet: string;
                type: string;
            }
        };
        valor: number,        
        cartao: {
            token: string;
            nome: string;
            numero: string;
            lembrar: boolean;
            validade: {
                mes: string;
                ano: string;
            };
            bandeira: string;
            csc: string;
            portador : string;
        }
    }
}

export class CompraFrotista {
    compra: {
        dispositivo: {
            fingerprint: string;
            uuid: string;
            lat: string;
            lng: string;
            network: {
                ip: string;
                subnet: string;
                type: string;
            }
        };
        valor: number,
        frotista: {
            IDEmpresaFrotistaCentroCusto: number;
        },
        cartao: {
            token: string;
            nome: string;
            numero: string;
            lembrar: boolean;
            validade: {
                mes: string;
                ano: string;
            };
            bandeira: string;
            csc: string;
        }
    }
}