import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Cartao, CartaoRetorno, CartaoValidacaoBinRetorno } from 'src/app/model/cartao';
import { environment } from 'src/environments/environment';

@Injectable()
export class CartaoService extends BaseService {
    constructor(private http: HttpClient) { super() }

    cadastroCartaoEvent: EventEmitter<void> = new EventEmitter();

    atualizarCartoes = () => this.cadastroCartaoEvent.emit();

    postCartao(param: Cartao, documento: string, tokenRecaptcha:string): Observable<any> {
        return this.http
            .post(this.UrlClienteFull + "getnet/v1/" + documento + "/cartao/estapar", JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'recaptchaToken':tokenRecaptcha})
            }).pipe(
                //timeout(10000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    getCartoes(document: string): Observable<CartaoRetorno[]> {
        return this.http
            .get(this.UrlClienteFull + "getnet/v1/" + document + "/cartoes/estapar", {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(
                map(super.extractDataCartao),
                catchError(super.serviceError)
            )
    }

    getCartao(documento: string, idCartao: string): Observable<any>{
        return this.http
            .get(this.UrlClienteFull + "getnet/v1/" + documento + "/cartao/estapar?uuidCartaoEstapar=" + idCartao, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            )
    }

    deleteCartao(documento: string, idCartao: string): Observable<any>{
        return this.http
            .delete(this.UrlClienteFull + "getnet/v1/" + documento + "/cartao/estapar?uuidCartaoEstapar=" + idCartao, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            )
    }

    // getRegexCartoes(): Observable<any> {
    //     return this.http
    //             .get(`${this.UrlBaseBroker}/getnet/v1/cartao/bandeira?regex`)
    //             .pipe(
    //                 map(super.extractData),
    //                 catchError(super.serviceError)
    //             )
    // }

    getValidarBinCartao(binCartao: string) : Observable<CartaoValidacaoBinRetorno> {
        return this.http
                    .get(`${environment.UrlBaseBroker}/getnet/v1/${this.obterUsuario().documento}/cartao/validar/bin/${binCartao}`, {
                            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente }) 
                    })
                    .pipe(
                        map(super.extractData),
                        catchError(super.serviceError)
                    );
    }

    getValidarCartao(cartao: Cartao, idBandeira: number) : Observable<any> {
        return this.http
                    .get(`${environment.UrlBaseBroker}/getnet/v1/${this.obterUsuario().documento}/cartao/validar?numero=${cartao.numero}&portador=${cartao.portador}&bandeira=${idBandeira}&cvv=${cartao.cvv}&cpfCnpj=${cartao.cpfCnpj}&mesExpiracao=${cartao.mesExpiracao}&anoExpiracao=${cartao.anoExpiracao}&isFrotista=false`, {
                        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente }) 
                     })
                    .pipe(
                        map(super.extractData),
                        catchError(super.serviceError)
                    );
    }
}