import { NgbActiveModal, NgbModalConfig, NgbDatepickerI18n, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { ClienteService } from '../../service/cliente.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cadastro-lead-frotista',
    templateUrl: './cadastro-lead-frotista.component.html',
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
                      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  })
  
  export class CadastroLeadFrotistaComponent {
    cadastroLeadFrotista: FormGroup;
    dateNull?: NgbDate;
    aderiuCadastroLeadFrotista: EventEmitter<void> = new EventEmitter();

    public tiposQuantidadeFrotas: {id: string, desc: string}[] = [
        { id: "1 à 5", desc: "De 1 à 5 veículos"},
        { id: "5 à 10", desc: "De 5 à 10 veículos"},
        { id: "10 à 20", desc: "De 10 à 20 veículos"},
        { id: "20 à 50", desc: "De 20 à 50 veículos"},
        { id: "50 à 100", desc: "De 50 à 100 veículos"},
        { id: "mais de 100", desc: "Mais de 100 veículos"}
    ];

    public aceiteTermoUso: boolean = false;

    public mask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 
    constructor(
        private spinner: NgxSpinnerService,
        config: NgbModalConfig
        ,public activeModal: NgbActiveModal
        ,private fb: FormBuilder
        ,private clienteService: ClienteService
        ,private router: Router
        ,private translate: TranslateService) {

            config.backdrop = 'static';
            config.keyboard = false;

            this.cadastroLeadFrotista = this.fb.group({
                FilialID: [environment.FilialId, []],
                EmpresaNome: [clienteService.obterUsuario().nome],
                Cnpj: [clienteService.obterUsuario().documento, []],
                NomeResponsavelEmpresa: ['', [Validators.required]],
                Telefone1: ['', [Validators.required]],
                HorarioContato: ['',[]],
                Email: ['',[Validators.required, Validators.email]],
                QtdeVeiculos: ['1 à 5', [Validators.required]]                
            }, { updateOn: 'change'});
        }

        cancelarCadastroLead(){
            this.activeModal.close();
            this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
        }

        salvar() {

            if(!this.cadastroLeadFrotista.valid){
                  Swal.fire({
                            text: this.translate.instant('RequiredFields'),
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',                 
                            confirmButtonColor: '#4a9f42',
                            width: 'auto'
                          });      
                return;
            }
            
            //this.spinner.show();
         
            this.clienteService.postCadastroLeadFrotista(this.cadastroLeadFrotista.getRawValue())
            .subscribe(
                result => {
                    //this.spinner.hide();                    
                    this.activeModal.close();
                    Swal.fire({
                        html: `
                        <div>
                            Em breve entraremos em contato para finalização do cadastro.<br /> 
                            Confira a lista de documentos que nossa equipe de atendimento solicitará:<br /><br />
                            
                            <ul style="text-align: left;">
                                <li>Contrato Social</li>
                                <li>Cartão CNPJ</li>
                                <li>Cópia do CPF ou CNH do colaborador que será responsável pela administração <br /> da conta no sistema Zona Azul Digital</li>
                                <li>Termo de Credenciamento - assinado e reconhecido firma <br /> do representante legal da empresa - Baixe o termo <a target="_blank" href="https://drive.google.com/file/d/1678AUQdSuGXZrbl3DZM8NcYoX2vO8l16/view?usp=sharing">aqui.</a></li>
                            </ul>
                        </div>
                        `,
                        //text:  result.Message,
                        titleText: this.translate.instant('LeadAfterSave'),
                        icon: 'success',                 
                        confirmButtonColor: '#4a9f42',
                        width: 'auto'
                        }).then(res => {
                            this.aderiuCadastroLeadFrotista.emit();
                        }                            
                        );                       
                },
                fail => {
                    //this.spinner.hide();
                    Swal.fire({
                        text:  fail.Message,
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'info',                 
                        confirmButtonColor: '#4a9f42',
                        width: 'auto'
                        });             
                }
            )                  
        }       

        onlyNumber(param: string) {
            return param.replace(/\D/g, '');
        }

        onlyLetterNumber(param: string) {
            return param.replace(/[^A-Za-z0-9]+/g, '')
        }

        padLeft(num:number, size:number): string {
            let s = num+"";
            while (s.length < size) s = "0" + s;
            return s;
        }      

          dateMask(event: any) {
            var v = event.target.value;
            if (v.match(/^\d{2}$/) !== null) {
              event.target.value = v + '/';
            } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
              event.target.value = v + '/';
             }
          }
  }