import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(private router: Router) {}
    canActivateChild(): boolean {
        return false;
    }
    canLoad(): boolean {

        this.router.navigate(['/login']);
        return false;
    }

    canActivate(): boolean {
        if (localStorage.getItem('isLoggedin')) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
