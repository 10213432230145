import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class SMSService extends BaseService {
    constructor(private http: HttpClient) { super() }

    enviar(celular: string): Observable<any> {
        
        let param = {               
                "documento": this.obterUsuario().documento,
                "telefone": `55${celular}`,
                "mensagem": "Digite o código: {Codigo} para confirmar o cadastro do seu celular." 
        };

        return this.http
            .post(`${this.BaseUrlFunction}site/v1/confirmacaosms?code=${environment.FuncCodeAuthSms}`, JSON.stringify(param), {
                headers: new HttpHeaders({'Content-Type': 'application/json'})
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }
}