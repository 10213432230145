<div id="posiciona" class="cookie-policy active" *ngIf="!isHidden">
    <section style="margin-top: 23px;">
        <div class="container-fluid">
            <div class="row flex">
                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xl-10">
                    <p><span [innerHTML]="'Textos.LGPD' | translate"></span></p>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 text-right">
                    <a href="javascript:void(0);" (click)="aceiteLgpd()" class="btn btn-white btn-outline dismiss">{{ 'I Agree' | translate }}</a>
                </div>
            </div>
        </div>
        <span class="hidden"></span>
    </section>
</div>