<div class="modal-header">
    
    <span style="color: #fff;">
     {{ 'ModalTitleConfirmActivation' | translate }}
    </span> 

</div>

<div class="modal-body" style="padding: 0px !important;">
 <div class="form-group" style="padding: 0px !important; margin: 0px !important;">
     <agm-map (mapReady)="onMapReady($event)"
              [latitude]="latitude" 
              [zoom]="zoom" 
              [longitude]="longitude"
              [disableDefaultUI]="true"
              [usePanning]="true">
         <agm-data-layer [id]="'setores'" [geoJson]="setoresFeatureMap"
                         (layerClick)="layerSetoresClick($event)"
                         [style]="styleDataLayerOptions">

                         <agm-info-window #infoWindow
                         [disableAutoPan]="true" 
                         [isOpen]="infoWindowVisible"
                         [latitude]="latitude" [longitude]="longitude"                                                
                         (infoWindowClose)="infoWindowClose()">
                         
                         <div class="infoSetor">
                               
                                 <ng-container *ngIf="tipoMarkerInfoGlobal == 'Setor'"
                                 [ngTemplateOutlet]="setortemplate"
                                 [ngTemplateOutletContext]="dadosSetor">
                                     </ng-container>                                           
                         </div>
                        
                        </agm-info-window>

         </agm-data-layer>                 
             
     </agm-map>  
   
 </div>
</div>

<div class="modal-footer bg-ligth">    
<button type="button" class="btn btn-outline-secondary" (click)="cancelar()">{{ 'Cancel' | translate }}</button>
<!-- <button type="submit" class="btn btn-outline-success">{{ 'Save' | translate}}</button> -->
</div>

<!-- <div class="boxUtilizacaoCadPassos-info-escolhaVeiculo" [hidden]="passoAtivacaoCad !== 1">
    <div class="row">                           
        <div class="col-4 col-md-4 col-lg-4 col-xl-4">
            <span style="color: #FFF;">{{'ChooseVehicle' | translate}}:</span>                              
        </div>
        <div class="col-6 col-md-6 col-lg-6 col-xl-6">
            <a (click)="openCars()" 
                placement="right" 
                ngbTooltip="Alterar!" 
                style="cursor: pointer;color: #74E0C1">
                    <span style="color: white;">{{ !veiculo?.placa ? 'Nenhum Veículo Cadastrado ainda' : 'Placa - '  }} </span> 
                      <span [hidden]="!veiculo?.placa">
                        {{veiculo.placa | isNull: 'Escolha o veículo!'}}  {{veiculo.marca | isNull: ''}} {{veiculo.cor | isNull: ''}}
                      </span>  
                    </a>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button style="background-color: #46AAA5; text-align: center; vertical-align: middle;margin: 6px!important;color: white!important;" 
                    (click)="confirmaVeiculo()" type="button" class="btn btn-sm">{{'Proceed' | translate}}</button>
        </div>
    </div>
 </div> -->

<div style="background-color: #ffffff;
opacity: 0.95;
font-weight: 500;
font-family: Roboto, sans-serif;
box-sizing: border-box;
top: 60px;
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 5px;
left: 1%;
max-width: 600px;
position: absolute;
width: calc(100% - 10px);
z-index: 1;"
[hidden]="passoAtivacaoCad !== 0">
<form class="search-form">
 <mat-form-field class="example-full-width">
   <input type="text"                 
          aria-label="Number"
          matInput
          [formControl]="formBuscaSetorControl"
          [matAutocomplete]="auto">
          <mat-placeholder class="placeholderBuscaSetores">{{'SearchAddressUseCad' | translate}}</mat-placeholder>
   <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setSetorSelected($event.option.value)">
     <mat-option *ngFor="let option of filteredOptionsSetores | async | slice : 0 : 50" [value]="option.Descricao">
       {{option.Descricao}}
     </mat-option>
   </mat-autocomplete>
 </mat-form-field>
</form>

</div>

     <div class="boxUtilizacaoCadPassos-info-escolhaSetor" [hidden]="passoAtivacaoCad !== 0">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <div style="color: #ffffff;font-size: 1.0em;">
                   {{'InfoChooseSetor' | translate}}
                </div>
            </div> 
        </div>
     </div>

     <div style="background-color: #282B2E;
                 opacity: 0.95;
                 font-weight: 500;
                 font-family: Roboto, sans-serif;
                 padding: 15px 25px;
                 box-sizing: border-box;
                 top: 60px;
                 border: 1px solid rgba(0, 0, 0, 0.12);
                 border-radius: 5px;
                 left: 1%;
                 max-width: 400px;
                 min-height: 250px;
                 height: auto;
                 position: absolute;
                 width: calc(100% - 10px);
                 z-index: 1;" [hidden]="passoAtivacaoCad !== 2">
                 
                 <div class="row">
                     <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
                         <div style="color: #70D44B;font-size: 1.1em;padding: 6px;">
                             {{'ConfirmData' | translate}}
                         </div>
                     </div> 
                 </div>
                 <div class="row">
                     <div class="col-2 col-md-2 col-lg-2 col-xl-2">
                         <span style="color: #FFF;">{{ 'Place' | translate}}:</span>                                
                     </div>
                     <div class="col-10 col-md-10 col-lg-10 col-xl-10">
                         <a (click)="openSetores()" placement="top" ngbTooltip="Alterar!" style="cursor: pointer;color: #74E0C1">{{logradouro | isNull: 'Escolha o local!'}}</a>
                     </div>
                 </div>
                 <div class="row">                           
                     <div class="col-2 col-md-2 col-lg-2 col-xl-2">
                         <span style="color: #FFF;">{{'Vehicle' | translate}}:</span>                              
                     </div>
                     <div class="col-10 col-md-10 col-lg-10 col-xl-10">
                         <a (click)="openCars()" placement="top" ngbTooltip="Alterar!" style="cursor: pointer;color: #74E0C1">{{veiculo?.placa | isNull: 'Escolha o veículo!'}}  {{veiculo?.marca | isNull: ''}} {{veiculo?.cor | isNull: ''}}</a>
                     </div>
                 </div>
                 <div class="row">                           
                    <div class="col-2 col-md-2 col-lg-2 col-xl-2">
                        <span style="color: #FFF;">{{'Rule' | translate}}:</span>                              
                    </div>
                    <div class="col-10 col-md-10 col-lg-10 col-xl-10">
                        <a (click)="openInfoRegra()" placement="top" ngbTooltip="?" style="cursor: pointer;color: #74E0C1">{{ regraDescricao | isNull: 'regra de estacionamento não encontrada!' }}</a>
                    </div>
                </div>
                 <div class="row">
                     <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-center" style="color: #70D44B;font-size: 1.1em;padding: 6px;">
                         {{'QuantityOfCad' | translate}}
                     </div>
                 </div>
                 <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-center" style="color: #fff;font-size: 1.1em;padding: 6px;">
                        {{'Operation' | translate}}: {{horarioFuncionamentoSetor}}
                    </div>
                </div>
                 <div class="row">
                     <div class="col-6 col-md-6 col-lg-6 col-xl-6" *ngFor="let tarifaCad of setorTarifasUtilizacaoCad">
                         <div [ngClass]="tarifaCad.Selected ? 'customBlue' : 'customGreen'" style="margin-left: -10px; margin-bottom: 10px; 
                                                        color: #fff; cursor: pointer; 
                                                        border-radius:15px; 
                                                        padding-top: 12px;padding-left: 6px;padding-right: 6px;padding-bottom: 12px;" (click)="activeCad(tarifaCad);">
                             <div class="row">
                                 <div class="col-6" style="padding-right: 0px;margin-right: -20px">
                                     {{tarifaCad.Quantidade}} <span style=" 
                                     color: #FFF; 
                                     text-align: center;
                                     margin-left: 4px;">CAD
                                     </span>
                                 </div>
                                
                                 <div class="col-6" style="padding-right: 0px;margin-right: -20px">
                                     {{ (tarifaCad.Valor | currency:'BRL') }}
                                 </div>
                             </div>
                             <div class="row">
                                 <div class="col-12">
                                     <div style="color: #000; font-weight: bold; padding-left: 0px;padding-top:8px;">
                                        {{calcularTempoCad(tarifaCad.Tempo)}}
                                     </div>
                                 </div>
                             </div>
                             <div class="row">
                                 <div class="col-12">
                                     <div style="color: #000; padding-left: 0px;">
                                         {{'ValidTo' | translate}} <strong>{{calcularTempoFimCad(tarifaCad.Tempo / 60)}}</strong>
                                     </div>
                                 </div>
                             </div>
                         </div>                               
                     </div>                   
                 </div>
                 
                 <div style="display: table; margin-top: 48px;">
                    <div style="background-color: #46AAA5; background: rgba(255,255,255,0.8);; width: 100%; height: 30px; position: absolute; bottom: 0px; margin-left: -25px; text-align: center; vertical-align: middle; display: table-cell;">
                        <button class="btn btn-lg btn-block" style="padding: 0px; font-weight: bold;" (click)="resetAtivacao()">{{'Cancel' | translate}}</button>
                    </div>
                     <div style="background-color: #46AAA5; width: 100%; height: 30px; position: absolute; bottom: 34px; margin-left: -25px; text-align: center; vertical-align: middle; display: table-cell;">
                         <button class="btn btn-lg btn-block" style="padding: 0px; font-weight: bold;" (click)="ConfirmarAtivacao()">{{'Proceed' | translate}}</button>
                     </div>                     
                 </div>
 </div>

 <ng-template #setortemplate let-info="info">
    <h3 style="color: white;" >Informações do Setor</h3>
    <p> <span>{{'Place' | translate}}: </span>{{info.logradouro}}</p>
    <p> <span>{{'NeighborhoodCad' | translate}}: </span>{{info.bairro}}</p>
    <p> <span>Total Ocupado: </span>{{info.taxaOcupacao}} %</p>    
    </ng-template>