import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RelatorioFrotistaRequest } from 'src/app/model/relatorio-frotista-request';

@Injectable()
export class RelatorioFrotistaAdminService extends BaseService {
    constructor(private http: HttpClient) { super() }

    getExtratoDetalhe(documento: string, idMovimento: string) : Observable<any> {
        const tokenGetExtrato = this.isFrotistaAdminLogado() ? 
                              this.obterTokenAdminFrotista() :
                              this.obterTokenUsuario();
        return this.http.get(this.UrlClienteFull + "clientes/v1/" + documento + "/extratoDetalhes?idMovimento=" + idMovimento, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': tokenGetExtrato, 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        });
    }

    consultar(relatorioParams: RelatorioFrotistaRequest) : Observable<any>{
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.http.get(`${this.BaseUrlFunction}site/v1/relatoriomovimentacoesfrotista/${relatorioParams.IDEmpresaFrotistaCentroCustoVI}?code=${environment.FuncCodeRelatorioMovimentacoes}`,
                            {
                                params: this._composeParamsRelatorio(relatorioParams) //params
                            }
        );
    }

    exportar(relatorioParams : RelatorioFrotistaRequest): Observable<Blob> {         
       
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.http.get(`${this.BaseUrlFunction}site/v1/relatoriomovimentacoesfrotista/${relatorioParams.IDEmpresaFrotistaCentroCustoVI}?code=${environment.FuncCodeRelatorioMovimentacoes}`,
                            { responseType: 'blob', 
                            //params: opts.params
                            params: this._composeParamsRelatorio(relatorioParams) //params
                            }
        );
    }
    
    private _composeParamsRelatorio(relatorioParams : RelatorioFrotistaRequest) : HttpParams{
        let params = new HttpParams();
        params = params.append('qtdDiasPeriodo',  relatorioParams?.QdtDiasPeriodo?.toString());
        params = params.append('periodoInicial', relatorioParams?.PeriodoInicial?.toString());
        params = params.append('periodoFinal', relatorioParams?.PeriodoFinal?.toString());
        params = params.append('tipoTransacao', relatorioParams?.TipoTransacaoRelatorio?.toString());
        params = params.append('placa', relatorioParams?.Placa);
        params = params.append('cpfUsuario', relatorioParams?.CpfUsuario);
        params = params.append('numeroPagina', relatorioParams?.NumeroPagina?.toString());
        params = params.append('quantidadeRegistros', relatorioParams?.QuantidadeRegistros?.toString());
        params = params.append('exportar', relatorioParams?.Exportar?.toString());

        return params;
    }
}