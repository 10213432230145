import { NgbActiveModal, NgbModalConfig, NgbDatepickerI18n, NgbDateParserFormatter, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { ClienteService } from '../../service/cliente.service';
import { CadastroCompletoSenhaComponent } from '../cadastroCompletoCliente/cadastro-completo-senha.component';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-cadastro-lead-frotista',
    templateUrl: './esqueceu-senha.component.html',
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
                      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  })
  
  export class EsqueceuSenhaComponent {

    passoRecuperarSenha = 1;
    recuperarSenhaInformaEmail: FormGroup;
    recuperarSenhaInformaTokenDocumento: FormGroup;

    //aderiuCadastroLeadFrotista: EventEmitter<void> = new EventEmitter();

    public mask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 
    constructor(
        private spinner: NgxSpinnerService,
        config: NgbModalConfig
        ,public activeModal: NgbActiveModal
        ,private fb: FormBuilder
        ,private clienteService: ClienteService
        ,private modalService: NgbModal
        ,private translate: TranslateService
        ,private ngxLoader: NgxUiLoaderService) {

            config.backdrop = 'static';
            config.keyboard = false;

            this.recuperarSenhaInformaEmail = this.fb.group({               
                email: ['',[Validators.required, Validators.email]]
            }, { updateOn: 'change'});

            this.recuperarSenhaInformaTokenDocumento = this.fb.group({               
                documento: ['', []],
                token: ['', [Validators.required]]                         
            }, { updateOn: 'change'});
        }

        solicitarToken(){

            if(!this.recuperarSenhaInformaEmail.valid){
                   
                Swal.fire({
                            text: this.translate.instant('RequiredFields'),
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',                 
                            confirmButtonColor: '#4a9f42',
                            width: 'auto'
                        });      
                return;              
            }
            
            this.ngxLoader.start('esqueceuSenhaLoader');
            this.clienteService.solicitarTokenTrocaDeSenha(this.recuperarSenhaInformaEmail.value.email)
            .subscribe(res => {                
                const modalRef = this.modalService.open(CadastroCompletoSenhaComponent, { centered: true, size: 'sm' });
                modalRef.componentInstance.areaLogada = false;
                modalRef.componentInstance.email = this.recuperarSenhaInformaEmail.value.email;
                modalRef.componentInstance.aderiuCadastroLeadFrotista.subscribe(res =>{
                    this.activeModal.close();
                });
                this.ngxLoader.stop('esqueceuSenhaLoader');              
            },
            fail => {
                this.ngxLoader.stop('esqueceuSenhaLoader');
            });
        }

        confirmarTrocaDeSenha(){

        }

        isPassoRecuperarSenha(passo: number){
            return this.passoRecuperarSenha === passo;
        }

        setPasso(passo: number){
            this.passoRecuperarSenha = 1;
        }

        blur($event){
            if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 0 && this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length <= 8){
                this.setarTipoDocumento("PASSAPORTE");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 9 && this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length <= 11){
                this.setarTipoDocumento("CPF");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 11){
                this.setarTipoDocumento("CNPJ");
            }
        }

        onPaste(event: ClipboardEvent) {           

            if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 0 && this.recuperarSenhaInformaTokenDocumento.get('documento.numero').value.replace(/\D/g, '').length <= 8){
                this.setarTipoDocumento("PASSAPORTE");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 9 && this.recuperarSenhaInformaTokenDocumento.get('documento.numero').value.replace(/\D/g, '').length <= 11){
                this.setarTipoDocumento("CPF");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 11){
                this.setarTipoDocumento("CNPJ");
            }

            if (this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length > 11) {
                this.mask = [/[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /[/]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
            } else {
                this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
            }          
          }

        keyup(event) {
            if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 0 && this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length <= 8){
                this.setarTipoDocumento("PASSAPORTE");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 9 && this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length <= 11){
                this.setarTipoDocumento("CPF");
            } else if(this.recuperarSenhaInformaTokenDocumento.get('documento').value.replace(/\D/g, '').length >= 11){
                this.setarTipoDocumento("CNPJ");
            }           
        }

        setarTipoDocumento(param: string){
            this.recuperarSenhaInformaTokenDocumento.patchValue({'documento': param.toUpperCase()});
        }

        onlyNumber(param: string) {
            return param.replace(/\D/g, '');
        }

        //getDatePost = (data: any) => `${data.year}${this.padLeft(data.month, 2)}${this.padLeft(data.day, 2)}`;

        onlyLetterNumber(param: string) {
            return param.replace(/[^A-Za-z0-9]+/g, '')
        }

        padLeft(num:number, size:number): string {
            let s = num+"";
            while (s.length < size) s = "0" + s;
            return s;
        }      

        dateMask(event: any) {
            var v = event.target.value;
            if (v.match(/^\d{2}$/) !== null) {
                event.target.value = v + '/';
            } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                event.target.value = v + '/';
            }
        }  
          
        // private emailEsqueceuSenha(){
        //     Swal.fire({
        //         title: 'Recuperar Senha',
        //         text: "Informe o E-mail cadastrado",
        //         input: 'text',
        //         inputAttributes: {
        //             autocapitalize: 'on'
        //         },
        //         showCancelButton: true,
        //         cancelButtonText: 'Cancelar',
        //         confirmButtonText: 'Confirmar',
        //         showLoaderOnConfirm: true,
        //         preConfirm: (email) => {

        //             if(email === null || email === undefined
        //                 || email === ""
        //                 || !this.validateEmail(email)){
        //                     Swal.showValidationMessage("Informe um e-mail válido.");
        //                     return "ERRO";
        //             } else {
        //                 this.confirmaTokenDocumentoEsqueceuSenha();
        //             }
        //         },
        //         allowOutsideClick: () => !Swal.isLoading()
        //     }).then((result) => {
                
        //         if (result.value == "OK") {
        //             //this.activeModal.close();                
        //         }
        //     });
        // }
    
        // confirmaTokenDocumentoEsqueceuSenha(){
        //      Swal.fire({
        //            title: 'Recuperar Senha',
        //            html:
        //              `
        //                <h3>Informe o número de documento cadastrado e o código recebido por email</h3>
        //                <label for="documento">CPF/CNPJ</label>  
        //                <input id="documento" class="swal2-input" />
        //                <label for="token">Código</label>
        //                <input id="token" class="swal2-input" />
        //            `,
        //            focusConfirm: false,
        //            preConfirm: () => [
        //              document.querySelector('#documento').value,
        //              document.querySelector('#token').value
        //            ]
        //          }).then(result => Swal.fire(JSON.stringify(result));
        // }
  }