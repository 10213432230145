import { Component, Output, EventEmitter, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Funcionalidades } from "src/app/model/funcionalidades";
import { Veiculo, VeiculoCad, VeiculoRetorno } from "src/app/model/veiculo";
import Swal from "sweetalert2";
import { ClienteService } from "../../service/cliente.service";

@Component({
    selector: 'app-ativacao-cad-veiculo',
    templateUrl: 'ativacao-cad-veiculo.component.html'
})

export class AtivacaoCADVeiculoComponent {

    @Input()
    veiculos: VeiculoCad[];

    veiculoSelecionado:VeiculoCad[] = [];

    @Output()
    selectVeiculo: EventEmitter<any> = new EventEmitter();

    filteredOptionsPlacas: Observable<VeiculoCad[]>;
    placasFilter: VeiculoCad[] = [];
    myControl = new FormControl(); 
    placaFiltered: VeiculoCad;

    constructor(public activeModal: NgbActiveModal, 
                private clienteService: ClienteService,
                private translate: TranslateService){ }

    ngOnInit(){      

        this.veiculoSelecionado = this.veiculos;
        this.filteredOptionsPlacas = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(placa => placa? this._filterPlaca(placa) : this.veiculos.slice() ),
      );

      this.myControl.valueChanges
      .subscribe((termo: string) => {
          if(termo?.length == 0) this.veiculoSelecionado = this.veiculos;
        }
       );
    }

    selecionar(paramVeiculo){
        this.activeModal.close();
        this.selectVeiculo.emit(paramVeiculo);        
    }

    getIcon(tipo: number) : string {
        switch(tipo){
          case 1: return 'local_shipping';
          case 8: return 'drive_eta';
          case 13: return 'directions_bus';
        }
    }

    setPlacaSelected(placa: string){     
        this.placaFiltered = this._filterPlaca(placa)[0];
        this.veiculoSelecionado = [];
        this.veiculoSelecionado.push(this.placaFiltered);         
    }   
    
    private _filterPlaca(value: string ): VeiculoCad[]{
        const filterValue = value.toUpperCase();
        return this.veiculos
                    .filter(option => option.placa.toUpperCase().includes(filterValue)); //.indexOf(filterValue) === 0);
    }

    onKeyUp(event: KeyboardEvent){
      if(event.keyCode == 27 || event.code === "Escape" || event.key === "Escape")
        this.clearSearch();      
    }
    
    clearSearch(){
        this.myControl.reset();
        this.veiculoSelecionado = this.veiculos;
    }

    favoritarToogle(placa: string, favorito: boolean){

        let veic = new Veiculo();
        veic.veiculo = {
            placa: placa.toUpperCase(),
            tipo: null,
            favorito: !favorito                
        };
        
        veic.usuario = {
            frotista:  this.clienteService.obterUsuario().frotista
                        && this.clienteService.getPerfilUsuario() === 'frotista'
        }

        this.clienteService.favoritarVeiculo(veic)
                                 .subscribe(
                                  result => {  
                                    
                                    this.getVeiculos();
                                    this.clienteService.atualizarPlacas();
                                    
                                    Swal.fire({
                                      text: result.message,
                                      titleText:  this.translate.instant('AlertInfo'),
                                      icon: 'success',                 
                                      confirmButtonColor: '#4a9f42'
                                    });
                                  },
                                  fail => {
                                    //this.spinner.hide();
                                    Swal.fire({
                                      text: fail?.message,
                                      titleText:  this.translate.instant('AlertInfo'),
                                      icon: 'info',                 
                                      confirmButtonColor: '#4a9f42'
                                    });
                                  }
                                );       
      }

      getVeiculos(){
        const usuario = this.clienteService.obterUsuario();
        
        this.clienteService.getVeiculos(
              this.clienteService.isFrotistaAdminLogado() ? 
              this.clienteService.obterDocumentoOriginalAdminFrotista():
              usuario.documento, 
                    usuario.frotista
                    && this.clienteService.getPerfilUsuario() === 'frotista' ? 
                    "frotista" : 
                    "comum" )
        .subscribe(
          result => {
            this.veiculos = result.veiculos.map(x => new VeiculoRetorno(x.ano, x.cor, x.id, x.marca, x.modelo, x.placa, x.tipo, x.favorito));  
            
            if(this.veiculoSelecionado.length == 1){
                const veiculoSelecionado = this.veiculoSelecionado[0];
                this.veiculoSelecionado = this.veiculos;
                this.veiculoSelecionado = this._filterPlaca(veiculoSelecionado.placa);
            }
            else
               this.veiculoSelecionado = this.veiculos;           
           
          },
          fail => {
          
          }
        );
      }
}