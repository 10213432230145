import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'isNull'})
export class IsNullPipe implements PipeTransform {
  transform(value: any, valueToReturn: string): string {    
    if(value === null
       || value === undefined
       || value === 'undefined'
       || value === ''
       || value === ' '
       || value.includes('undefined')
       || value === 'undefined - undefined - undefined' )
    return valueToReturn;  
    
    return value;
  }
}