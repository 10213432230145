export class RelatorioFrotistaRequest {

    public QdtDiasPeriodo: number;
    public PeriodoInicial: string;
    public PeriodoFinal: string;
    public TipoTransacaoRelatorio: TipoTransacaoRelatorio;
    public Placa: string
    public CpfUsuario: string;
    public IDEmpresaFrotistaCentroCustoVI : number;
    public NumeroPagina: number;
    public QuantidadeRegistros: number;
    public Exportar : boolean;   
    
    constructor(qdtDiasPeriodo: number,
                periodoInicial: string,
                periodoFinal: string,
                tipoTransacaoRelatorio: TipoTransacaoRelatorio,
                placa: string,
                cpfUsuario: string,
                idEmpresaFrotistaCentroCustoVI : number,
                numeroPagina: number,
                quantidadeRegistros: number,
                exportar : boolean){

                    this.QdtDiasPeriodo = qdtDiasPeriodo;
                    this.PeriodoInicial = periodoInicial;
                    this.PeriodoFinal = periodoFinal
                    this.TipoTransacaoRelatorio = tipoTransacaoRelatorio;
                    this.Placa = placa;
                    this.CpfUsuario = cpfUsuario;
                    this.IDEmpresaFrotistaCentroCustoVI = idEmpresaFrotistaCentroCustoVI;
                    this.NumeroPagina = numeroPagina;
                    this.QuantidadeRegistros = quantidadeRegistros;
                    this.Exportar = exportar;
    }
}

export enum TipoTransacaoRelatorio
{
    Todas = 1,
    Creditos = 2,
    Utilizacoes = 3
}