import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SetorFilter } from 'src/app/model/utilizacao-cad';

@Component({
    selector: 'app-ativacao-cad-setor',
    templateUrl: './ativacao-cad-setor.component.html'
})

export class AtivacaoCADSetorComponent implements OnInit {
    @Output()
    selectSetor: EventEmitter<any> = new EventEmitter();

    filteredOptionsSetores: Observable<SetorFilter[]>;
    setoresFilter: SetorFilter[] = [];
    myControl = new FormControl(); 
    setorFiltered: SetorFilter;

    constructor(public activeModal: NgbActiveModal) {
    }

    selecionar(){
      this.activeModal.close();
      this.selectSetor.emit(this.setorFiltered);        
    }

    setSetorSelected(setorDescricao: string){
     
        this.setorFiltered = this._filterSetor(setorDescricao)[0];        
    }    

    // setSetorSelectedById(idSetor: number){
    //     const setorFilter = this._findSetorById(idSetor);
        
    // }

    private _filterSetor(value: string ): SetorFilter[]{
        const filterValue = value.toUpperCase();
        return this.setoresFilter
                    .filter(option => option.DescricaoSetor.toUpperCase().includes(filterValue)
                                      || option.Descricao.toUpperCase().includes(filterValue)); //.indexOf(filterValue) === 0);
    }

    ngOnInit(){
        this.filteredOptionsSetores = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(setor => setor? this._filterSetor(setor) : this.setoresFilter.slice() )
      )
    }
}