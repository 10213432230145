import { Component, OnInit } from '@angular/core';
import { CartaoService } from '../../service/cartao.service';
import { ClienteService } from '../../service/cliente.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CartaoCadastroComponent } from './cartao-cadastro.component';

import Swal from 'sweetalert2';
import { CartaoRetorno } from 'src/app/model/cartao';
import { NgxSpinnerService } from 'ngx-spinner';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cartao-lista',
    templateUrl: './cartao-lista.component.html'
  })
  
export class CartaoListaComponent implements OnInit {
    cartoes: CartaoRetorno[];

    constructor(
      private spinner: NgxSpinnerService,
      private modalService: NgbModal,
      config: NgbModalConfig
      ,private cartaoService: CartaoService
      ,private clienteService: ClienteService
      ,private translate: TranslateService ){
        
        config.backdrop = 'static';
        config.keyboard = false;
        
        cartaoService.getCartoes(this.clienteService.obterUsuario().documento)
        .subscribe(
          result => {
            this.cartoes = result;
          },
          fail => {         
            let erro = fail;
          }
        );

        cartaoService.cadastroCartaoEvent.subscribe(e => {
          cartaoService.getCartoes(this.clienteService.obterUsuario().documento)
          .subscribe(
            result => {
              this.cartoes = result;
            },
            fail => {         
              let erro = fail;
            }
          );
        });
    }

    ngOnInit() {
    }

    open() {
      const modalRef = this.modalService.open(CartaoCadastroComponent, { centered: true, size: 'md' });
      
      modalRef.componentInstance.selectCartao.subscribe(
        result => {
          this.cartaoService.getCartoes(this.clienteService.obterUsuario().documento)
        .subscribe(
          result => {
            this.cartoes = result;
          },
          fail => {
            let erro = fail;
          }
        );
        },
        fail => {
       
        }
      );
    }

    deletar(param){
      const swalWithBootstrapButtons = Swal.mixin({
        // customClass: {
        //   confirmButton: 'btn btn-success',
        //   cancelButton: 'btn btn-danger'
        // },
        // buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.translate.instant('AlertInfo'),
        text: this.translate.instant('CardDeleteQuestion'),
        icon: 'question',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4a9f42',
        cancelButtonText: this.translate.instant('Cancel'),       
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.cartaoService.deleteCartao(this.clienteService.obterUsuario().documento, param)
        .subscribe(
          result => {
            const index: number = this.cartoes.findIndex(x => x.uuidCartaoEstapar === param); //
            if (index !== -1) {
              this.cartoes.splice(index, 1);
            }
          
            Swal.fire({
              text: this.translate.instant('CardDeleteSuccess'),
              titleText: this.translate.instant('AlertInfo'),
              icon: 'success',                 
              confirmButtonColor: '#4a9f42'
            });
          },
          fail => {
            //this.spinner.hide();
            Swal.fire({
              text: `${fail.message}`,
              titleText: this.translate.instant('AlertInfo'),
              icon: 'info',                 
              confirmButtonColor: '#4a9f42'
            });           
          }
        );
          
        }
      })      
    }    

    get cartaoIncluir() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.IncluirCartoes);
    }
}