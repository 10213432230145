import { Telefone } from './telefone';

export class Cliente {
    public nome: string;
    public sobrenome: string;
    public razaoSocial: string;
    public nomeFantasia: string;
    public dataNascimento: any;
    public documento: {
        numero: string;
        tipo: string;
    };
    public endereco: {
        cep: string;
        logradouro: string;
        numeroLogradouro: string;
        complemento: string;
        bairro: string;
        cidade: string;
        estado: string;
        pais: string;
    };
    public telefones: Telefone[];
    public nomeMae: string;
    public email: string;
    public precisaTrocarSenha: boolean;
    public pendenteAtualizacao: boolean;
    public senha: string;
    constructor(documento: string, nome: string, sobreNome: string, email: string, dataNascimento: string, razaoSocial?: string, nomeFantasia?: string) {
        this.documento = {
            numero: "",
            tipo: ""
        };
        this.documento.numero = documento;
        this.nome = nome;
        this.sobrenome = sobreNome;
        this.razaoSocial = razaoSocial;
        this.email = email;
        this.nomeFantasia = nomeFantasia;
        this.dataNascimento = dataNascimento;
    }
}