<div class="modal-header">
    <h4 class="modal-title">{{'RecoverPassword' | translate}}</h4>
  </div>
  <div class="modal-body">
      <form [hidden]="!isPassoRecuperarSenha(1)" novalidate [formGroup]="recuperarSenhaInformaEmail">
          <div class="container form-group">
            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="Email">{{'EmailRecoverPassword' | translate}}: *</label>
                    <input required class="form-control" id="Email" formControlName="email" />
                </div>
            </div> 
            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <button class="btn btn-next" (click)="solicitarToken()">{{'Proceed' | translate}}</button>
                </div>
            </div>         
          </div>
      </form>
      <!-- <form [hidden]="!isPassoRecuperarSenha(2)" novalidate [formGroup]="recuperarSenhaInformaTokenDocumento">
        <div class="container form-group">
          <div class="row mb-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label for="token">{{'CodeEmail' | translate}}: *</label>
                  <input required class="form-control" id="token" placeholder="{{'CodeEmail' | translate}}" formControlName="token" />
              </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label for="documento">{{'Document' | translate}}: *</label>
                <input required class="form-control" id="documento" placeholder="{{'Document' | translate}}" formControlName="documento" matInput mask="000.000.000-00||00.000.000/0000-00||AA.000.000" (blur)="blur($event)" (keydown)="keyup($event)" (paste)="onPaste($event)" 
                    style="text-transform: uppercase"/>                                                         
            </div>
          </div>   
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                <button class="btn btn-light" (click)="setPasso(1)">Anterior</button>
                <button class="btn btn-next" (click)="confirmarTrocaDeSenha()">Próximo</button>
            </div>
        </div>     
        </div>
    </form> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate }}</button>
  </div>