import { Component, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-extrato-detalhe-frotista-admin',
    templateUrl: './extrato-detalhe-frotista-admin.component.html'
})

export class ExtratoDetalheFrotistaAdminComponent {
    @Input()
    detalheFrotistaAdmin;

    constructor(
        public activeModal: NgbActiveModal){
        }
}