<div class="modal-header">
    
    <span style="color: #fff;font-size: 1.em;">
    {{ 'AlterSetor' | translate }} 
    </span> 

</div>
<div class="modal-body" style="padding-top: 0px; padding-bottom: 0px;">

    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            {{'InfoSearchSetor' | translate}}
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <form class="search-form-full-width">
                <mat-form-field class="example-full-width">
                  <input type="text"                 
                         aria-label="Number"
                         matInput
                         [formControl]="myControl"
                         [matAutocomplete]="auto">
                         <mat-placeholder class="placeholderBuscaSetores">{{'SearchAddressUseCad' | translate}}</mat-placeholder>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setSetorSelected($event.option.value)">
                    <mat-option *ngFor="let option of filteredOptionsSetores | async | slice : 0 : 50" [value]="option.Descricao">
                      {{option.Descricao}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
               </form>
        </div>
    </div>
    
</div>
<div class="modal-footer bg-ligth">    
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate }}</button>
    <button type="button" class="btn btn-outline-success" (click)="selecionar()" >{{ 'Confirm' | translate}}</button>
</div>