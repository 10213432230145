<div class="card border-0 shadow-sm mb-4">
    <div class="col py-2 pr-0" style="border-bottom: solid 1px #4C4E51;margin-bottom: 10px;">
        <div class="card-header border-0 bg-none">
            {{ 'Bank Slip' | translate }}
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-300">
                <div class="card-body scroll-y h-250 p-0">
                  <div class="card-body table-responsive">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Nº Documento</th>
                                <th>Emissão</th>
                                <th>Vencimento</th>
                                <th>Data Baixa</th>
                                <th>Valor</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let boleto of boletos">
                            <tr>
                                <td>{{boleto.NumeroDocumento}}</td>
                                <td>{{ formatData(boleto.DataEmissao) | date:'dd/MM/yyyy'}}</td>
                                <td>{{ formatData(boleto.DataVencimento) | date:'dd/MM/yyyy'}}</td>
                                <td>{{ (boleto.DataBaixa != '') ? (formatData(boleto.DataBaixa) | date:'dd/MM/yyyy') : ''}}</td>
                                <td>{{boleto.Valor | currency:'BRL'}}</td>    
                                <td>{{boleto.DescricaoStatus}}</td>                            
                                <td (click)="open(boleto)" style="cursor: pointer;" [hidden]="boleto.CodigoStatus === 'B'"><i class="material-icons">print</i></td>
                                <td (click)="visualizar(boleto)" style="cursor: pointer;" [hidden]="boleto.CodigoStatus !== 'B'"><i class="material-icons">search</i></td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
