<header class="header" style="padding: .5rem 1rem;">
    <nav class="container-fluid navbar-expand">
        <div class="row">
            <div class="col-6 col-sm-8 col-md-10 col-lg-10 col-xl-10">
                <a href="javascript:void(0)" class="">
                    <img src="assets/images/logo/logo-navbar.png" width="144" alt="Estapar Zona Azul Digital" class="">
                </a>
            </div>
            <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right">
                <ul class="navbar-nav" [ngSwitch]="usuarioLogado()" style="float: right;">
                    <li class="nav-item dropdown ml-0 ml-sm-4" ngbDropdown style="border: 0px;">
                        <a class="nav-link dropdown-toggle profile-link active" href="javascript:void(0)" ngbDropdownToggle>
                            <span class="flag-icon {{'FlagIconClass'|translate}}"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right w-200 pt-0 overflow-hidden" ng-click="$event.stopPropagation()" ngbDropdownMenu>
                            <a class="dropdown-item border-top" href="javascript:void(0)" (click)="changeLang('pt')" style="cursor: pointer !important;">
                                <div class="row">
                                    <div class="col-auto align-self-center">
                                        <span class="flag-icon flag-icon-br"></span> <span class="ml-2"></span>
                                    </div>
                                    <div class="col pl-0">
                                        <p class="mb-0">{{ 'Portuguese' |translate }}</p>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item border-top" href="javascript:void(0)" (click)="changeLang('en')">
                                <div class="row">
                                    <div class="col-auto align-self-center">
                                        <span class="flag-icon flag-icon-us"></span> <span class="ml-2"></span>
                                    </div>
                                    <div class="col pl-0">
                                        <p class="mb-0">{{ 'English' | translate }}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>

                    <li *ngSwitchCase="true" class="nav-item dropdown ml-0 ml-sm-4" ngbDropdown style="border: 0px;">
                        <a class="nav-link profile-link active" href="javascript:void(0)" ngbDropdownToggle>
                            <div class="username-text ml-2 mr-2 d-lg-inline-block">
                                <h4 class="username">{{ 'Log Out' | translate }}</h4>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right w-200 pt-0 overflow-hidden"
                             ng-click="$event.stopPropagation()"
                             ngbDropdownMenu>
                            <a class="dropdown-item border-top" (click)="onLogout()">
                                <div class="row">
                                    <div class="col-auto align-self-center">
                                        <i class="material-icons text-danger">exit_to_app</i>
                                    </div>
                                    <div class="col pl-0">
                                        <p class="mb-0 text-danger">{{ 'Log Out' | translate }}</p>
                                    </div>
                                    <div class="col-auto align-self-center text-right pl-0">
                                        <i class="material-icons text-mute small text-danger">chevron_right</i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="row submenu" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;padding-right:18px;padding-left:50%;background-color:#70D44B;background-image: linear-gradient(to right, rgb(116 ,224,193), rgb(112,212,75));background-repeat:no-repeat;background-position:center;background-size:cover;border-top-width:0;border-bottom-width:0;/* padding-top:5px; *//* padding-bottom:5px; */height: 3px;margin-top: 8px;">&nbsp;</div>
</header>