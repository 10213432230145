
import { CommonModule } from '@angular/common';import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbAccordionModule /*, NgbDatepicker*/ } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NbDatepickerModule } from '@nebular/theme';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';

import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { LoginModule } from './login/login.module';

import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthGuard } from './shared/guard/auth.guard';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule
    ,NgxMaskModule.forRoot(maskConfig)
    ,ToastrModule.forRoot()
    ,NgxSpinnerModule
    ,NgxUiLoaderModule
    ,BrowserModule
    ,BrowserAnimationsModule
    ,HttpClientModule
    ,LanguageTranslationModule
    ,DashboardModule
    ,LoginModule
    ,AppRoutingModule
    ,NgbModule
    ,NgbAccordionModule
    //,NgbDatepicker
    ,NbDatepickerModule.forRoot()    
    ,NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    RecaptchaV3Module
  ],
  exports: [
  ],
  providers: [
    AuthGuard,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.KeyRecaptcha,
    }
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    
  ]
})

export class AppModule { }
