<div class="">
    <div class="wrapper">
        <div class="content">
            <app-header></app-header>
            
            <div class="row">
                <div class="container main-container" >
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4">
                            <app-dados-basico-cliente></app-dados-basico-cliente>
                            <div [hidden]="ehComercioEspecial"><app-veiculo-lista></app-veiculo-lista></div>
                            <div [hidden]="!listarTelefones || ehComercioEspecial">
                                <app-telefone-lista></app-telefone-lista>
                            </div>
                        </div>
                    
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8">
                            <div class="row">
                                <div [ngClass]="cartaoListar ? 'col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'">
                                    <app-saldo-lista></app-saldo-lista>
                                </div>
                                <div [hidden]="!cartaoListar || ehComercioEspecial" class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                    <app-cartao-lista></app-cartao-lista>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <div [hidden]="ehComercioEspecial">
                                        <app-utilizacao-cad></app-utilizacao-cad>
                                    </div>
                                    <div [hidden]="!usuarioCorporativo || ehComercioEspecial">
                                        <app-usuario-frotista></app-usuario-frotista>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <div [hidden]="ehComercioEspecial">
                                        <app-extrato-lista></app-extrato-lista>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <div>
                                        <app-boleto-lista></app-boleto-lista>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <app-footer loadNeoAssistTag="true" class="footer position-relative d-block"></app-footer>
                </div>
            </div>
        </div>
    </div>

    <app-lgpd></app-lgpd>
</div>