import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Boleto, BoletoImprimir, BoletoDetalhe, BoletoParam } from 'src/app/model/boleto';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BoletoService extends BaseService {

    movimentacaoBoletoEvent: EventEmitter<void> = new EventEmitter();

    atualizarLista = () => this.movimentacaoBoletoEvent.emit();

    constructor(private http: HttpClient) { super() }

    getBoletos(document: string): Observable<Boleto[]> {
        return this.http
            .get(`${environment.UrlGetBoletosBroker}${environment.RotaGetBoletosBroker}?documento=${document}`, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario() })
            })
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            )
    }

    getDetalheBoleto(param: BoletoDetalhe): Observable<any> {
        return this.http
        .post(`${this.BaseUrlFunction}app/boleto/v1?code=${this.FuncCodeAuthBoleto}`, JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })
        })
        .pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )      
    }

    getDetalheImprimir(document: string, param: BoletoImprimir): Observable<any>{
        return this.http
        .post(this.BaseUrlFunction + "app/boleto/v1/" + document, JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
        })
        .pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }
}