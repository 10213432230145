import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ExtratoService } from '../../service/extrato.service';
import { ClienteService } from '../../service/cliente.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbModal, NgbModalConfig, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ExtratoDetalheComponent } from './extrato-detalhe.component';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { RelatorioMovimentacoesComponent } from '../relatorio/relatorio-movimentacoes.component';
import { Funcionalidades } from 'src/app/model/funcionalidades';

@Component({
    selector: 'app-extrato-lista',
    templateUrl: './extrato-lista.component.html',
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})

export class ExtratoListaComponent implements OnInit {  

  @ViewChild('scrolItensExtrato') private scrolItensExtratoContainer: ElementRef;
  carregarMaisEnabled = false;  

  dataInicial: NgbDateStruct;
  dataFinal: NgbDateStruct;
  
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;  

  paginacao: any = { limiteRegistros: 0, quantidadeRegistros:0} ;  
 
  active = 'todos';
  tabHistorico: any = this.active;

  currentJustify = 'fill';
  yearMonth: string = '';

  movimentacoes: Array<any> = [];
  movimentacoesCredito: Array<any>;
  movimentacoesCAD: Array<any> = [];

  constructor(
    private modalService: NgbModal
    ,config: NgbModalConfig
    ,private translate: TranslateService
    ,private extratoService: ExtratoService
    ,private clienteService: ClienteService
    ,public formatter: NgbDateParserFormatter){
      
      config.backdrop = 'static';
      config.keyboard = false;
      
      let dataIni = new Date();
      dataIni.setDate(dataIni.getDate() - 7);
      const dataFinal = new Date();

      this.dataInicial = new NgbDate(dataIni.getFullYear(), dataIni.getMonth() + 1, dataIni.getDate());
      this.dataFinal = new NgbDate(dataFinal.getFullYear(), dataFinal.getMonth() + 1, dataFinal.getDate());
     
      this.extratoService.movimentacaoExtratoEvent.subscribe(result => {
        this.getExtrato();
      })
  }

  ngOnInit(){
    this.getExtrato();   
  }  

  public onScroll() {
    let element = this.scrolItensExtratoContainer.nativeElement;
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    
    if (this.paginacao.quantidadeRegistros > this.paginacao.limiteRegistros 
        && atBottom && this.tabHistorico === 'todos') {
        this.carregarMaisEnabled = true;
    } else {
        this.carregarMaisEnabled = false;
    }
  }

  private scrollToTop(): void {
    if(this.scrolItensExtratoContainer != undefined)
      this.scrolItensExtratoContainer.nativeElement.scrollTop = this.scrolItensExtratoContainer.nativeElement.clientHeight;
  }  

  setDateExtrato(dateString: string): Date {
    const dateParts = dateString.split('-');
    const mes = parseInt(dateParts[1]) + 1 === 12 ? 1 : parseInt(dateParts[1]);
    return new Date(parseInt(dateParts[0]), mes, parseInt(dateParts[2])); 
  }

  public getExtrato(carregarMais: boolean = false){

    if(!carregarMais) this.scrollToTop();
    
    
    const documentoGetExtrato = this.clienteService.isFrotistaAdminLogado() ?
                                  this.clienteService.obterDocumentoOriginalAdminFrotista() : 
                                  this.clienteService.obterUsuario().documento;
        
    const isFrotista = this.clienteService.isFrotista()
                            && this.clienteService.getPerfilUsuario() === 'frotista' ? true : false;
   
    let dataFinalExtrato;

    if(carregarMais){
      dataFinalExtrato = this.movimentacoes[this.movimentacoes.length - 1].movimento.data; //this._getDateParamsFromDateString(ultimoItem);
    }      
    else{
      this.movimentacoes = [];
      dataFinalExtrato = this._getDateParamsFromDateString(this.dataFinal);
    }      

    this.extratoService
          .getExtratoPeriodo(documentoGetExtrato, 
                             this._getDateParamsFromDateString(this.dataInicial), 
                             dataFinalExtrato, 
                             isFrotista)
      .subscribe(
        result => {

          this.carregarMaisEnabled = false;
          
          if("movimentacoes" in result){        
            
            this.paginacao = result.paginacao; 
            this.movimentacoes = [...this.movimentacoes, ...result.movimentacoes];
          }
          else{            
            this.movimentacoes = [];
            this.movimentacoesCredito = [];
            this.movimentacoesCAD = [];
          }
        },
        fail => {
        
        }
      );
  }

  getNiceDescription(tipo: string) : string{    
    switch(tipo){
      case 'recarga':
      case 'pagamento':  
        return this.translate.instant('RechargeEwallet');
      case 'recarga-cad': return this.translate.instant('CadPurchase');
      case 'utilizacao' : return this.translate.instant('ActivationOfBlueZone');
      case 'estorno-recarga': return this.translate.instant('ReversalOfPayment');
      case 'portabilidade' : return this.translate.instant('PortabilityCad');
      default: return 'transação';
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) { 
    
    this.tabHistorico = changeEvent.nextId;
    
    switch(changeEvent.nextId){
      case 'todos': 
        this.getExtrato()
      break;
      case 'creditos': 
        this.carregarMaisEnabled = false;
        this.movimentacoesCredito = this.movimentacoes?.filter(function(i:any){
          return i.movimento.tipo === 'recarga';
        });        
      break;
      case 'cads':          
        this.carregarMaisEnabled = false;
        this.movimentacoesCAD = this.movimentacoes?.filter(function(i: any){
          return i.movimento.tipo === 'utilizacao' 
                 || i.movimento.tipo === 'recarga-cad';                      
        });            
      break;
    }
  }

  // private _getDateParamsBusca = (data: Date): string => 
  //   `${data.getFullYear()}-${this.pad(data.getMonth() + 1 === 12 ? 1 : data.getMonth() + 1, 2)}-${this.pad(data.getDate(), 2)}`;
  
  private _getDateParamsFromDateString(data: NgbDateStruct) : string{
    //const dateParts = data.split('-');
    return  `${data.year}-${this.pad(data.month, 2)}-${this.pad(data.day, 2)}`;
    //return `${dateParts[0]}-${this.pad(dateParts[1], 2)}-${this.pad(dateParts[2], 2)}`;
  }

  onDateSelection(date: NgbDate) {
    
  }

    open(param: string){
      
      const documentoGetExtrato = this.clienteService.isFrotistaAdminLogado() ?
                                  this.clienteService.obterDocumentoOriginalAdminFrotista() : 
                                  this.clienteService.obterUsuario().documento;

      this.extratoService.getExtratoDetalhe(documentoGetExtrato, param)
            .subscribe(
                result => {
                  const modalRef = this.modalService.open(ExtratoDetalheComponent, { centered: true, size: 'md' });
      
                  modalRef.componentInstance.setarDados(result.movimento);

                  //this.spinner.hide();
                },
                fail => {
                  //this.spinner.hide();
                 
                }
            );      
    }

    gerarRelatorioMovimentacoesModal() {
      const modalRef = this.modalService.open(RelatorioMovimentacoesComponent, { centered: true, size: 'xl' });
    }

    // hide(param: Date){
    //   if(this.yearMonth == "" || this.yearMonth != new Date(param).getFullYear() + "-" + new Date(param).getMonth()){
    //     this.yearMonth = new Date(param).getFullYear() + "-" + new Date(param).getMonth();
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }

    lang(){
      return this.translate.getDefaultLang();
    }

    pad(value:any, size:number): string {
      let s = value + "";
      while (s.length < size) s = "0" + s;
      return s;
    }

    get usuarioCorporativo() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.UsuariosCorporativos);
    }  

    dateMask(event: any) {
      var v = event.target.value;
      if (v.match(/^\d{2}$/) !== null) {
        event.target.value = v + '/';
      } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
        event.target.value = v + '/';
       }
    }

    carregarMaisItensPeriodo(){      
      this.getExtrato(true);
    }
}