import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-politica-privacidade',
    templateUrl: './politica-privacidade.component.html'
  })

  export class PoliticaPrivacidadeComponent {
      public textoTermoUso: string;

      constructor(config: NgbModalConfig
        ,public activeModal: NgbActiveModal){}
      
  }