import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class CadService extends BaseService{   
    
    constructor(private http: HttpClient) { super() }

    CompraCartaoUtilizarCad(param: any, documento: string, fingerPrint: string, tokenRecaptcha:string) {
        param.compra.dispositivo.fingerprint = fingerPrint;
        return this.http
            .post(`${this.UrlBaseBroker}/compras/v1/${documento}/cartaoCredito/utilizacao`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                           'Authorization': this.obterTokenUsuario(), 
                                           'Applicationid': environment.BrokerApplicationIdPortalCliente,
                                           'recaptchaToken': tokenRecaptcha })
            })
            .pipe(  
                catchError(super.serviceError)                              
            );
    }

    CompraEWalletUtilizarCad(param: any, documento: string, fingerPrint: string , tokenRecaptcha: string) {
        param.compra.dispositivo.fingerprint = fingerPrint;
        return this.http
            .post(`${this.UrlBaseBroker}/compras/v1/${documento}/carteiraVirtual/utilizacao`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                           'Authorization': this.obterTokenUsuario(), 
                                           'Applicationid': environment.BrokerApplicationIdPortalCliente,
                                           'recaptchaToken':tokenRecaptcha })
            })
            .pipe(  
                catchError(super.serviceError)                              
            );
    }

    UtilizarCad(param: any, documento: string, fingerPrint: string, isFrotistaAdmin: boolean = false) {
        param.utilizacao.dispositivo.fingerprint = fingerPrint;
        return this.http
            .post(`${this.UrlBaseBroker}/compras/v1/${documento}/utilizacao`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 
                                           'Authorization': isFrotistaAdmin ? this.obterTokenAdminFrotista() : this.obterTokenUsuario(), 
                                           'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(  
                catchError(super.serviceError)                              
            );
    }
}