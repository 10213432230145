import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FAQComponent } from '../../components/faq/faq.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

    @Input()
    public loadNeoAssistTag: boolean;

    loadAPI: Promise<any>;

    constructor(private modalService: NgbModal){
        
    }

    ngOnInit(): void {
        if(this.loadNeoAssistTag)
            this.loadAPI = new Promise(() => {               
                this.loadScriptNeoAssist();
            });
    }

    open(){
        const modalRef = this.modalService.open(FAQComponent, { centered: true, size: 'md' });
    }      

    public loadScriptNeoAssist() {
        let node = document.createElement('script');
        node.src  = `https://static.zdassets.com/ekr/snippet.js?key=${environment.ZendeskKey}`;
        node.type = 'text/javascript';
        node.setAttribute("id", "ze-snippet");
        document.getElementsByTagName('body')[0].appendChild(node);
    }    

    public showWhats(){
        return !this.loadNeoAssistTag;
    }
}