<div class="modal-header page-checkout">
    <h4 class="modal-title">
        <div [ngSwitch]="movimento?.tipoOperacao" style="font-size: 1.1em; font-weight: 580;">
            <div *ngSwitchCase="2">
                {{'ActivationOfBlueZone' | translate}} <span style="color:#70d44b;"> {{movimento.quantidadeCAD}} CAD </span>
            </div>
            <div *ngSwitchCase="1">
                {{'RechargeEwallet' | translate}} <span style="color:#70d44b;"> {{movimento.carteiraVirtual | currency:'BRL'}} </span>
            </div>           
        </div>     
    </h4>
</div>
<div class="modal-body">
    <div class="container" *ngIf="movimento === undefined || movimento === null ">
        <h4>{{'TransactionHistoryError' | translate}}</h4>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento?.dataFormatada }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Nome:</strong>
                <span style="margin-left: 6px;">
                 {{movimento?.nome}}
                </span>                
            </div>            
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Documento</strong><span style="margin-left: 6px;">{{ movimento.documento }}</span>                
            </div>
        </div>  
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Email</strong><span style="margin-left: 6px;">{{ movimento?.email }}</span>                
            </div>
        </div>  
    </div>
    <div class="container" *ngIf="movimento !== undefined && movimento?.tipoOperacao === 2">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Início do CAD:</strong><span style="margin-left: 6px;">{{ movimento?.dataInicioTiquete }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Fim do CAD:</strong><span style="margin-left: 6px;">{{ movimento?.dataLimiteTiquete }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Tempo adiquirido:</strong>
                    <span style="margin-left: 6px;">
                        {{ calcularTempoCad(movimento?.tempo) }}
                    </span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Area</strong><span style="margin-left: 6px;">{{ movimento?.area }}</span>                
            </div>
        </div> 
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Local</strong><span style="margin-left: 6px;">{{ movimento?.local }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Setor</strong><span style="margin-left: 6px;">{{ movimento?.setor }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Regra</strong><span style="margin-left: 6px;">{{ movimento?.regra }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Placa</strong><span style="margin-left: 6px;">{{ movimento.placa }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Autenticação</strong><span style="margin-left: 6px;">{{ movimento?.tiqueteAutenticacao }}</span>                
            </div>
        </div> 
    </div>    
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
</div>