import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FiliaisService } from '../../service/filiais.service';
import { AtivacaoCADVeiculoComponent } from './ativacao-cad-veiculo.component';
import { ClienteService } from '../../service/cliente.service';
import { VeiculoCad, VeiculoRetorno } from 'src/app/model/veiculo';
import { CadService } from '../../service/cad.service';
import { Filial, Horario, SetorFilter, SetorTarifa, Tarifa, UtilizacaoCad, CompraUtilizacaoCartaoFrotista, CompraUtilizacaoCarteiraFrotista, CompraUtilizacaoCarteira, CompraUtilizacaoCartao, UtilizacaoCadFrotista } from 'src/app/model/utilizacao-cad';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AtivacaoCADSetorComponent } from './ativacao-cad-setor.component';
import * as moment from 'moment';
import { ExtratoService } from '../../service/extrato.service';
import { CartaoService } from '../../service/cartao.service';
import { RegraZadInformativoComponent } from '../regraZadInformativo/regra-zad-informativo.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as uuid from 'uuid';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-ativacao-cad',
    templateUrl: './ativacao-cad.component.html',
    providers: [ReCaptchaV3Service]
})

export class AtivacaoCADComponent implements OnInit {
 
  @Output()
  cadUtilizado: EventEmitter<any> = new EventEmitter(); 

  loadAPI: Promise<any>;
  private guidPagamento = uuid.v4();

  private _passoAtivacaoCad = 0;
  horarioFuncionamentoSetor: string;

  get passoAtivacaoCad(){ return this._passoAtivacaoCad; }

  get regraDescricao() {
    return this.setorTarifasUtilizacaoCad?.find(x => x.TipoVeiculo === this.veiculo?.tipo)?.DescricaoRegra
  }
  
  public setoresFeatureMap;
  center = {lat:  -23.606007, lng: -46.691833};
  latitude: number = this.center.lat;
  longitude: number = this.center.lng;
  public zoom = 18;
  public infoWindowVisible = false;
  public dadosSetor: any = {
    info: {
        idSetor: 0,
        logradouro: '',
        bairro: '',
        area: '',
        taxaOcupacao: 0                     
    } 
  };
  public latitudeUtilizacao: string;
  public longitudeUtilizacao: string;
  public tipoMarkerInfoGlobal = 'Setor'; 
  
  formBuscaSetorControl = new FormControl(); 
  filteredOptionsSetores: Observable<SetorFilter[]>;
  setoresFilter: SetorFilter[] = [];
  setorTarifas: SetorTarifa[] = [];
  setorTarifasUtilizacaoCad: Tarifa[] = [];

  public veiculos: VeiculoRetorno[] = [];

  veiculo: VeiculoRetorno = {} as VeiculoRetorno;
    
  tempo: number;
  valor: number;
  
  quantidadeCad: number;
       
  logradouro: string = "";
  map: any;
     
  tokenCartaoCompra: string = "";

  public tokenRecaptcha: string;

  constructor(private modalService: NgbModal,
          private spinner: NgxSpinnerService,
          config: NgbModalConfig,
          public activeModal: NgbActiveModal
          ,private filialService: FiliaisService
          ,private clienteService: ClienteService
          ,private cartaoService: CartaoService          
          ,private cadService: CadService,
           private translationService: TranslateService,
           private extratoService: ExtratoService,
           private ngxLoader: NgxUiLoaderService,
           private recaptchaV3Service: ReCaptchaV3Service)
  {
    config.backdrop = 'static';
    config.keyboard = false; 
    this.tokenRecaptcha = '';
  }

    ngOnInit() {

      this.loadAPI = new Promise(() => {               
        this.loadScriptGetDevice();
      });
      
      this.filteredOptionsSetores = this.formBuscaSetorControl.valueChanges
      .pipe(
        startWith(''),
        map(setor => setor? this._filterSetor(setor) : this.setoresFilter.slice() )
      )

      const usuario = this.clienteService.obterUsuario();

      this.clienteService.getVeiculos(
        this.clienteService.isFrotistaAdminLogado() ? 
        this.clienteService.obterDocumentoOriginalAdminFrotista():
        usuario.documento, 
        usuario.frotista
        && this.clienteService.getPerfilUsuario() === 'frotista' ? 
        "frotista" : 
        "comum" )
      .subscribe(
        result => {
          this.veiculos = result.veiculos;          

          if(this.veiculos.length > 0){
            const veiculoFavorito = this.veiculos.filter(x => x.favorito)[0];
            if(veiculoFavorito !== null && veiculoFavorito !== undefined)
             this.veiculo = veiculoFavorito;
            else this.veiculo = this.veiculos[0];
          }
        },
        () => {
          
        }
      );   

      this.cartaoService.getCartoes(this.clienteService.obterUsuario().documento).subscribe(res => {
        this.tokenCartaoCompra = res[0]?.uuidCartaoEstapar;
      });
      
      this.getDadosMapa(); 
    }

    activeCad(tarifaCad: Tarifa){

      if(tarifaCad === undefined || tarifaCad === null){
                    Swal.fire({
                      html: this.translationService.instant('TariffNotFoundForTypeVehicle'),
                      titleText: this.translationService.instant('AlertInfo'),
                      icon: 'warning',                 
                      confirmButtonColor: '#4a9f42'
                    });
                    return; 
                  }

      this.setorTarifasUtilizacaoCad.forEach(tarifa => tarifa.Selected = false );
      tarifaCad.Selected = true;
      this.quantidadeCad = tarifaCad.Quantidade;
      this.tempo = tarifaCad.Tempo;
      this.valor = tarifaCad.Valor;
    }

    setSetorSelectedById(idSetor: number){

      if(this.veiculo == undefined 
         || this.veiculo == null
          || this.veiculo?.tipo == undefined
           || this.veiculo?.tipo == null){
            Swal.fire({
              text: this.translationService.instant('VehicleNotRegistered'),
              titleText: this.translationService.instant('AlertInfo'),
              icon: 'warning',                 
              confirmButtonColor: '#4a9f42'
            });      
            
            this._passoAtivacaoCad = 0;
            return; 
           }

      const setorFilter = this._findSetorById(idSetor);
      if(setorFilter){

        this.filialService.getTarifasByArea(setorFilter.IdArea).subscribe(res => {

          let setorTarifas = res.filial.areas[0].setores.find(x => x.id == setorFilter.Id);

          this
                .setorTarifas
                  .push(new SetorTarifa(setorTarifas.id,
                                        setorTarifas.tarifas.map(tarifa => {
                                                            return new Tarifa(tarifa.tempo, 
                                                                              tarifa.valor, 
                                                                              tarifa.quantidade, 
                                                                              tarifa.tipoVeiculo,
                                                                              tarifa.descricaoRegra)}),
                                        setorTarifas.horarios.map(horario => {
                                          return new Horario(horario.descricao, 
                                                              horario.dia, 
                                                              horario.inicio, 
                                                              horario.fim, 
                                                              horario.permitido)}                                            
                                            )                              
                   )
                  );

                  this._passoAtivacaoCad = 2;

                  this.dadosSetor.info.idSetor = setorFilter.Id;
                  this.dadosSetor.info.logradouro = setorFilter.Descricao;
                  this.dadosSetor.info.bairro = setorFilter.Bairro;
                  this.latitudeUtilizacao = setorFilter.Latitude;
                  this.longitudeUtilizacao = setorFilter.Longitude;
                  this.logradouro = setorFilter.Descricao;    
                  
                  let setorTarifa = this._findTarifasBySetor(setorFilter.Id);

                  if(!setorTarifa){
                    Swal.fire({
                      text: this.translationService.instant('TariffNotFound'),
                      titleText: this.translationService.instant('AlertInfo'),
                      icon: 'warning',                 
                      confirmButtonColor: '#4a9f42'
                    });     
                    this._passoAtivacaoCad = 0;
                    return false;              
                  }

                  this.setorTarifasUtilizacaoCad =
                                        setorTarifa
                                          .Tarifas
                                            .filter(option => option.TipoVeiculo === this.veiculo.tipo)
                                              .map(tarifa => new Tarifa(tarifa.Tempo, 
                                                                        tarifa.Valor, 
                                                                        tarifa.Quantidade, 
                                                                        tarifa.TipoVeiculo,
                                                                        tarifa.DescricaoRegra));
                  
                  // if(this.setorTarifasUtilizacaoCad.length === 0){
                  //   Swal.fire({
                  //     html: this.translationService.instant('TariffNotFoundForTypeVehicle'),
                  //     titleText: this.translationService.instant('AlertInfo'),
                  //     icon: 'warning',                 
                  //     confirmButtonColor: '#4a9f42'
                  //   });                  
                  //   this._passoAtivacaoCad = 0;
                  //   return false; 
                  // }

                  var horariosSetor = this.horariosPossiveis(this.setorTarifas.find(x => x.IdSetor === this.dadosSetor.info.idSetor).Horarios);
                  if(horariosSetor.length > 0){
                    this.horarioFuncionamentoSetor = `${horariosSetor[0].Inicio} às ${horariosSetor[0].Fim}`;
                  }
                  else{
                    this.horarioFuncionamentoSetor = this.translationService.instant('OutOfOperation');
                  }                    

                  this.activeCad(this.setorTarifasUtilizacaoCad[0]);
        });                                                          
      }
    }

    setSetorSelected(setorDescricao: string){

      if(this.veiculo == undefined 
        || this.veiculo == null
         || this.veiculo?.tipo == undefined
          || this.veiculo?.tipo == null){
           Swal.fire({
            text: this.translationService.instant('VehicleNotRegistered'),
            titleText: this.translationService.instant('AlertInfo'),
            icon: 'warning',                 
            confirmButtonColor: '#4a9f42'
          });       
           this._passoAtivacaoCad = 0;
           return; 
          }
     
      const setorFilter = this._filterSetor(setorDescricao)[0];      

      if(setorFilter){

          this.filialService.getTarifasByArea(setorFilter.IdArea).subscribe(res => {
          
          let setorTarifas = res.filial.areas[0].setores.find(x => x.id == setorFilter.Id);

          this
                .setorTarifas
                  .push(new SetorTarifa(setorTarifas.id,
                                        setorTarifas.tarifas.map(tarifa => {
                                                            return new Tarifa(tarifa.tempo, 
                                                                              tarifa.valor, 
                                                                              tarifa.quantidade, 
                                                                              tarifa.tipoVeiculo, 
                                                                              tarifa.descricaoRegra)}),
                                        setorTarifas.horarios.map(horario => {
                                          return new Horario(horario.descricao, 
                                                              horario.dia, 
                                                              horario.inicio, 
                                                              horario.fim, 
                                                              horario.permitido)}                                            
                                            )                              
                   )
                  );

          this._passoAtivacaoCad = 2;

          this.dadosSetor.info.idSetor = setorFilter.Id;
          this.dadosSetor.info.logradouro = setorFilter.Descricao;
          this.dadosSetor.info.bairro = setorFilter.Bairro;
          this.latitudeUtilizacao = setorFilter.Latitude;
          this.longitudeUtilizacao = setorFilter.Longitude; 
          this.latitude = parseFloat(setorFilter.Latitude); //parseFloat(setorFilter.Latitude);
          this.longitude = parseFloat(setorFilter.Longitude); //parseFloat(setorFilter.Longitude);
          this.logradouro = setorFilter.Descricao;

          this.map.setCenter({ lat: this.latitude, lng: this.longitude });
                
          let setorTarifa = this._findTarifasBySetor(setorFilter.Id);

          if(!setorTarifa){
              Swal.fire({
                text: this.translationService.instant('TariffNotFound'),
                titleText: this.translationService.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });   
              this._passoAtivacaoCad = 0;
              return false; 
          }

          this.setorTarifasUtilizacaoCad =
                                setorTarifa
                                  .Tarifas
                                    .filter(option => option.TipoVeiculo === this.veiculo.tipo)
                                      .map(tarifa => new Tarifa(tarifa.Tempo, 
                                                                tarifa.Valor, 
                                                                tarifa.Quantidade, 
                                                                tarifa.TipoVeiculo,
                                                                tarifa.DescricaoRegra));

          // if(this.setorTarifasUtilizacaoCad.length === 0){
          //     Swal.fire({
          //       html: this.translationService.instant('TariffNotFoundForTypeVehicle'),
          //       titleText: this.translationService.instant('AlertInfo'),
          //       icon: 'info',                 
          //       confirmButtonColor: '#4a9f42'
          //     });   
          //     this._passoAtivacaoCad = 0;
          //     return false; 
          // }

          var horariosSetor = this.horariosPossiveis(this.setorTarifas.find(x => x.IdSetor === this.dadosSetor.info.idSetor).Horarios);
          if(horariosSetor.length > 0){
            this.horarioFuncionamentoSetor = `${horariosSetor[0].Inicio} às ${horariosSetor[0].Fim}`;
          }
          else{
            this.horarioFuncionamentoSetor = this.translationService.instant('OutOfOperation');
          }        

          this.activeCad(this.setorTarifasUtilizacaoCad[0]);
        });                                                 
      }
    }   
    
  // confirmaVeiculo(){
  //  this._passoAtivacaoCad = 2; 
  // }
 
  calcularTempoFimCad(horas: number){
    var dataAtual = new Date();
    dataAtual.setHours(dataAtual.getHours() + horas);
    return `${this.pad(dataAtual.getHours(),2)}:${this.pad(dataAtual.getMinutes(), 2)}:${this.pad(dataAtual.getSeconds(), 2)}`;   
  }

  calcularTempoCad(minutos: number){
    return ( (minutos / 60) < 1 ) ? `${minutos} ${this.translationService.instant('MinuteCad')}` : `${minutos / 60} ${this.translationService.instant('HourCad')}`;
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  setarCar(param){
    this.veiculo = param;

    //const setorFilter = this._findSetorById(this.dadosSetor.info.idSetor);
    let setorTarifa = this._findTarifasBySetor(this.dadosSetor.info.idSetor);    

    this.setorTarifasUtilizacaoCad =
                          setorTarifa
                            .Tarifas
                              .filter(option => option.TipoVeiculo === this.veiculo.tipo)
                                .map(tarifa => new Tarifa(tarifa.Tempo, 
                                                          tarifa.Valor, 
                                                          tarifa.Quantidade, 
                                                          tarifa.TipoVeiculo,
                                                          tarifa.DescricaoRegra));   
                  
     this.activeCad(this.setorTarifasUtilizacaoCad[0]);
  }

  resetAtivacao() {
    this._passoAtivacaoCad = 0;
    this.formBuscaSetorControl.setValue('');
  } 

  openSetores(){
    const modalRef = this.modalService.open(AtivacaoCADSetorComponent, {centered: true, size: 'lg'});

    modalRef.componentInstance.setoresFilter = this.setoresFilter;
    modalRef.componentInstance.selectSetor.subscribe((setorFiltered: SetorFilter) => {
      this.setSetorSelected(setorFiltered.Descricao);
    })
  }

  openCars(){

    this.ngxLoader.start();

    const modalRef = this.modalService.open(AtivacaoCADVeiculoComponent, { centered: true, size: 'sm' });
    modalRef.componentInstance.veiculos = this.veiculos;
    
    modalRef.componentInstance.selectVeiculo.subscribe(
      result => {
        this.setarCar(result);
      },
      () => {
       
      }
    );
    this.ngxLoader.stop();
  }

  openInfoRegra(){
    const modalRef = this.modalService.open(RegraZadInformativoComponent, {centered: true, size: 'lg'});
  }

  ConfirmarAtivacao = () => {     

    if(!this._setorValidoUtilizacaoCad()){   
      Swal.fire({
        html: this.translationService.instant('PlaceIsRequired'),
        titleText: this.translationService.instant('AlertInfo'),
        icon: 'warning',                 
        confirmButtonColor: '#4a9f42'
      });    
      return;
    }

    if(!this._veiculoValidoUtilizacaoCad()){ 
      Swal.fire({
        text: this.translationService.instant('VehicleIsRequired'),
        titleText: this.translationService.instant('AlertInfo'),
        icon: 'warning',                 
        confirmButtonColor: '#4a9f42'
      });     
      return;
    }

    if(!this._validarUtilizacaoCad()){   
      Swal.fire({
        text: this.translationService.instant('CadIsRequired'),
        titleText: this.translationService.instant('AlertInfo'),
        icon: 'warning',                 
        confirmButtonColor: '#4a9f42'
      });      
      return;
    }

    if(this.horariosPossiveis(this.setorTarifas.find(x => x.IdSetor === this.dadosSetor.info.idSetor).Horarios).length === 0){
      Swal.fire({
        text: this.translationService.instant('ActivationOutOperation'),
        titleText: this.translationService.instant('AlertInfo'),
        icon: 'warning',                 
        confirmButtonColor: '#4a9f42'
      });      
      return;
    }

    Swal.fire({
      title: this.translationService.instant('MsgConfirmCadActivation'),
      html: `
                <div class="list-group">
                <a href="##" class="list-group-item list-group-item-action flex-column align-items-start destaque-item-list-utilizacao">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1"><b>${this.translationService.instant('Vehicle')}</b></h4>                    
                  </div>
                  <div class="d-flex w-100 justify-content-between">
                    <h5>${this.veiculo.placa}</h5>                   
                  </div>                                    
                </a>  
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start destaque-item-list-utilizacao">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1"><b>Logradouro</b></h4>                    
                  </div> 
                  <div class="d-flex w-100 justify-content-between">
                    <h5>${this.dadosSetor.info.logradouro}</h5>                 
                  </div>                  
                </a>
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start destaque-item-list-utilizacao">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1"><b>Bairro</b></h4>                   
                  </div> 
                  <div class="d-flex w-100 justify-content-between">
                    <h5>${this.dadosSetor.info.bairro}</h5>                
                  </div>                   
                </a>      
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start destaque-item-list-utilizacao">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1"><b>Regra</b></h4>                    
                  </div> 
                  <div class="d-flex w-100 justify-content-between">
                    <h5>${this.regraDescricao}</h5>                
                  </div>                    
                </a>      
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start destaque-item-list-utilizacao">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1"><b>Quantidade de CAD(s)</b></h4>                    
                  </div>  
                  <div class="d-flex w-100 justify-content-between">
                    <h5>${this.quantidadeCad} CAD(s) - ${ (this.tempo / 60) < 1 ? this.tempo + ' minutos ' : (this.tempo/60) + ' Hora(s)'} </h5>                
                  </div>                  
                </a>                     
              </div>              
      `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#4a9f42',     
      confirmButtonText: this.translationService.instant('YesUse'),
      cancelButtonText: this.translationService.instant('No'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        this.ngxLoader.start('ativacaoCad');       
        
        const cliente = this.clienteService.obterUsuario();

        const documentoGetSaldo = this.clienteService.isFrotistaAdminLogado() ?
                                  this.clienteService.obterDocumentoOriginalAdminFrotista() : 
                                  cliente.documento;
        
        const tipoUsuario = this.clienteService.isFrotista()
                            && this.clienteService.getPerfilUsuario() === 'frotista' ? "frotista" : "comum";
        
        this.clienteService.getSaldos(documentoGetSaldo, tipoUsuario)
              .subscribe(res => {              
                if(cliente.frotista && this.clienteService.getPerfilUsuario() === 'frotista' ){
                  this._utilizarCadFrotista(res);
                }
                else{
                  this._utilizarCadPerfilPessoal(res);
                }  
        }); 
      }
    })    
  }; 
  
  private MensagemLimiteAtivacoesAtingido(message: string){
    Swal.fire({
      title: this.translationService.instant('AlertInfo'),
      text: message,
      icon: 'warning',
      reverseButtons: true
    })
  }

  private ConfirmarAtivacaoIgnorandoLimite(){

    this.ngxLoader.start('ativacaoCad'); 

    const cliente = this.clienteService.obterUsuario();

    const documentoGetSaldo = this.clienteService.isFrotistaAdminLogado() ?
                              this.clienteService.obterDocumentoOriginalAdminFrotista() : 
                              cliente.documento;
    
    const tipoUsuario = this.clienteService.isFrotista()
                        && this.clienteService.getPerfilUsuario() === 'frotista' ? "frotista" : "comum";
    
    this.clienteService.getSaldos(documentoGetSaldo, tipoUsuario)
          .subscribe(res => {              
            if(cliente.frotista && this.clienteService.getPerfilUsuario() === 'frotista' ){
              this._utilizarCadFrotista(res, true);
            }
            else{
              this._utilizarCadPerfilPessoal(res, true);
            }  
    });
  }

  private _utilizarCadCompraEWallet(requestUtilizacao: CompraUtilizacaoCarteiraFrotista){
    this.recaptchaV3Service.execute('UtilizarCadCompraEWallet').subscribe((token: string): void => {
      this.tokenRecaptcha = token; 

    this.cadService.CompraEWalletUtilizarCad(
      requestUtilizacao, 
      this.clienteService.obterUsuario().documento, this.guidPagamento, this.tokenRecaptcha)
        .subscribe(
          res => {
            this.activeModal.close();
            this.ngxLoader.stop('ativacaoCad');
            
            this.cadUtilizado.emit(res);
            this.extratoService.atualizarExtrato();
            this.clienteService.atualizarSaldo();
          },
          error => {
            this.ngxLoader.stop('ativacaoCad');

            if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
              this.MensagemLimiteAtivacoesAtingido(error?.message);
              return;
            }

            Swal.fire({
              html: error.message,
              titleText: this.translationService.instant('AlertInfo'),
              icon: 'info',                 
              confirmButtonColor: '#4a9f42'
            });
          });
     });           
  } 
  
  private _verificarLimitacaoAtivacoes(httpCodigo: string) : boolean{
    return httpCodigo === "428";
  }

  private _utilizarCadPerfilPessoal(responseSaldos: any, ignoraMaximoCADs: boolean = false){    

    const saldoCredito = responseSaldos?.saldo;
    const saldoCAD = responseSaldos?.cartoes?.find(x => x.desc === "CAD")?.quantidade;

    if(this.quantidadeCad <= saldoCAD){
      this.cadService.UtilizarCad(this._criarRequestUtilizacao(ignoraMaximoCADs), 
                                  this.clienteService.obterUsuario().documento, this.guidPagamento)
              .subscribe(
                res => {        
                  this.activeModal.close();         
                  this.ngxLoader.stop('ativacaoCad');
                  
                  this.cadUtilizado.emit(res);
                  this.extratoService.atualizarExtrato();
                  this.clienteService.atualizarSaldo();
                },
                error => {
                  this.ngxLoader.stop('ativacaoCad');
                  
                  if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
                    this.MensagemLimiteAtivacoesAtingido(error?.message);                   
                    return;
                  }

                  Swal.fire({
                    html: error?.message !== null && error?.message !== '' ? error?.message : this.translationService.instant('FatalError'),
                    titleText: this.translationService.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                  });                    
                }); 
                return;                                                         ;
    }

    if(this.valor <= saldoCredito ){
      this.recaptchaV3Service.execute('AtivacaoCad').subscribe((token: string): void => {
        this.tokenRecaptcha = token; 
    
      this.cadService.CompraEWalletUtilizarCad(this._criarRequestUtilzacaoCarteira(ignoraMaximoCADs), 
                                  this.clienteService.obterUsuario().documento, this.guidPagamento, this.tokenRecaptcha)
              .subscribe(
                res => {
                  this.activeModal.close();
                  this.ngxLoader.stop('ativacaoCad');                 
                  
                  this.cadUtilizado.emit(res);
                  this.extratoService.atualizarExtrato();
                  this.clienteService.atualizarSaldo();
                },
                error => {
                  this.ngxLoader.stop('ativacaoCad');

                  if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
                    this.MensagemLimiteAtivacoesAtingido(error?.message);
                    return;
                  }

                  Swal.fire({
                    html:  error.message,
                    titleText: this.translationService.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                  });                  
                }); 
                return;
       });       
    }
    else{

      this.recaptchaV3Service.execute('CompraCartaoUtilizarCad').subscribe((token: string): void => {
        this.tokenRecaptcha = token; 
      

      this.cadService.CompraCartaoUtilizarCad(this._criarRequestUtilizacaoCartao(ignoraMaximoCADs), 
      this.clienteService.obterUsuario().documento, this.guidPagamento, this.tokenRecaptcha)
              .subscribe(
              res => {
              this.activeModal.close();
              this.ngxLoader.stop('ativacaoCad');
              
              this.cadUtilizado.emit(res);
              this.extratoService.atualizarExtrato();
              this.clienteService.atualizarSaldo();
              },
              error => {
                this.ngxLoader.stop('ativacaoCad');

                if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
                  this.MensagemLimiteAtivacoesAtingido(error?.message);
                  return;
                }

                Swal.fire({
                  html:  error.message,
                  titleText: this.translationService.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });       
              })
            }); 
              return;         
    }
  }

  private _utilizarCadFrotista(responseSaldos: any, ignoraMaximoCADs: boolean = false) : any{    
   
    const documentoAdminFrotista = this.clienteService.obterDocumentoOriginalAdminFrotista() === null ? "" 
                                 : this.clienteService.obterDocumentoOriginalAdminFrotista();        

    const saldoCarteiraVirtual = responseSaldos?.saldos[0]?.valor;
    const saldoCAD = responseSaldos?.saldos[0]?.quantidadeCad; 
    const isAdminFrotista = this.clienteService.isFrotistaAdminLogado();
    
    if(this.quantidadeCad <= saldoCAD){
      this.cadService.UtilizarCad(this._criarRequestUtilizacaoFrotista(documentoAdminFrotista, 
                                                                       ignoraMaximoCADs, 
                                                                       this.clienteService.getCentroDeCustoFrotista()), 
                                                                       isAdminFrotista ? documentoAdminFrotista : this.clienteService.obterUsuario().documento,
                                                                        this.guidPagamento, this.clienteService.isFrotistaAdminLogado())
              .subscribe(
                res => {        
                  this.activeModal.close();         
                  this.ngxLoader.stop('ativacaoCad');
                  
                  this.cadUtilizado.emit(res);
                  this.extratoService.atualizarExtrato();
                  this.clienteService.atualizarSaldo();
                },
                error => {
                  this.ngxLoader.stop('ativacaoCad');
                  
                  if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
                    this.MensagemLimiteAtivacoesAtingido(error?.message);                   
                    return;
                  }

                  Swal.fire({
                    html: error?.message !== null && error?.message !== '' ? error?.message : this.translationService.instant('FatalError'),
                    titleText: this.translationService.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                  });                    
                }); 
                return;                                                         ;
    }

    //compra utilização com Ewallet
    if(this.valor <= saldoCarteiraVirtual){      

      this._utilizarCadCompraEWallet(new CompraUtilizacaoCarteiraFrotista(this.latitude.toString(), 
                                      this.longitude.toString(), 
                                      this.quantidadeCad, 
                                      this.tempo, 
                                      this.valor, 
                                      new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                                      new Filial(environment.FilialId, 
                                                this.dadosSetor.info.idSetor, 
                                                null, 
                                                null),
                                      this.clienteService.getCentroDeCustoFrotista(),
                                      documentoAdminFrotista,
                                      ignoraMaximoCADs));
      return;
    }
    else {

      if(!this.clienteService.isFrotistaAdminLogado()){
        Swal.fire({
          html: this.translationService.instant('WithoutBalance'),
          titleText: this.translationService.instant('AlertInfo'),
          icon: 'info',                 
          confirmButtonColor: '#4a9f42'
        });      
        this.ngxLoader.stop('ativacaoCad');
        return;
      }

      this.recaptchaV3Service.execute('CompraCartaoUtilizarCad').subscribe((token: string): void => {
        this.tokenRecaptcha = token; 
        
      this.cadService.CompraCartaoUtilizarCad(
        this._criarRequestUtilizacaoCartaoFrotista(ignoraMaximoCADs), 
        this.clienteService.obterUsuario().documento, this.guidPagamento, this.tokenRecaptcha)
          .subscribe(
            res => {
              this.activeModal.close();
              this.ngxLoader.stop('ativacaoCad');
              
              this.cadUtilizado.emit(res);
              this.extratoService.atualizarExtrato();
              this.clienteService.atualizarSaldo();
            },
            error => {
              this.ngxLoader.stop('ativacaoCad');

              if(this._verificarLimitacaoAtivacoes(error?.erro?.httpCodigo)){
                this.MensagemLimiteAtivacoesAtingido(error?.message);
                return;
              }

              Swal.fire({
                html:  error.message,
                titleText: this.translationService.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });
            });
          }); 
            return;
    }     
  }

  private _criarRequestUtilizacao(ignoraMaximoCADs: boolean = false) : UtilizacaoCad {
    return new UtilizacaoCad(this.latitude.toString(), 
                             this.longitude.toString(), 
                             this.quantidadeCad, 
                             this.tempo, 
                             this.valor, 
                             new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                             new Filial(environment.FilialId, 
                                        this.dadosSetor.info.idSetor, 
                                        null, 
                                        null),
                                        ignoraMaximoCADs);
  }

  private _criarRequestUtilizacaoFrotista(documentoAdminFrotista: string = "",
                                          ignoraMaximoCADs: boolean = false,
                                          idEmpresaFrotistaCentroCusto) : UtilizacaoCadFrotista{
    return new UtilizacaoCadFrotista(this.latitude.toString(), 
                             this.longitude.toString(), 
                             this.quantidadeCad, 
                             this.tempo, 
                             this.valor, 
                             new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                             new Filial(environment.FilialId, 
                                        this.dadosSetor.info.idSetor, 
                                        null, 
                                        null),
                                        idEmpresaFrotistaCentroCusto,
                                        documentoAdminFrotista,
                                        ignoraMaximoCADs);
  }

  private _criarRequestUtilizacaoCartaoFrotista(ignoraMaximoCADs: boolean = false){

    const documentoAdminFrotista = this.clienteService.obterDocumentoOriginalAdminFrotista() === null ? ""
                                   : this.clienteService.obterDocumentoOriginalAdminFrotista();
    
    return new CompraUtilizacaoCartaoFrotista(this.latitude.toString(), 
                                        this.longitude.toString(), 
                                        this.quantidadeCad, 
                                        this.tempo, 
                                        this.valor, 
                                        new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                                        new Filial(environment.FilialId, 
                                                  this.dadosSetor.info.idSetor, 
                                                  null, 
                                                  null),
                                        this.clienteService.getCentroDeCustoFrotista(),
                                        this.tokenCartaoCompra,
                                        documentoAdminFrotista,
                                        ignoraMaximoCADs);                                       
  }

  private _criarRequestUtilzacaoCarteira(ignoraMaximoCADs: boolean = false){
    return new CompraUtilizacaoCarteira(this.latitude.toString(), 
                                        this.longitude.toString(), 
                                        this.quantidadeCad, 
                                        this.tempo, 
                                        this.valor, 
                                        new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                                        new Filial(environment.FilialId, 
                                                  this.dadosSetor.info.idSetor, 
                                                  null, 
                                                  null),
                                                  ignoraMaximoCADs);
  }

  private _criarRequestUtilizacaoCartao(ignoraMaximoCADs: boolean = false){
    return new CompraUtilizacaoCartao(this.latitude.toString(), 
                                        this.longitude.toString(), 
                                        this.quantidadeCad, 
                                        this.tempo, 
                                        this.valor, 
                                        new VeiculoCad(this.veiculo.placa, this.veiculo.tipo),
                                        new Filial(environment.FilialId, 
                                                  this.dadosSetor.info.idSetor, 
                                                  null, 
                                                  null),
                                        this.tokenCartaoCompra,
                                        ignoraMaximoCADs);
  }

  private _setorValidoUtilizacaoCad = () => this.logradouro !== "" && this.logradouro !== " "
                                            && this.dadosSetor.info.idSetor > 0
                                            && this.latitudeUtilizacao !== "" && this.latitudeUtilizacao !== " "
                                            && this.longitudeUtilizacao !== "" && this.longitudeUtilizacao !== " ";
  
  private _veiculoValidoUtilizacaoCad = () => this.veiculo && this.veiculo !== undefined
                                              && this.veiculo.placa !== undefined
                                              && this.veiculo.placa !== "" && this.veiculo.placa !== " ";                                          

  private _validarUtilizacaoCad = () => this.quantidadeCad > 0
                                    &&  this.tempo > 0
                                    && this.valor > 0;

  /* Variáveis do Mapa */

  styleDataLayerOptions = {
    style: (feat: any) => {
      return {
          strokeColor: feat.getProperty('dadossetor').disponibilidadeCor,
          strokeWeight: 4,
          clickable: true,
          visible: true,  
          title: 'Logradouro para utilização'        
      };                    
  }
  };

  /* Funções do Mapa */
  onMapReady(map) {
    this.map = map;    
  }   

  layerSetoresClick($event) {

    this.tipoMarkerInfoGlobal = 'Setor';
         
    this.dadosSetor.info = $event.feature.getProperty('dadossetor');

    this.setSetorSelectedById(this.dadosSetor.info.idSetor);
    this.formBuscaSetorControl.setValue(this.dadosSetor.info.logradouro);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();

    this._passoAtivacaoCad = 2;    
 }

 infoWindowClose = () => {
  this.infoWindowVisible = false;
 }

  private getDadosMapa = async () => {
    
     this.filialService.getDisponibilidadeSetoresMapa()
        .subscribe(
          result => {
           
            this.setoresFilter = result.featureGeoJsonCollection.features.map( feature => {

              const latLngDefaultSetor = feature.geometry.coordinates[0]; 

              return new SetorFilter(feature.properties.dadossetor.idSetor,
                                     feature.properties.dadossetor.logradouro,                                    
                                     latLngDefaultSetor[1].toString(), 
                                     latLngDefaultSetor[0].toString(),
                                     feature.properties.dadossetor.descricaoSetor,
                                     feature.properties.dadossetor.bairro,
                                     feature.properties.dadossetor.idArea); 
            });

            this.setoresFeatureMap = result.featureGeoJsonCollection;
            
            this.ngxLoader.stop('disponibilidadeSpinner');
          },
          () => {
            this.ngxLoader.stop('disponibilidadeSpinner');
            Swal.fire({
              text:  this.translationService.instant('PlacesSearchError'),
              titleText: this.translationService.instant('AlertInfo'),
              icon: 'info',                 
              confirmButtonColor: '#4a9f42'
            })
            .then(() => {
              this.activeModal.close();
            });            
          }
          );  
  }  

  private _filterSetor(value: string ): SetorFilter[]{
    const filterValue = value.toUpperCase();
    return this.setoresFilter
                .filter(option => option.DescricaoSetor.toUpperCase().includes(filterValue)
                                  || option.Descricao.toUpperCase().includes(filterValue)); //.indexOf(filterValue) === 0);
  }
  
  private _findSetorById(value: number): SetorFilter{
    return this.setoresFilter
                 .find(option => option.Id === value);
  }

  private _findTarifasBySetor(idSetor: number): SetorTarifa {
    return this.setorTarifas.find(option => option.IdSetor === idSetor);
  }

  private horariosPossiveis(horarios: Horario[]) : Horario[] {
    
    var agora = moment(); 
    //Horarios
    var horariosPossiveis = horarios.filter((h) => {
        var beginningTime = moment(h.Inicio, 'HH:mm');
        var endTime = moment(h.Fim, 'HH:mm');
        var actualTime = agora; 

        return (h.Permitido && h.Dia == parseInt(agora.format("d")) + 1)
            && actualTime.isBetween(beginningTime, endTime)             
    }).sort(function (a, b) {
        return a.Inicio - b.Inicio;
    });

    return horariosPossiveis.map(horario => {
      return new Horario(horario.Descricao, 
                         horario.Dia, 
                         horario.Inicio, 
                         horario.Fim, 
                         horario.Permitido)}
        
        );        
  }

  public loadScriptGetDevice() {
      
    let node = document.createElement('script');
    node.src = environment.UrlGetDevice.replace('{sessao}', this.guidPagamento);
    node.type = 'text/javascript';
    node.async = true;   
    document.getElementsByTagName('body')[0].appendChild(node);
  }   

  cancelar(){
    this.activeModal.close();    
  }
}