import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-extrato-detalhe',
    templateUrl: './extrato-detalhe.component.html'
})

export class ExtratoDetalheComponent implements OnInit {
    public id: string;   
    public movimento: any;
        
    constructor(public activeModal: NgbActiveModal,
                private translationService: TranslateService
                ){
            
    }
 
    setarDados(movimentoDetalhe: any){
        if(movimentoDetalhe === undefined
            || movimentoDetalhe === null) return;
            
        this.movimento = movimentoDetalhe;

        const dataTransacao = movimentoDetalhe?.data?.split(" ")[0]?.split("-");
        const dataTransacaoTime = movimentoDetalhe?.data?.split(" ")[1];
        const dataTransacaoFormatada = `${dataTransacao[2]}/${dataTransacao[1]}/${dataTransacao[0]} ${dataTransacaoTime.substr(0,8)}`;
        
        this.movimento.data = dataTransacaoFormatada;
        this.movimento.ano2021 = dataTransacao[0] <= "2021" ? true : false;

        switch(this.movimento.tipo){
            case 'utilizacao':    
            
                if(this.movimento?.tiquete === undefined || this.movimento?.tiquete === null
                    || this.movimento?.tiquete?.inicio === undefined
                    || this.movimento?.tiquete?.inicio === null
                    || this.movimento?.tiquete?.inicio === ""
                    || this.movimento?.tiquete?.fim === undefined
                    || this.movimento?.tiquete?.fim === null
                    || this.movimento?.tiquete?.fim === "") return;

                const dataInicio = movimentoDetalhe?.tiquete?.inicio?.split(" ")[0]?.split("-");;
                const dataInicioTime = movimentoDetalhe?.tiquete?.inicio?.split(" ")[1];
                const dataFim = movimentoDetalhe?.tiquete?.fim?.split(" ")[0]?.split("-");;
                const dataFimTime = movimentoDetalhe?.tiquete?.fim?.split(" ")[1];    
                const dataInicioFormatada = `${dataInicio[2]}/${dataInicio[1]}/${dataInicio[0]} ${dataInicioTime.substr(0,8)}`;
                const dataFimFormatada = `${dataFim[2]}/${dataFim[1]}/${dataFim[0]} ${dataFimTime.substr(0,8)}`;
                
                this.movimento.tiquete.inicio = dataInicioFormatada;
                this.movimento.tiquete.fim = dataFimFormatada;
                this.movimento.tiquete.tempo = this.calcularTempoCad(movimentoDetalhe?.tiquete?.tempo); //`${(movimentoDetalhe?.tiquete?.tempo / 60 < 1) ? movimentoDetalhe?.tiquete?.tempo + ' ' + this.translationService.instant('MinuteCad') : movimentoDetalhe?.tiquete?.tempo / 60} ${this.translationService.instant('HourCad')}`; 
                break;           
        }        
    }  
    
    calcularTempoCad(minutos: number){

        if(minutos === null || minutos === undefined || minutos == 0)
        return '';

        return ( (minutos / 60) < 1 ) ? `${minutos} ${this.translationService.instant('MinuteCad')}` : `${minutos / 60} ${this.translationService.instant('HourCad')}`;
    }

    ngOnInit() {
    }
}