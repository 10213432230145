<form [formGroup]="cartaoForm">
    <div class="modal-body">      
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="panel panel-default credit-card-box">
                        <div class="panel-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="numero">{{ 'Card Number' | translate }}</label>
                                            <div class="input-group">
                                                <input required maxlength="19"
                                                       [attr.disabled]="validandoCard ? true : null"
                                                       style="text-transform: uppercase;" 
                                                       #numero
                                                       class="form-control" 
                                                       name="numero" 
                                                       formControlName="numero" 
                                                       placeholder="{{'Card Number' | translate}}" 
                                                       autocomplete="cc-number" 
                                                       (blur)="verificaCartaoCampo($event)" 
                                                       (keyup)="setaBandeira($event)" 
                                                       (paste)="onPaste($event)" 
                                                       mask="0000 0000 0000 0000" />
                                                <span class="input-group-addon">
                                                    <i [hidden]="true" class="material-icons" style="margin-top: 6px;">credit_card</i>
                                                   <div><img src="assets/images/logo/{{cssBandeira}}" style="margin-top: -5px;"></div>                                                   
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>{{ 'Card Holder' | translate }}</label>
                                            <input required class="form-control" placeholder="{{ 'Card Holder' | translate }}" formControlName="portador" textOnly  style="text-transform: uppercase;"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <div class="form-group">
                                            <label for="cardExpiry"><span class="hidden-xs">{{'CardValidDate' | translate}}</span></label>
                                            <input required type="text" maxlength="3" class="form-control" name="cardExpiryMonth" formControlName="mesExpiracao" placeholder="MM" autocomplete="cc-exp-month" [textMask]="{mask: maskMonth}" />
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <div class="form-group">
                                            <label for="cardExpiry"><span class="hidden-xs" style="visibility: hidden;">_</span></label>
                                            <input type="text" maxlength="3" required class="form-control" name="cardExpiryYear" formControlName="anoExpiracao" placeholder="{{'anoCartaoPlaceHolder' | translate}}" autocomplete="cc-exp-year" [textMask]="{mask: maskYear}"/>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pull-right">
                                        <div class="form-group">
                                            <label for="cardCVC">{{ 'CodeSecurityCardLabel' | translate}}</label>
                                            <input required type="tel" class="form-control" name="cardCVC" placeholder="{{'CVV' | translate}}" autocomplete="cc-csc" formControlName="cvv" numbersOnly />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group" style="margin-top: 6px;">
                                            <label>{{ 'DocumentTitularCard' | translate }}</label>
                                            <input required class="form-control" 
                                                   id="documentoCliente" 
                                                   placeholder="{{'Document' | translate}}" 
                                                   formControlName="documentoTitular" 
                                                   matInput mask="000.000.000-00||00.000.000/0000-00"                                                                               
                                                   (paste)="onPasteDocumento($event)" 
                                                   style="text-transform: uppercase"/>  
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="display:none;">
                                    <div class="col-xs-12">
                                        <p class="payment-errors"></p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate}}</button>
        <button type="button" class="btn btn-outline-success" (click)="salvar()">{{ 'Save' | translate}}</button>
    </div>
</form>