import { Component, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Veiculo } from 'src/app/model/veiculo';
import { ClienteService } from '../../service/cliente.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-veiculo-cadastro',
    templateUrl: './veiculo-cadastro.component.html'
})

export class VeiculoCadastroComponent {
    veiculoForm: FormGroup;

    @Output()
    veiculoCadastrado: EventEmitter<Veiculo> = new EventEmitter();

    tipoVeiculos: { id: number, desc: string }[] = [
        { id: 1, desc: "Caminhão" },
        { id: 8, desc: "Automóvel" },
        { id: 13, desc: "Fretamento" }
    ];

    cadastrarComoFavorito: boolean;

    public maskPlaca = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, '-', /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];
    private regexPlaca = "/^([a-zA-Z]{3}[0-9]{4})|([a-zA-Z]{3}[0-9][a-zA-Z][0-9]{2})|([a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}|[a-zA-Z]{4}[0-9]{3})$/";

    constructor(private spinner: NgxSpinnerService
                ,public activeModal: NgbActiveModal
                ,private fb: FormBuilder
                ,private clienteService: ClienteService
                ,private translate: TranslateService)
        {
            this.veiculoForm = this.fb.group({
                placa: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
                tipoVeiculo: ['', [Validators.required]]             
            });
        }

    salvar(){

        const placa = this.veiculoForm.value.placa.replace('_','').replace('-','');
        
        if(!eval(this.regexPlaca).test(placa)){
            Swal.fire({
                text: this.translate.instant('InvalidLicensePlate') ,
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
                });      
            return;
        }

        if(placa.length < 7){
            Swal.fire({
                text: this.translate.instant('CompleteLicensePlate') ,
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
                });      
            return;
        }
        
        if(!this.veiculoForm.valid){
            Swal.fire({
                      text: this.translate.instant('RequiredFields'),
                      titleText: this.translate.instant('AlertInfo'),
                      icon: 'info',                 
                      confirmButtonColor: '#4a9f42'
                    });      
            return;
        }

        //if (this.veiculoForm.valid) {
            //this.spinner.show();

            let veiculo = this.veiculoForm.value;

            let veic = new Veiculo();
            veic.veiculo = {
                placa: placa.toUpperCase(),
                tipo: parseInt(this.veiculoForm.get('tipoVeiculo').value),
                favorito: this.cadastrarComoFavorito
                //marca: this.veiculoForm.get('marca').value.toUpperCase(),
                //cor: this.veiculoForm.get('cor').value.toUpperCase()
            };

            veic.usuario = {
                frotista: false
            }

            this.clienteService.postVeiculo(this.clienteService.obterUsuario().documento, veic).subscribe(
                result => {
                    //this.spinner.hide();
                    this.activeModal.close();
                    this.veiculoCadastrado.emit(veic);
                },
                fail => {
                    //this.spinner.hide();
                    this.activeModal.close();
                }
            );
        //}
    }

    cadastrarComoFavoritoToogle(){
        this.cadastrarComoFavorito = !this.cadastrarComoFavorito;
    }
    
}