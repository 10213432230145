import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { RelatorioFrotistaRequest, TipoTransacaoRelatorio } from 'src/app/model/relatorio-frotista-request';
import Swal from 'sweetalert2';
import { ClienteService } from '../../service/cliente.service';
import { ExtratoService } from '../../service/extrato.service';
import { RelatorioFrotistaAdminService } from '../../service/relatorio-frotista-admin.service';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { RelatorioMovimentacoesDetalheComponent } from './relatorio-movimentacoes-detalhe.component';

@Component({
    selector: 'app-relatorio-movimentacoes',
    templateUrl: './relatorio-movimentacoes.component.html',
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  })

  export class RelatorioMovimentacoesComponent {
     
      relatorioForm: FormGroup;

      periodos: {qtdDias: number, desc: string}[] = [
        { qtdDias: 7, desc: "7 dias" },
        { qtdDias: 15, desc: "15 dias" },
        { qtdDias: 30, desc: "30 dias" },
        { qtdDias: 60, desc: "60 dias" },
        { qtdDias: 0, desc: "Período De-Até" }
      ];
      tiposTransacao: {id: number, desc: string}[] = [
        {id: 0, desc: "Todas"},
        {id: 1, desc: "Crédito"},
        {id: 2, desc: "Utilização"},
      ];
      dateNull?: NgbDate;
     
      pageSize = 10;
      page = 1;
      previousPage: number;
      totalRegistros = 0;
      movimentacoes: Array<any> [] = [];
      
      public maskPlaca = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, '-', /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];

      constructor( public activeModal: NgbActiveModal
                  ,private fb: FormBuilder
                  ,private relatorioFrotistaAdminService: RelatorioFrotistaAdminService
                  ,private translate: TranslateService
                  ,private modalService: NgbModal){

                    this.relatorioForm = this.fb.group({
                      periodo: [7, [Validators.required]],
                      periodoInicial: [this.dateNull, []],
                      periodoFinal: [this.dateNull, []],
                      tipoTransacaoCredito: [true, []],
                      tipoTransacaoUtilizacao: [true, []],
                      placa: ['', []],
                      cpfUsuario: ['', []]
                    }, { updateOn: 'change'});           
      }

      consultar(exportar: boolean){

        if(!this.relatorioForm.valid){
          Swal.fire({
                    text: this.translate.instant('RequiredFields'),
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42',
                    width: 'auto'
                  });      
          return;
       }       

       this.relatorioFrotistaAdminService.consultar(this.montarObjetoRequestRelatorio(exportar))
            .subscribe(res => {
              this.movimentacoes = res.movimentoRecargaSiteExportByUsuario;
              this.totalRegistros = res.totalRegistros;
            });
      }

      baixar(exportar: boolean){        

        //método 1
        //  var fileWindow = window.open(`${environment.BaseUrlFunction}site/v1/downloadrelatoriomovimentacoes?code=${environment.FuncCodeBaixarRelatorioMovimentacoes}`);
        //  fileWindow.open();

         //método 2
        this.relatorioFrotistaAdminService.exportar(this.montarObjetoRequestRelatorio(exportar)).subscribe(file => {
            var blob = new Blob([file], {type: "application/octet-stream"});
            saveAs.saveAs(blob, "Relatorio.xlsx");
        });

        //  this.extratoService.getFile().subscribe( (file: any) => {
        //   var blob = new Blob([file], {type: "application/octet-stream"});
        //   saveAs.saveAs(blob, "teste.xlsx");
        //    //this.downLoadFile(file, "application/octet-stream") 
        // });
      }

      loadPage(page: number) {    
        if (this.page !== this.previousPage) {
          this.previousPage = page;    
          this.consultar(false);    
        }      
      }

      detalheMovimento(movimento: any, nomeUsuario: string){

        if(movimento.nome === null || movimento.nome === undefined)
         movimento.nome = nomeUsuario;

        const modalRef = this.modalService.open(RelatorioMovimentacoesDetalheComponent, { centered: true, size: 'md' });
      
        modalRef.componentInstance.setarDados(movimento);          
      }

      private montarObjetoRequestRelatorio(exportar: boolean) : RelatorioFrotistaRequest{
        let tiposTransacao: TipoTransacaoRelatorio = null;

        if(this.relatorioForm.value.tipoTransacaoCredito && this.relatorioForm.value.tipoTransacaoUtilizacao)
          tiposTransacao = TipoTransacaoRelatorio.Todas;
        else if(this.relatorioForm.value.tipoTransacaoCredito)
          tiposTransacao = TipoTransacaoRelatorio.Creditos;
        else if(this.relatorioForm.value.tipoTransacaoUtilizacao)
          tiposTransacao = TipoTransacaoRelatorio.Utilizacoes;        
       
        return new RelatorioFrotistaRequest(
          this.relatorioForm.value.periodo, //as number,
          this.relatorioForm.value.periodo != 0 ? null : this._getDateParamsFromDateString(this.relatorioForm.value.periodoInicial),
          this.relatorioForm.value.periodo != 0 ? null : this._getDateParamsFromDateString(this.relatorioForm.value.periodoFinal),
          tiposTransacao,
          this.relatorioForm.value?.placa?.replace("-",""),
          this.relatorioForm.value.cpfUsuario,
          this.relatorioFrotistaAdminService.getCentroDeCustoFrotista(),
          this.page,
          this.pageSize,
          exportar
        );
      }

      private _getDateParamsFromDateString(data: NgbDateStruct) : string{
        //const dateParts = data.split('-');
        return  `${data.year}-${this.pad(data.month, 2)}-${this.pad(data.day, 2)}`;
        //return `${dateParts[0]}-${this.pad(dateParts[1], 2)}-${this.pad(dateParts[2], 2)}`;
      }

      dateMask(event: any) {
        var v = event.target.value;
        if (v.match(/^\d{2}$/) !== null) {
          event.target.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
          event.target.value = v + '/';
         }
      }  
      
      pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
      }
  }