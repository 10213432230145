import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FiliaisService } from '../shared/service/filiais.service';

@Component({
    selector: 'app-consulta-cad',
    templateUrl: './consulta-cad.component.html'
  })

  export class ConsultaCadComponent {     
      public htmlBuscaCadPage: any;

      constructor(//config: NgbModalConfig
                  private sanitized: DomSanitizer
                  ,public activeModal: NgbActiveModal
                  ,private filialService: FiliaisService){

           
      }      
  }