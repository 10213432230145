import { AfterViewInit, Component, EventEmitter, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClienteService } from '../../service/cliente.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import CryptoJS from "crypto-js";
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cadastro-completo-senha',
    templateUrl: './cadastro-completo-senha.component.html'
})

export class CadastroCompletoSenhaComponent implements AfterViewInit, OnInit {

    @Input() areaLogada: boolean;
    @Input() email: string;
    private documentoTrocaSenha: string;
    cadastroNovaSenha: FormGroup;

    public mask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];  
     
    protected chaveCriptografia: string = environment.ChaveCriptografia;

    aderiuCadastroLeadFrotista: EventEmitter<void> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal
        , private fb: FormBuilder,
          private clienteService: ClienteService,
          private spinner: NgxSpinnerService,
          private translate: TranslateService) {            
    }
  ngOnInit(): void {

    this.documentoTrocaSenha = this.areaLogada ?
                                      this.clienteService.isFrotistaAdminLogado() ?
                                      this.clienteService.obterDocumentoOriginalAdminFrotista() :
                                      this.clienteService.obterUsuario().documento 
                                    : null;
    
    this.cadastroNovaSenha = this.fb.group({
      senhaAtual: ['', this.areaLogada ? [Validators.required, Validators.minLength(6), Validators.maxLength(15)] : []],
      novaSenha: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      confirmaNovaSenha: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      documento: [this.areaLogada ? [this.documentoTrocaSenha, Validators.required]: []],
      token: ['', !this.areaLogada ? [Validators.required]: []]      
    });    
  }
  
  ngAfterViewInit(): void {
    this.cadastroNovaSenha.value.token = "";
    this.cadastroNovaSenha.value.senhaAtual = "";
    this.cadastroNovaSenha.value.novaSenha = "";
    this.cadastroNovaSenha.value.confirmaNovaSenha = "";
    this.cadastroNovaSenha.reset();      
  }

    salvarSenha() {
       
        if(!this.cadastroNovaSenha.valid){
          Swal.fire({
                    text: this.translate.instant('RequiredFields'),
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42',
                    width: 'auto'
                  });      
        return;
        }
        
        if(!this.areaLogada 
          && (this.cadastroNovaSenha.value.token === ""
              || this.cadastroNovaSenha.value.token === null
              || this.cadastroNovaSenha.value.token === undefined
              || this.cadastroNovaSenha.value.token?.length < 1)
        ){
            Swal.fire({
              text: this.translate.instant('RequiredCodeVerification'),
              titleText: this.translate.instant('AlertInfo'),
              icon: 'info',                 
              confirmButtonColor: '#4a9f42'
            });      
            return;
        }
        if(this.areaLogada)
          this.cadastroNovaSenha.value.token = "";       
        
        if(!this._validarForcaDaSenha()){
            Swal.fire({
                html: this.translate.instant('CriterionPassword'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });      
            return;
        }  

        var senhaAtual = this.encrypt(this.cadastroNovaSenha.value.senhaAtual, this.chaveCriptografia);
        var senhaNova = this.encrypt(this.cadastroNovaSenha.value.novaSenha, this.chaveCriptografia);

            this.clienteService
                  .postAlterarSenha(
                      this.areaLogada ? this.documentoTrocaSenha : null, 
                      this.email,
                      senhaAtual, 
                      senhaNova, 
                      this.cadastroNovaSenha.value.token)
                    .subscribe(
                
                result => {  
                    this.aderiuCadastroLeadFrotista.emit();   
                    Swal.fire({
                        text: this.translate.instant('UpdatedPassword'),
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'success',                 
                        confirmButtonColor: '#4a9f42'
                      }).then(result =>{
                        if(result.value)  this.activeModal.close();
                    });
                },
                fail => {                    
                    Swal.fire({
                        text: fail.message,
                        titleText: this.translate.instant('AlertInfo'),
                        icon: 'info',                 
                        confirmButtonColor: '#4a9f42'
                      });                   
                }
            )            
        }

        private _validarForcaDaSenha() : boolean{
          if(this.cadastroNovaSenha.value.novaSenha.length < 8) return false;          
          if(!this.cadastroNovaSenha.value.novaSenha.match(/[A-Z]+/)) return false;
          if(!this.cadastroNovaSenha.value.novaSenha.match(/[0-9]+/)) return false;           

          return true;
      }

        encrypt (senha, key):string {
     
            var keySize = 256;
            var salt = CryptoJS.lib.WordArray.random(16);

            var key = CryptoJS.PBKDF2(key, salt, {
                keySize: keySize/32,
                iterations: 100
              });

            var iv = CryptoJS.lib.WordArray.random(128/8);      

            var encrypted = CryptoJS.AES.encrypt(senha, key, { 
              iv: iv, 
              padding: CryptoJS.pad.Pkcs7,
              mode: CryptoJS.mode.CBC        
            });

            var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
            return result;
          }  
}
