import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { CadastroBasicoClienteComponent } from '../shared/components/cadastroCliente/cadastro-basico-cliente.component';
import { TranslateService } from '@ngx-translate/core';
import { ClienteService } from '../shared/service/cliente.service';
import Swal from 'sweetalert2';
import { CadastroLeadFrotistaComponent } from '../shared/components/cadastroLeadFrotista/cadastro-lead-frotista.component';
import { EsqueceuSenhaComponent } from '../shared/components/esqueceuSenha/esqueceu-senha.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConsultaCadComponent } from './consulta-cad.component';
import { FiliaisService } from '../shared/service/filiais.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent {

    loginForm: FormGroup;
    msgError: string = "";
    public maskLogin = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];

    constructor(
        private translate: TranslateService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private loginService: LoginService,
        private clienteService: ClienteService,
        private filialService: FiliaisService) {

        this.loginForm = this.fb.group({
            user: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    onLoggedin() {

        const aceiteLgpd = localStorage.getItem('app.lgpd');
        if (aceiteLgpd === null || aceiteLgpd === undefined || aceiteLgpd === 'false') {
            Swal.fire({
                text: this.translate.instant('LgpdAggree'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42'
            });
            return;
        }

        if (!this.loginForm.valid) {
            Swal.fire({
                text: this.translate.instant('IdentificationFormInvalid'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42'
            });
            return;
        }

        if (this.loginForm.valid) {

            this.ngxLoader.start();
            this.loginService.login(this.onlyLetterNumber(this.loginForm.value.user), this.loginForm.value.password)
                .subscribe(
                    retorno => {

                        this.ngxLoader.stop();

                        const comercioEspecial = environment
                            .ComerciosEspeciais
                            .filter(x => x.Cnpj === retorno.usuario.documento && x.Liberado).length > 0;

                        if (retorno?.usuario?.ehComercio === true
                            && !comercioEspecial) {
                            this.router.navigate(['/login']);
                            Swal.fire({
                                title: this.translate.instant('AlertInfo'),
                                text: this.translate.instant('TradeNotAllowed'),
                                icon: "warning",
                                confirmButtonColor: '#3085d6'
                            });
                            return;
                        }

                        if (this.isCnpjAdminFrotaLogin(retorno)
                            || this.isEmpresaFrotista(retorno)) {
                            this.router.navigate(['/login']);
                            Swal.fire({
                                title: this.translate.instant('AlertInfo'),
                                text: this.translate.instant('OwnerFleetAccessNotAllowed'),
                                icon: "warning",
                                confirmButtonColor: '#3085d6'
                            });
                            return;
                        }

                        localStorage.setItem('isLoggedin', 'true');

                        this.loginService.setHashStorageDevice(this.loginForm.value.user, retorno.hashStorageDevice);

                        this.loginService.persistirUserApp(retorno, comercioEspecial);

                        this.escolhaPerfilUsuario(retorno, comercioEspecial);
                    },
                    fail => {

                        this.ngxLoader.stop();

                        if (fail.erro !== undefined) {
                            if (this.translate.currentLang == "pt") {
                                this.msgError = fail.erro.dicionario.portugues;
                            } else if (this.translate.currentLang == "en") {
                                this.msgError = fail.erro.dicionario.ingles;
                            } else {
                                this.msgError = fail.erro.dicionario.portugues;
                            }
                        }

                        Swal.fire({
                            text: `${this.msgError}`,
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',
                            confirmButtonColor: '#4a9f42'
                        });

                        localStorage.removeItem('isLoggedin');
                        //this.spinner.hide();
                    }
                );
        }
    }

    open() {
        const aceiteLgpd = localStorage.getItem('app.lgpd');
        if (aceiteLgpd === null || aceiteLgpd === undefined || aceiteLgpd === 'false') {
            Swal.fire({
                text: this.translate.instant('LgpdAggree'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42'
            });
            return;
        }

        const modalRef = this.modalService.open(CadastroBasicoClienteComponent, { centered: true, size: 'lg' });
    }

    openEsqueceuSenha() {

        const aceiteLgpd = localStorage.getItem('app.lgpd');
        if (aceiteLgpd === null || aceiteLgpd === undefined || aceiteLgpd === 'false') {
            Swal.fire({
                text: this.translate.instant('LgpdAggree'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42'
            });
            return;
        }

        const modalRef = this.modalService.open(EsqueceuSenhaComponent, { centered: true, size: 'lg' })
    }

    onlyLetterNumber(param: string) {
        return param.replace(/[^A-Za-z0-9]+/g, '');
    }

    keyUpLogin(event) {
        if (this.loginForm.get('user').value.replace(/\D/g, '').length >= 11 && (event.keyCode >= 48 && event.keyCode <= 57)) {
            this.maskLogin = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
        } else {
            this.maskLogin = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
        }
    }

    private isCnpjAdminFrotaLogin(retornoLogin: any): boolean {
        return (retornoLogin.usuario.documento.length === 14
            && retornoLogin.usuario.frotista
            && retornoLogin.usuario.adminFrotista)
    }

    private isEmpresaFrotista(retornoLogin: any): boolean {
        return retornoLogin.usuario.EmpresaFrotista;
    }

    private escolhaPerfilUsuario(retornoLogin: any, comercioEspecial: boolean = false) {

        //this.clienteService.setFingerPrintIdSession();

        if (!comercioEspecial
            && retornoLogin.usuario.documento.length === 14
            &&
            !retornoLogin.usuario.EmpresaFrotista && (

                localStorage.getItem('app.verDepoisCadastroLeadFrotista') === 'true'
                || localStorage.getItem('app.verDepoisCadastroLeadFrotista') === undefined
                || localStorage.getItem('app.verDepoisCadastroLeadFrotista') === null
            )
        ) {
            this.escolhaSolucaoFrotista();
            return;
        }

        if (retornoLogin.usuario.documento.length === 14) {

            this.clienteService.setPerfilUsuario('pessoal');
            localStorage.setItem('app.token', retornoLogin.usuario.token);
            localStorage.setItem('app.user', JSON.stringify(retornoLogin.usuario));
            this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
            return;
        }

        if (this.clienteService.isFrotista()
            && (this.clienteService.getPerfilUsuario() !== 'pessoal'
                || this.clienteService.getPerfilUsuario() !== 'frotista')
        ) {
            Swal.fire({
                text: this.translate.instant('ChoosePerfilAccess'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#4a9f42',
                confirmButtonText: this.translate.instant('Personal'),
                cancelButtonText: this.translate.instant('Corporate')
            }).then((result) => {
                if (result.value) {
                    localStorage.setItem('app.user', JSON.stringify(retornoLogin.usuario));
                    localStorage.setItem('app.token', retornoLogin.usuario.token);
                    this.clienteService.setPerfilUsuario('pessoal');
                } else {

                    this.clienteService.setPerfilUsuario('frotista');
                    if (retornoLogin.usuario.adminFrotista) {
                        localStorage.setItem('app.token', retornoLogin.usuarioAdmin.token);
                        localStorage.setItem('app.user', JSON.stringify(retornoLogin.usuarioAdmin));
                        localStorage.setItem('app.documentoAdminFrotista', retornoLogin.usuario.documento);
                        localStorage.setItem('app.tokenAdminFrotista', retornoLogin.usuario.token)
                    }
                    else {
                        localStorage.setItem('app.token', retornoLogin.usuario.token);
                        localStorage.setItem('app.user', JSON.stringify(retornoLogin.usuario));
                    }
                }

                this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
                return;
            })
        }
        else {
            this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
        }
    }

    private escolhaSolucaoFrotista() {
        Swal.fire({
            title: this.translate.instant('FleetSolution'),
            text: this.translate.instant('FleetSolutionText'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#4a9f42', // confirmButtonColor: '#4a9f42',
            confirmButtonText: 'Tenho interesse',
            cancelButtonText: 'Ver depois'
        }).then((result) => {
            if (result.value) {
                const modalRef = this.modalService.open(CadastroLeadFrotistaComponent, { centered: true, size: 'lg' });
                modalRef.componentInstance.aderiuCadastroLeadFrotista.subscribe(res => {
                    localStorage.setItem('app.verDepoisCadastroLeadFrotista', 'false');
                    this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
                });
            } else {
                localStorage.setItem('app.verDepoisCadastroLeadFrotista', 'true');
                this.router.navigate(['/'], { queryParams: { th: 'tag_vestaparsite' } });
            }

            return;
        })
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    openConsultaCad() {
        this.filialService.getConsultaCadPage();
    }

    openConsutaCadCet() {
        this.filialService.getConsultaCadCetPage();
    }
}