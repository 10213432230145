import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ParamCliente } from 'src/app/model/DTO/paramCliente';
import { map, catchError } from 'rxjs/operators';
import { Telefones } from 'src/app/model/telefone';
import { Veiculo } from 'src/app/model/veiculo';
import { ParamClientePJUpdate } from 'src/app/model/DTO/paramClientePJ';
import { ParamMovimentacaoFrotistaAdmin } from 'src/app/model/DTO/paramMovimentacaoFrotistaAdmin';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import * as uuid from 'uuid';

@Injectable()
export class ClienteService extends BaseService {
    constructor(private http: HttpClient, spinner: NgxSpinnerService) { 
        super();
        this.spinner = spinner; 
    }

    centroCustoAdminFrotista: string;

    getComercioEspecial() : boolean {
        return super.getComercioEspecial();
    }

    isFrotista() : boolean {
        return super.isFrotista();
    }

    isFrotistaAdmin() : boolean {
        return super.isFrotistaAdmin();
    }

    setIsFrotista(flag: boolean): void {
        super.setIsFrotista(flag);
    }

    setFingerPrintIdSession(){
        super.setFingerPrintIdSession(uuid.v4());        
    }

    setCentroDeCustoFrotista(idEmpresaFrotistaCentroCusto: number){
        super.setCentroDeCustoFrotista(idEmpresaFrotistaCentroCusto);
    }

    setCnpjEmpresaFrotista(cnpj){
        super.setCnpjEmpresaFrotista(cnpj);
    }

    getCnpjEmpresaFrotista(){
        return super.getCnpjEmpresaFrotista();
    }

    getCentroDeCustoFrotista(): number {
        return super.getCentroDeCustoFrotista();
    }

    setPerfilUsuario(tipo: string): void {
        super.setPerfilUsuario(tipo);
    }    

    getPerfilUsuario(): string { 
        return super.getPerfilUsuario();
    }

    selectFrotista(): boolean {
        return super.selectFrotista();
    }

    setSelectFrotista(flag: boolean): void {
        super.setSelectFrotista(flag);
    }

    funcionalidadePermitida(tipoFuncionalidade: Funcionalidades) { 
        return super.funcionalidadePermitida(tipoFuncionalidade); 
    }

    obterCEP(cep: string): Observable<any[]> {
        return this.http
             .get<any>(environment.UrlViaCep.replace("{cep}", cep));            
    }

    postClientePF(param: ParamCliente, tokenRecaptcha : string): Observable<any> {
        return this.http
            .post(this.UrlClienteFull + "clientes/v1/pf", JSON.stringify(param), {
                headers: new HttpHeaders({'Content-Type': 'application/json', 'Assertiva-Key': this.AssertivaKey, 'Applicationid': environment.BrokerApplicationIdPortalCliente,'recaptchaToken': tokenRecaptcha })
            }).pipe(
                //timeout(15000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    postAdicionarUsuarioFrotista(param: any): Observable<any> {
        return this.http
            .post(`${this.BaseUrlFunction}v1/frotista/usuario/salvar?code=${this.FuncCodeAuthAddUsuarioFrotista}`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            )
    }

    postRemoverUsuarioFrotista(param: any): Observable<any> {
        return this.http
            .post(`${this.BaseUrlFunction}v1/frotista/usuario/remover?code=${this.FuncCodeAuthRemoveUsuarioFrotista}`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            )
    }

    putClientePF(documento: string, param: ParamCliente): Observable<any> {
        return this.http
            .put(this.UrlClienteFull + "clientes/v1/pf/" + documento, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'Assertiva-Key': this.AssertivaKey, 'Authorization': this.obterTokenUsuario()})
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    postClientePJ(param: ParamCliente, tokenRecaptcha: string): Observable<any> {
        return this.http
            .post(this.UrlClienteFull + "clientes/v1/pj", JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Assertiva-Key': this.AssertivaKey, 'Applicationid': environment.BrokerApplicationIdPortalCliente,'RecaptchaToken': tokenRecaptcha  })
            }).pipe(
                //timeout(15000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    putClientePJ(documento: string, param: ParamClientePJUpdate): Observable<any> {
        return this.http
            .put(this.UrlClienteFull + "clientes/v1/pj/" + documento, JSON.stringify(param), {
                headers: new HttpHeaders({ 
                                    'Content-Type': 'application/json', 
                                    'Applicationid': environment.BrokerApplicationIdPortalCliente, 
                                    'Assertiva-Key': this.AssertivaKey, 
                                    'Authorization': this.obterTokenUsuario()
                        })
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    getClientePF(documento: string): Observable<any>{
        return this.http.get(this.UrlClienteFull + "clientes/v1/pf/" + documento + "?completo=true", {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        );
    }

    getClientePJ(documento: string){
        return this.http.get(this.UrlClienteFull + "clientes/v1/pj/" + documento + "?completo=true", {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        );
    }

    getUsuariosFrotistaAdmin(documento: string, listarPorCentroCustoAdmin = false){
        return this.http.get(`${this.BaseUrlFunction}app/usuariosfrotistaadmin/v1/${documento}?listarPorCentroCustoAdmin=${listarPorCentroCustoAdmin}&code=${environment.FuncCodeAuthUsuariosFrotAdmin}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario()})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    getExtratoFrotistaAdmin(param: ParamMovimentacaoFrotistaAdmin){
        return this.http.post(this.BaseUrlFunction + "app/frotistaadmin/v1/?code=PPxq9qYGSjWe1ICySYWIQiGUzPCm/xHtaEt7UU6DDh3gz1chjopXxQ==", JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario()})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    getSaldos(documento: string, tipoUsuario: string) : Observable<any>{

        const tokenGetSaldo = this.isFrotistaAdminLogado() ? 
                              this.obterTokenAdminFrotista() :
                              this.obterTokenUsuario();

        return this.http.get(`${this.UrlServiceNewToken}${documento}/saldo?tipoUsuario=${tipoUsuario}&token=${tokenGetSaldo}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': tokenGetSaldo})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    postTelefones(documento: string, param: Telefones){
        return this.http
            .post(`${this.UrlClienteFull}clientes/v1/${documento}/telefone?isResponsavel=false`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            }).pipe(
                //timeout(15000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    deleteTelefone(documento: string, id: number) {
        return this.http
        .delete(`${this.UrlClienteFull}clientes/v1/${documento}/telefone?id=${id}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
        })
        .pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    getVeiculos(documento: string, tipoUsuario: string): Observable<any> {
        
        const tokenGetVeiculos = this.isFrotistaAdminLogado() ? 
                                 this.obterTokenAdminFrotista() :
                                 this.obterTokenUsuario();

        return this.http
            .get<any>(`${this.UrlBaseBroker}/clientes/v1/${documento}/veiculos?tipoUsuario=${tipoUsuario}&pagina=1&quantidadeRegistros=100000`, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': tokenGetVeiculos, 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(
                res => {
                    //this.spinner.hide();
                    return res;
                } 
            )
    }

    postVeiculo(documento: string, param: Veiculo): Observable<any> {
                
        return this.http
            .post(`${this.UrlBaseBroker}/clientes/v1/${documento}/veiculos`, JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario() })
            }).pipe(
                //timeout(15000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    deleteVeiculo(documento: string, placa: string, tipo: number, tipoUsuario: string): Observable<any> {
        return this.http
        .delete(`${this.UrlBaseBroker}/clientes/v1/${documento}/veiculos?placa=${placa}&tipo=${tipo}&tipoUsuario=${tipoUsuario}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': this.obterTokenUsuario() })
        })
        .pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    favoritarVeiculo(veiculo: Veiculo){
        
        const tokenFavoritar = this.isFrotistaAdminLogado() ? 
                               this.obterTokenAdminFrotista() :
                               this.obterTokenUsuario();

        const documentoFavoritar = this.isFrotistaAdminLogado() ?
                                    this.obterDocumentoOriginalAdminFrotista() : 
                                    this.obterUsuario().documento;
     
        return this.http
            .put(`${this.UrlBaseBroker}/clientes/v1/${documentoFavoritar}/veiculos/favoritar`, JSON.stringify(veiculo), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'Assertiva-Key': this.AssertivaKey, 'Authorization': tokenFavoritar})
            }).pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    postAlterarSenha(documento: string, email:string, senhaAtual: string,  senhaNova: string, token: string): Observable<any> {
        return this.http
            .post(`${this.BaseUrlFunction}v2/usuario/alterarsenha?code=${environment.FuncCodeTrocaSenha}`, JSON.stringify(
                {Email: email, Documento: documento, SenhaAtual:senhaAtual, SenhaNova:senhaNova, Token: token}
                ), {
                headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})
            }).pipe(                
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    solicitarTokenTrocaDeSenha(email: string): Observable<any> {
        return this.http
            .post(`${this.BaseUrlFunction}v1/usuario/recuperarsenha?code=${environment.FuncCodeSolicitaTrocaSenha}`,
                    JSON.stringify({EmailRecuperacao: email}), 
                    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})
            }).pipe(                
                map(super.extractData),
                catchError(super.serviceError)
            );
    }  

    postCadastroLeadFrotista(model: any){
        return this.http
        .post(this.BaseUrlFunction + `v1/frotista?code=${environment.FuncCodeAuthLeadFrotista}`, JSON.stringify(model), {
            headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})
        }).pipe(                
            map(super.extractData),
            catchError(super.serviceError)
        );
    }

    getTelefones(documento: string): Observable<any> {
        return this.http
            .get<any>(`${this.UrlBaseBroker}/clientes/v1/${documento}/telefone?isResponsavel=false`, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.obterTokenUsuario(), 'Applicationid': environment.BrokerApplicationIdPortalCliente })
            })
            .pipe(
                res => {
                    return res;
                } 
            )
    }

    enviarConfirmacaoEmail(email: string){        
        return this.http.get(`${this.UrlBaseBroker}/clientes/v1/validar/email?value=${email}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente })
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        );       
    }

    confirmarCodigoEmail(email: string, codigo: string){
        return this.http
        .post(`${this.UrlBaseBroker}/clientes/v1/validar/email`, 
               JSON.stringify({ value: email, codigo: codigo }), {
               headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8', 'Applicationid': environment.BrokerApplicationIdPortalCliente}),
               observe: 'response'
        }).pipe(                
            map(super.extractData),
            catchError(super.serviceError)            
        );
    }

    enviarConfirmacaoSms(numCel: string, language: string){        
        return this.http.get(`${this.UrlBaseBroker}/clientes/v1/validar/telefone?value=${numCel}&idioma=${language}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        );       
    }

    confirmarCodigoSms(numCel: string, codigo: string){
        return this.http
        .post(`${this.UrlBaseBroker}/clientes/v1/validar/telefone`, 
               JSON.stringify({ value: numCel, codigo: codigo }), {
               headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8', 'Applicationid': environment.BrokerApplicationIdPortalCliente}),
               observe: 'response'
        }).pipe(                
            map(super.extractData),
            catchError(super.serviceError)            
        );
    }

    movimentacaoSaldoEvent: EventEmitter<void> = new EventEmitter();
    atualizacaoCadastroPlacaEvent: EventEmitter<void> = new EventEmitter();

    atualizarSaldo = () => this.movimentacaoSaldoEvent.emit();
    atualizarPlacas = () => this.atualizacaoCadastroPlacaEvent.emit();
}