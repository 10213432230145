import { Component } from '@angular/core';
import { ClienteService } from '../../service/cliente.service';
import { Cliente } from 'src/app/model/cliente';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CadastroCompletoClientePFComponent } from '../cadastroCompletoCliente/cadastro-completo-cliente-pf.component';

import { NgxSpinnerService } from 'ngx-spinner';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-dados-basico-cliente',
    templateUrl: './dados-basico-cliente.component.html'
  })

  export class DadosBasicoClienteComponent {
    cliente: Cliente;
    funcionalidades = Funcionalidades; 
    
    constructor(
      private spinner: NgxSpinnerService,
      private ngxLoader: NgxUiLoaderService, 
      private modalService: NgbModal,
      private clienteService: ClienteService)
      {
        this.obterCliente(clienteService.obterUsuario().documento);
      }

    open() {
      
      const modalRef = this.modalService.open(CadastroCompletoClientePFComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.dadosAtualizados.subscribe((res: Cliente) => {
        this.cliente = res;
      });
    }

    obterCliente(documento: string){
      if(documento.length == 11 || documento.length == 8){
        this.clienteService.getClientePF(documento)
            .subscribe(
              result => {
                this.cliente = result.cliente;
              },
              () => {
              
              }
          );
      } else if(documento.length == 14) {
        this.clienteService.getClientePJ(documento)
          .subscribe(
            result => {
              this.cliente = result.cliente;
            },
            () => {
           
            }
          );
      }
    }

    verFuncionalidade(funcionalidade: Funcionalidades) : boolean {
      return this.clienteService.funcionalidadePermitida(funcionalidade);
    }
    
    get ehComercioEspecial() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.ComercioEspecial);
    }
  }