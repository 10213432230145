import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { environment } from 'src/environments/environment';

export abstract class BaseService {
    protected UrlInicioService: string = environment.UrlInicioService;
    protected UrlClienteFull: string = environment.UrlClienteFull;
    protected UrlBase: string = environment.UrlBase;
    protected UrlServiceNewToken: string = environment.UrlServiceNewToken;
    protected UrlCDN: string = environment.UrlCDN;
    protected BaseURL: string = environment.BaseURL;
    protected UrlCredito: string = environment.UrlCredito;
    protected BaseUrlFunction: string = environment.BaseUrlFunction;
    protected BaseProd: string = environment.baseProd;
    protected timeout: number = environment.timeout;
    protected UrlBaseBroker = environment.UrlBaseBroker;
    protected UrlCdn: string = environment.UrlCdn;
    protected FilialId: number = environment.FilialId;
    protected FunctionCodeAuthorizationGeoJson: string = environment.FunctionCodeAuthorizationGeoJson;
    protected FuncCodeAuthAddUsuarioFrotista: string = environment.FuncCodeAuthAddUsuarioFrotista;
    protected FuncCodeAuthRemoveUsuarioFrotista: string = environment.FuncCodeAuthRemoveUsuarioFrotista;
    protected FuncCodeAuthBoleto: string = environment.FuncCodeAuthBoleto;
    protected AssertivaKey = environment.AssertivaKey;
    protected spinner: NgxSpinnerService;
    protected BaseRecaptcha: string = environment.BaseRecaptcha;

    protected ObterHeaderFormData() {
        return {
            headers: new HttpHeaders({
                'Content-Disposition': 'form-data; name="produto"',
                'Authorization': `Bearer ${this.obterTokenUsuario()}`
            })
        };
    }

    protected ObterHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected ObterAuthHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.obterTokenUsuario()}`
            })
        };
    }

    protected extractData(response: any) {
        return response || {};
    }

    protected extractDataCartao(response: any) {

        return response.dadosCartoes || {};
    }

    public obterUsuario() {
        return JSON.parse(localStorage.getItem('app.user'));
    }

    public obterDocumentoOriginalAdminFrotista() {
        return localStorage.getItem('app.documentoAdminFrotista');
    }

    public isFrotistaAdminLogado() {
        return this.obterUsuario().adminFrotista
            && this.getPerfilUsuario() === 'frotista';
    }

    protected obterTokenUsuario(): string {
        return localStorage.getItem('app.token');
    }

    public obterTokenAdminFrotista(): string {
        return localStorage.getItem('app.tokenAdminFrotista');
    }

    protected isFrotista(): boolean {
        return localStorage.getItem('app.frotista') === "true";
    }

    protected setIsFrotista(flag: boolean): void {
        this.removeIsFrotista();
        localStorage.setItem('app.frotista', flag.toString());
    }

    protected setFingerPrintIdSession(uuid: string) {
        localStorage.setItem('app.fingerPrint', uuid);
    }

    public getFingerPrintIdSession() {
        return localStorage.getItem('app.fingerPrint');
    }

    protected setCentroDeCustoFrotista(idEmpresaFrotistaCentroCusto: number) {
        localStorage.setItem('app.IDEmpresaFrotistaCentroCusto', idEmpresaFrotistaCentroCusto?.toString());
    }

    protected setCnpjEmpresaFrotista(cnpj) {
        localStorage.setItem('app.cnpjEmpresaFrotista', cnpj);
    }

    public getCentroDeCustoFrotista(): number {
        return parseInt(localStorage.getItem('app.IDEmpresaFrotistaCentroCusto'));
    }

    protected getCnpjEmpresaFrotista() {
        return localStorage.getItem('app.cnpjEmpresaFrotista');
    }

    protected setPerfilUsuario(tipo: string): void {
        localStorage.setItem('app.perfilUsuario', tipo);
    }

    protected getPerfilUsuario(): string {
        return localStorage.getItem('app.perfilUsuario');
    }

    protected getComercioEspecial(): boolean {
        return localStorage.getItem('app.comercioEspecial') === "true";
    }

    protected setSelectFrotista(flag: boolean): void {
        localStorage.setItem('app.selectFrotista', flag.toString());
    }

    protected removeSelectFrotista(): void {
        localStorage.removeItem('app.selectFrotista');
    }

    protected removeIdEmpresaFrotistaCentroCusto(): void {
        localStorage.removeItem('app.IDEmpresaFrotistaCentroCusto');
    }

    protected selectFrotista(): boolean {
        return localStorage.getItem('app.selectFrotista') === "true";
    }

    protected removeIsFrotista(): void {
        localStorage.removeItem('app.frotista');
    }

    protected isFrotistaAdmin(): boolean {
        return localStorage.getItem('app.adminFrotista') === "true";
    }

    protected serviceError(error: HttpErrorResponse | any) {

        let errMsg: string = "";

        if (error.error !== undefined) {
            errMsg = error.error;
        } else if (error instanceof HttpErrorResponse) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        }

        //Swal.fire('Atenção',
        //(errMsg as any).message, 'error' );

        return throwError(errMsg);
    }

    protected closeLoading() {
        //this.spinner.hide();
    }

    protected funcionalidadePermitida(tipoFuncionalidade: Funcionalidades): boolean {

        const usuario = this.obterUsuario();
        const perfilUtilizado = this.getPerfilUsuario();

        switch (tipoFuncionalidade) {
            case Funcionalidades.VerCadastro:
            case Funcionalidades.EditarCadastro:
            case Funcionalidades.ListarTelefones:
            case Funcionalidades.IncluirTelefone:
                return (usuario.adminFrotista && perfilUtilizado === 'frotista')
                    || (!usuario.frotista || perfilUtilizado === 'pessoal')
            case Funcionalidades.IncluirVeiculo:
                return (usuario.adminFrotista && perfilUtilizado === 'frotista')
                    || (!usuario.frotista || perfilUtilizado === 'pessoal')
            case Funcionalidades.ExcluirVeiculo:
                return (usuario.adminFrotista && perfilUtilizado === 'frotista')
                    || (!usuario.frotista || perfilUtilizado === 'pessoal');
            case Funcionalidades.ExibirCentroDeCusto:
                return usuario.frotista && perfilUtilizado === 'frotista';
            case Funcionalidades.VisualizarSaldoCad:
                return !usuario.frotista
                    || perfilUtilizado === 'pessoal';
            case Funcionalidades.ListarCartoes:
            case Funcionalidades.IncluirCartoes:
                return (usuario.adminFrotista && perfilUtilizado === 'frotista')
                    || (!usuario.frotista || perfilUtilizado === 'pessoal')
            case Funcionalidades.ComprarCredito:
                return (usuario.adminFrotista && perfilUtilizado === 'frotista')
                    || (!usuario.frotista || perfilUtilizado === 'pessoal')
            case Funcionalidades.UsuariosCorporativos:
                return usuario.adminFrotista && perfilUtilizado === 'frotista';
            case Funcionalidades.InformarValorCompraCredito:
                return usuario.adminFrotista && perfilUtilizado === 'frotista';
            case Funcionalidades.ComercioEspecial:
                return this.getComercioEspecial();
            default: return false;
        }
    }

    public getHashStorageDevice(documento) {
        try {
            let hashes = JSON.parse(localStorage.getItem('app.hashStorageDevice'));

            let line = hashes.filter(a => a.indexOf(documento) > -1)[0];

            return line.split('|')[1];
        } catch {
            return localStorage.getItem('app.hashStorageDevice');
        }
    }

    public setHashStorageDevice(documento: string, hashStorageDevice: string) {
        try {
            let hashes = JSON.parse(localStorage.getItem('app.hashStorageDevice'));

            let indice = hashes.findIndex(item => item.startsWith(documento));

            hashes.splice(indice, 1);

            hashes.push(documento + "|" + hashStorageDevice);

            localStorage.setItem('app.hashStorageDevice', JSON.stringify(hashes));
        } catch {
            let hashes = [documento + "|" + hashStorageDevice];
            localStorage.setItem('app.hashStorageDevice', JSON.stringify(hashes));
        }
    }

    public getDeviceUUID() {
        return localStorage.getItem('app.deviceUUID');
    }

    public setDeviceUUID(uuid: string) {
        return localStorage.setItem('app.deviceUUID', uuid);
    }
}