<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'InfoRulesZadSp' | translate }}</h4>
</div>
<div class="modal-body" style="background-color: #24272a;color: #fff;">
    <div class="container">
        <div *ngFor="let regra of jsonRegras" style="margin-bottom: 18px;">
            <span style="font-weight: bold;font-size: 1.4em;">{{regra.regra}}</span>
            {{regra.descritivo}}            
        </div>
        <!-- <div [innerHTML]="htmlHelper"></div> -->
    </div>
</div>
<div class="modal-footer mt-4">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>   
</div>