<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'Add Phone' | translate }}</h4>
</div>
<form [formGroup]="telefoneForm">
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <label>{{'Type' | translate}}</label>
                    <select required class="form-control" formControlName="tipo">
                        <option></option>
                        <option value="0">{{'TypePhoneCell' | translate}}</option>
                        <option value="1">{{'TypePhoneResidential' | translate}}</option>
                        <option value="2">{{'TypePhoneCommercial' | translate}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <label>DDD</label>
                    <input required type="tel" class="form-control" placeholder="" formControlName="ddd" [textMask]="{mask: maskDDD}" />
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <label>{{'TelephoneNumber' | translate}}</label>
                    <input [maxlength]="telefoneForm.get('tipo').value === 'Fixo' ? 9 : 11"
                     required type="tel" class="form-control" placeholder="" formControlName="numero" mask="0000-0000||00000-0000" />
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <label>{{'MainPhone' | translate}}?</label>
                    <input type="checkbox" class="form-control form-control-sm" formControlName="isPrincipal" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{'Cancel' | translate}}</button>
        <button type="submit" class="btn btn-outline-success" (click)="salvar()">{{'Save' | translate}}</button>
    </div>
</form>