
    <div class="">
        <div class="content">
            <app-header></app-header>
            <div class="background opac" style="background-image:url('assets/images/background/bg-sao.jpg')">
            </div>

            <div class="container">
                <div class="row login-row-height">
                    <div class="col-12 col-md-6 col-lg-7">
                        <div class="hero-body">
                            <div class="container" [innerHtml]="'Textos.WelcomeLogin' | translate">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5">
                        <form [formGroup]="loginForm" id="frmLogin">
                            <div class="card">
                                <div class="card-body">
                                    <div class="">
                                        <div class="card-body">
                                            
                                    <!-- <h5 class="font-weight-light mb-1 text-mute-high">Acesse sua conta,</h5> -->
                                    <h4 class="font-weight-light mb-1 text-mute-high">{{'Document' | translate}}</h4>
                                    
                                            <div class="mb-4">
                                                <label for="inputDocument" class="sr-only">{{'Document' | translate}} </label>
                                                <input style="background-color: rgba(22,30,67,0.5);border: 2px solid #6FD24B;color: white;" id="inputDocument" class="form-control form-control-lg rounded-pill ng-pristine ng-invalid ng-touched" placeholder="{{ 'DocumentPlaceHolder' | translate }}" matInput mask="000.000.000-00||00.000.000/0000-00||AA.000.000" formControlName="user"/>
                                            </div>
                                            <div class="mb-4">
                                                <label for="inputPassword" class="sr-only">{{ 'Password' | translate}}</label>
                                                <input style="background-color: rgba(22,30,67,0.5);border: 2px solid #6FD24B;color: white;" type="password" id="inputPassword" class="form-control form-control-lg rounded-pill ng-pristine ng-invalid ng-touched" placeholder="{{ 'Password' | translate}}" formControlName="password" />
                                            </div>
                                            <div class="">{{msgError}}</div>
                                            <div class="mb-4">
                                                <button type="submit" (click)="onLoggedin()" 
                                                        class="btn btn-lg btn-login btn-block btn-rounded" style="cursor: pointer;font-weight: 500;">
                                                    <span>
                                                        {{ 'Log in' | translate }}
                                                    </span>
                                                </button>
                                            </div>
                                            <h3 class="font-weight-normal mb-1" style="font-size: 1.0em!important;"><a (click)="open()" style="text-decoration: underline; cursor: pointer!important;">{{ 'NotRegistered' | translate}}</a></h3>
                                            <h3 class="font-weight-normal mb-1" style="font-size: 1.0em!important;"><a (click)="openEsqueceuSenha()" style="text-decoration: underline; cursor: pointer!important;">{{ 'ForgotPassword' | translate}}</a></h3>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="margin-top: 8px;">
                                <div class="card-body">
                                    <div class="text-center">
                                        <span class="center" style="justify-content:center;"> Consulta de autenticação de CAD</span><br /> <br />
                                    </div>                                    
                                    <h3 class="font-weight-normal mb-1" style="font-size: 1.0em!important;"><a (click)="openConsultaCad()" style="text-decoration: underline; cursor: pointer!important;">{{ 'SearchCad' | translate}}</a></h3>
                                    <h3 class="font-weight-normal mb-1" style="font-size: 1.0em!important;"><a (click)="openConsutaCadCet()" style="text-decoration: underline; cursor: pointer!important;">{{ 'SearchCadAnotherApps' | translate}}</a></h3>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <app-footer [loadNeoAssistTag]="false" class="footer position-relative d-block"></app-footer>
        </div>
    </div>
    <app-lgpd></app-lgpd>
