import { Component } from "@angular/core";
import { ClienteService } from '../../service/cliente.service';
import { ParamMovimentacaoFrotistaAdmin } from 'src/app/model/DTO/paramMovimentacaoFrotistaAdmin';

import {ExtratoFrotistaAdmin} from 'src/app/model/extratoFrotistaAdmin';
import { ConstantPool } from '@angular/compiler';
import { ExtratoDetalheFrotistaAdminComponent } from './extrato-detalhe-frotista-admin.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lista-extrato-frotista-admin',
    templateUrl: './extrato-lista-frotista-admin.component.html'
})

export class ExtratoFrotistaAdminComponent {
    active = 1;
    currentJustify = 'fill';

    private documentoExtrato: string = "";

    param: ParamMovimentacaoFrotistaAdmin = new ParamMovimentacaoFrotistaAdmin();
    retornoExtrato: ExtratoFrotistaAdmin[];
    retornoExtratoCredito: ExtratoFrotistaAdmin[];
    retornoExtratoUtilizacao: ExtratoFrotistaAdmin[];

    tipoTransacao: { id: number, desc: string }[] = [
        { id: 1, desc: "Crédito" },
        { id: 2, desc: "Utilização" },
        { id: 3, desc: "Estorno Recarga" },
        { id: 4, desc: "Estorno Utilização" },
        { id: 6, desc: "Crédito CAD" },
        { id: 7, desc: "Valor consumido da Carteira Virtual" },
        { id: 8, desc: "Bonus" },
        { id: 9, desc: "Utilização Bônus" },
        { id: 10, desc: "Recarga Rotativo Digital (CRD) - BHTRANS MG" },
        { id: 11, desc: "Recarga Motocicleta - BHTRANS MG" },
        { id: 12, desc: "Bonus" },
    ];

    constructor(private spinner: NgxSpinnerService,
        private modalService: NgbModal
        ,config: NgbModalConfig
        ,private clienteService: ClienteService) {
            config.backdrop = 'static';
            config.keyboard = false;

        this.param.Documento = this.clienteService.obterUsuario().documento;
        this.param.FilialID = environment.FilialId.toString();
        this.param.TipoRelatorio = 3;
        this.param.DataInicial = "2020-06-01";
        this.param.DataFinal = "2020-07-30";
        this.param.CentroCusto = "116";
        this.param.DocumentoEmpresa = this.clienteService.obterUsuario().documento;

        clienteService.getExtratoFrotistaAdmin(this.param).subscribe(
            result => {
                if("Data" in result){
                    if("itens" in result.Data){
                        this.retornoExtrato = result.Data.itens;
                        
                        this.retornoExtratoCredito = Array.from(this.retornoExtrato).filter(function(i){
                            return i.tipoCobrancaFilialID === 1;
                        });
                          
                          this.retornoExtratoUtilizacao = Array.from(this.retornoExtrato).filter(function(i){
                            return i.tipoCobrancaFilialID !== 1;
                          });
                    }
                  }
            },
            fail => {
              
            }
        );
    }

    open(param){
        const modalRef = this.modalService.open(ExtratoDetalheFrotistaAdminComponent, { centered: true, size: 'sm' });
        
        modalRef.componentInstance.detalheFrotistaAdmin = param;
    }

    getDescTipoTransacao(id: number): string  {
        if(id !== null){
            return this.tipoTransacao.find(x => x.id === id).desc;
        } else {
            return "";
        }
    }

    hide(param: string){
        if(this.documentoExtrato != param) {
            this.documentoExtrato = param;
            return false;
        } else {
            return true;
        }
      }
}