import { Component } from "@angular/core";
import { ClienteService } from '../../service/cliente.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { VeiculoCadastroComponent } from './veiculo-cadastro.component';
import Swal from 'sweetalert2';
import { Veiculo, VeiculoRetorno } from 'src/app/model/veiculo';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Funcionalidades } from 'src/app/model/funcionalidades';

@Component({
    selector: 'app-veiculo-lista',
    templateUrl: './veiculo-lista.component.html'
})

export class VeiculoListaComponent {
    veiculos: VeiculoRetorno[] = [];
    funcionalidades = Funcionalidades;     

    mask = ['/[A-Z]/', '/[A-Z]/', '/A-Z/', '-', '/[0-9]/', '/[0-9A-Z]/', '/[0-9]/', '/[0-9]/'];

    constructor(
      private spinner: NgxSpinnerService,
      private modalService: NgbModal,
      config: NgbModalConfig,
      private clienteService: ClienteService,
      private translate: TranslateService){

        config.backdrop = 'static';
        config.keyboard = false;

        this.getVeiculos();   
        this.clienteService.atualizacaoCadastroPlacaEvent.subscribe(x => {
          this.getVeiculos();
        });     
    }

    get cadastrarVeiculo() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.IncluirVeiculo);
    }

    get excluirVeiculo() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.ExcluirVeiculo)
    }

    open() {
        const modalRef = this.modalService.open(VeiculoCadastroComponent, { centered: true, size: 'md' });

        modalRef.componentInstance.veiculoCadastrado
          .subscribe(
            result => {
             
              this.getVeiculos();
              // this.veiculos.push({
              //     ano: 0,
              //     cor: "",
              //     id: 0,
              //     marca: "",
              //     modelo: "",
              //     placa: result.veiculo.placa,
              //     tipo: result.veiculo.tipo,
              //     favorito: result.veiculo.favorito
              //   })

                Swal.fire({
                  text: this.translate.instant('VehicleRegistered'),
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'success',                 
                  confirmButtonColor: '#4a9f42'
                });
            },
            fail => {
            }
          );
      }

      deletar(placa, tipoVeiculo){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            //confirmButton: 'btn btn-success',
            //cancelButton: 'btn btn-danger'
          },
          //buttonsStyling: false
        })
  
        swalWithBootstrapButtons.fire({
          title: this.translate.instant('AlertInfo'),
          text: this.translate.instant('DeleteVehicle'),
          icon: 'question',
          confirmButtonColor: '#4a9f42',
          confirmButtonText: 'OK',
          cancelButtonText: this.translate.instant('Cancel'),
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            //this.spinner.show();
            this.clienteService
                  .deleteVeiculo(this.clienteService.obterUsuario().documento, 
                                 placa, 
                                 tipoVeiculo, "comum")
          .subscribe(
            result => {
              //this.spinner.hide();
              const index: number = this.veiculos.findIndex(x => x.placa === placa);
              if (index !== -1) {
                this.veiculos.splice(index, 1);
              }
              Swal.fire({
                text: result.message,
                titleText:  this.translate.instant('AlertInfo'),
                icon: 'success',                 
                confirmButtonColor: '#4a9f42'
              });
            },
            fail => {
              //this.spinner.hide();
              Swal.fire({
                text: fail?.message,
                titleText:  this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });
            }
          );
            
          }
        })      
      }   

      getIcon(tipo: number) : string {
        switch(tipo){
          case 1: return 'local_shipping';
          case 8: return 'drive_eta';
          case 13: return 'directions_bus';
        }
      }

      getPlacaFormatada(placa: string) : string {
        return `${placa.substring(0,3)}-${placa.substring(3, 7)}`;
      }

      favoritarToogle(placa: string, favorito: boolean){

        let veic = new Veiculo();
        veic.veiculo = {
            placa: placa.toUpperCase(),
            tipo: null,
            favorito: !favorito                
        };
        
        veic.usuario = {
            frotista:   this.clienteService.obterUsuario().frotista
                        && this.clienteService.getPerfilUsuario() === 'frotista'
        }

        this.clienteService.favoritarVeiculo(veic)
                                 .subscribe(
                                  result => {  
                                    
                                    this.getVeiculos();
                                    
                                    Swal.fire({
                                      text: result.message,
                                      titleText:  this.translate.instant('AlertInfo'),
                                      icon: 'success',                 
                                      confirmButtonColor: '#4a9f42'
                                    });
                                  },
                                  fail => {
                                    //this.spinner.hide();
                                    Swal.fire({
                                      text: fail?.message,
                                      titleText:  this.translate.instant('AlertInfo'),
                                      icon: 'info',                 
                                      confirmButtonColor: '#4a9f42'
                                    });
                                  }
                                );       
      }

      getVeiculos(){
        const usuario = this.clienteService.obterUsuario();
        
        this.clienteService.getVeiculos(
              this.clienteService.isFrotistaAdminLogado() ? 
              this.clienteService.obterDocumentoOriginalAdminFrotista():
              usuario.documento, 
                    usuario.frotista
                    && this.clienteService.getPerfilUsuario() === 'frotista' ? 
                    "frotista" : 
                    "comum" )
        .subscribe(
          result => {
            this.veiculos = result.veiculos.map(x => new VeiculoRetorno(x.ano, x.cor, x.id, x.marca, x.modelo, x.placa, x.tipo, x.favorito));
          },
          fail => {
          
          }
        );
      }
}