import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-boleto-print',
    templateUrl: './boleto-print.component.html'
})

export class BoletoPrintComponent implements OnInit {

    @Input()
    htmlStr: string;

    @Input()
    linhaDigitavelBoleto: string;

    constructor(public activeModal: NgbActiveModal){
        
    }

    ngOnInit() {
        this.htmlStr = this.htmlStr.replace("Boleto.Net", "");
    }
}