<div class="modal-body" style="padding-top: 0px; padding-bottom: 0px;">
    <div class="container" style="padding: 0px;">
        <div class="row" style="padding: 0px;">
            <div class="col-12" style="padding: 0px;">
                <div class="border-0 shadow-sm overflow-hidden h-100">
                    <div class="card-body scroll-y h-150 p-0">
                        <div class="list-group roudned-0">
                            <div *ngFor="let cartao of cartoes" class="list-group-item status-border border-warning">
                                <div class="row" (click)="selecionar(cartao)">
                                    <div class="col-auto align-self-center">
                                        <i class="material-icons">credit_card</i>
                                    </div>
                                    <div class="col pl-0" style="margin: auto !important;">
                                        <p style="margin: 0px !important;">{{cartao.bandeira}}</p>
                                    </div>
                                    <div class="col pl-0" style="margin: auto !important;">
                                        <p style="margin: 0px !important; text-align: right;">{{cartao.ultimosDigitos}}</p>
                                    </div>
                                    <div class="col-auto pr-0 align-self-center">
                                        <button class="btn btn-sm text-danger" (click)="selecionar(cartao)"><i class="material-icons">add</i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>