import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FiliaisService } from '../../service/filiais.service';
import { HelperService } from '../../service/helper.service';

@Component({
    selector: 'app-regra-zad-informativo',
    templateUrl: './regra-zad-informativo.component.html'
  })

  export class RegraZadInformativoComponent {
     // public htmlHelper: string;
      public jsonRegras: any;

      constructor(config: NgbModalConfig
                  ,public activeModal: NgbActiveModal
                  ,private helperService: HelperService
                  ,private filialService: FiliaisService ){

              this.filialService.getRegrasZADSP().subscribe(res => {
                 this.jsonRegras = res;
              });

              //this.htmlHelper = this.helperService.getRegraTextHelp('pt');
            }            
  }