import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { AgmCoreModule } from '@agm/core';

import { LanguageTranslationModule } from '../shared/modules/language-translation/language-translation.module';
import { DashboardRoutingModule } from './dashboard-rounting.module';
import { DashboardComponent } from './dashboard.component';
import { CustomModule } from '../shared/modules/custom-module/custom.module';
import { EstadosService } from '../shared/service/estados.service';
import { MunicipiosService } from '../shared/service/municipios.service';
import { SMSService } from '../shared/service/sms.service';
import { CartaoService } from '../shared/service/cartao.service';
import { FiliaisService } from '../shared/service/filiais.service';
import { ExtratoService } from '../shared/service/extrato.service';
import { FAQService } from '../shared/service/faq.service';
import { CreditoService } from '../shared/service/credito.service';
import { BoletoService } from '../shared/service/boleto.service';
import { CadService } from '../shared/service/cad.service';
import { HelperService } from '../shared/service/helper.service';
import { RelatorioFrotistaAdminService } from '../shared/service/relatorio-frotista-admin.service';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule
    ,HttpClientModule
    ,LanguageTranslationModule
    ,RouterModule
    ,DashboardRoutingModule
    ,NgbModule
    ,FormsModule
    ,ReactiveFormsModule
    ,CustomModule   
  ],
  exports: [
  ],
  providers: [
    EstadosService
    ,MunicipiosService
    ,SMSService
    ,CartaoService
    ,FiliaisService
    ,ExtratoService
    ,RelatorioFrotistaAdminService
    ,FAQService
    ,CreditoService
    ,BoletoService
    ,CadService
    ,HelperService
  ],
  entryComponents: [

  ]
})
export class DashboardModule { }
