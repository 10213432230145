import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cartao-lista-opcao',
    templateUrl: './cartao-lista-opcao.component.html'
})

export class CartaoListaOpcaoComponent {
    @Input()
    cartoes: [];

    @Output()
    selectCartao: EventEmitter<any> = new EventEmitter();
    
    constructor(public activeModal: NgbActiveModal){
    }
    
    selecionar(paramCartao){
        this.activeModal.close();
        this.selectCartao.emit(paramCartao);        
    }
}