import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-relatorio-movimento-detalhe',
    templateUrl: './relatorio-movimentacoes-detalhe.component.html'
})

export class RelatorioMovimentacoesDetalheComponent implements OnInit {
    
    public movimento: any;
        
    constructor(public activeModal: NgbActiveModal,
                private translationService: TranslateService
                ){
            
    }
 
    setarDados(movimentoDetalhe: any){ 
        this.movimento = movimentoDetalhe;       
    }  
    
    calcularTempoCad(minutos: number){

        if(minutos === null || minutos === undefined || minutos == 0)
        return '';

        return ( (minutos / 60) < 1 ) ? `${minutos} ${this.translationService.instant('MinuteCad')}` : `${minutos / 60} ${this.translationService.instant('HourCad')}`;
    }

    ngOnInit() {
    }
}