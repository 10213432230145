<div class="modal-header">
    <h4 class="modal-title">{{'RegistrationDataLead' | translate}}</h4>
  </div>
  <div class="modal-body">
      <form novalidate [formGroup]="cadastroLeadFrotista">
          <div class="container form-group">
            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <label for="NomeResponsavelEmpresa">{{ 'ResponsibleName' | translate}}: *</label>
                    <input required class="form-control" id="NomeResponsavelEmpresa" placeholder="{{'ResponsibleName' | translate}}" formControlName="NomeResponsavelEmpresa" 
                        style="text-transform: uppercase"/>                                                         
                </div>
                <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <label for="Telefone1">{{ 'MobilePhoneNumber' | translate}}: *</label>                    
                    <input [maxlength]="14" required type="tel" placeholder="{{'MobilePhoneNumber' | translate}}" class="form-control" formControlName="Telefone1" mask="(00)00000-0000" />                                                         
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <label for="HorarioContato">{{ 'TimeToCall' | translate }}</label>
                    <input type="time" class="form-control" formControlName="HorarioContato" placeholder="dd/MM/aaaa HH:mm" maxlength="5" id="HorarioContato" name="HorarioContato" >
                </div>
                <div class="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <label for="Email">{{'Email' | translate}}: *</label>
                    <input required class="form-control" id="Email" placeholder="{{'Email' | translate}}" formControlName="Email" />
                    
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="qtdVeiculos">{{ 'QuantityVehicles' | translate }}</label>
                    <select required class="form-control" id="QtdeVeiculos" formControlName="QtdeVeiculos" style="font-weight: bold;" >
                        <option *ngFor="let tipoQuantidadeFrota of tiposQuantidadeFrotas" value="{{tipoQuantidadeFrota.id}}">{{tipoQuantidadeFrota.desc}}</option>
                    </select>                    
                </div>
            </div>           
          </div>
      </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancelarCadastroLead()">{{ 'Cancel' | translate }}</button>
    <button type="submit" class="btn btn-outline-success" (click)="salvar()">{{ 'Save' | translate}}</button>
  </div>