import { Component } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CadastroFrotistaComponent } from './cadastroFrotista.component';

@Component({
    selector: 'app-usuario-frotista',
    templateUrl: './usuarioFrotista.component.html'
})

export class UsuarioFrotistaComponent {

    constructor(private modalService: NgbModal
        ,config: NgbModalConfig){
            config.backdrop = 'static';
            config.keyboard = false;
    }

    openCadastroUsuarioCorporativo(){
        const modalRef = this.modalService.open(CadastroFrotistaComponent, { centered: true, scrollable: true, size: 'xl' });
    }
}