export class CompraCADCarteira {
    compra: {
        dispositivo: {
            fingerprint: string,
            uuid: string,
            lat: string,
            lng: string,
            network: {
                ip: string,
                subnet: string,
                type: string
            }
        },
        valor: number,
        quantidade: number,
        utilizacao: {
            veiculo: {
                tipo: number
            },
            filial: {
                id: number
            }
        }
    }
}