<div class="modal-header">
    <h4 class="modal-title">{{ 'Privacy Policy' | translate}}</h4>
</div>
<div class="modal-body" style="font-size: 12px;">
    <div class="container">
        <div [innerHTML]="'Textos.Privacy Policy' | translate"></div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
    <!--!saveUsername &&-->
</div>