<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'Add Vehicle' | translate }}</h4>
</div>
<form [formGroup]="veiculoForm">
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for="placa">{{'LicensePlate' | translate}}</label>
                    <input minlength="8" required class="form-control" id="placa" formControlName="placa" placeholder="" [textMask]="{mask: maskPlaca}" style="text-transform: uppercase" />
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label for="tipoVeiculo">{{'TypeVehicle' | translate }}</label>
                    <select required class="form-control" id="tipoVeiculo" formControlName="tipoVeiculo">
                        <option></option>
                        <option *ngFor="let tipoVeiculo of tipoVeiculos" value="{{tipoVeiculo.id}}">{{tipoVeiculo.desc}}</option>
                    </select>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <label for="favorito">&nbsp;</label>
                    <button class="btn btn-sm" (click)="cadastrarComoFavoritoToogle()">
                        <i  [ngClass]=" cadastrarComoFavorito ? 'material-icons' : 'material-icons-outlined'" 
                            [ngStyle]="{'color': cadastrarComoFavorito ? 'rgb(219, 219, 21)' : 'rgb(151, 151, 140)', 'font-size' : '36px' }">
                            grade
                        </i>
                        </button>
                </div>
            </div>          
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{'Cancel' | translate}}</button>
        <button type="submit" class="btn btn-outline-success" (click)="salvar()">{{'Save' | translate}}</button>
    </div>
</form>