<div class="modal-header">
    
    <span style="color: #fff;font-size: 1.em;">
        {{ 'RecoverPassword' | translate }} 
    </span> 

</div>
<div class="modal-body">
    <div class="content">
        <form novalidate [formGroup]="cadastroNovaSenha">
            <input [hidden]="true" autocomplete="off" type="text" class="form-control" formControlName="documento" />    
        <div class="row" [hidden]="areaLogada">
            <div class="col-12">
                <label>{{'CodeVerification' | translate}}</label>
                <input [required]="!areaLogada" autocomplete="off" type="text" class="form-control" formControlName="token" />
            </div>
        </div>
        <div class="row">
            <div class="col-12" [hidden]="!areaLogada" >
                <label>{{'CurrentPassword' | translate }}</label>
                <input [required]="areaLogada" type="password" class="form-control" formControlName="senhaAtual" maxlength="15">
            </div>
            <div class="col-12">
                <label>{{'NewPassword' | translate}}</label>
                <input required type="password" class="form-control" formControlName="novaSenha" maxlength="15">
            </div>
            <div class="col-12">
                <label>{{ 'ConfirmNewPassword' | translate}}</label>
                <input required type="password" class="form-control" formControlName="confirmaNovaSenha" maxlength="15">
            </div>
        </div>
    </form>
    </div>
</div>
<div class="modal-footer mt-4" style="padding: 0px;">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate}}</button>
    <button type="submit" class="btn btn-outline-success" (click)="salvarSenha()">{{ 'Save' | translate}}</button>
</div>