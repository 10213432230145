import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private ngxLoader: NgxUiLoaderService,
              private translate: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    this.ngxLoader.start();  
    
    return next.handle(request)
    .pipe(      

      tap(
        null,     
         
       (err: any) => {
        this.ngxLoader.stop();
        if (err instanceof HttpErrorResponse) {

          switch(err.status){
            case 500:
            case 501:
            case 502:
              Swal.fire({
                text: this.translate.instant('FatalError') ,
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });  
              return;
              case 400:
                Swal.fire({
                  text: err.error.message ,
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });      
                return;
              case 404:
                Swal.fire({
                  text: err.error.message ,
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });      
                return;
            case 401:
                localStorage.removeItem('app.comercioEspecial');
                localStorage.removeItem('usuario');
                localStorage.removeItem('isLoggedin');
                localStorage.removeItem('app.token');
                localStorage.removeItem('app.user');
          
                localStorage.removeItem('app.userOLD');
                localStorage.removeItem('app.tokenOLD');
                localStorage.removeItem('app.frotista');
              
                localStorage.removeItem('app.adminFrotista');
                localStorage.removeItem('app.ativo');
                localStorage.removeItem('app.user');
                localStorage.removeItem('app.perfilUsuario');
                localStorage.removeItem('app.selectFrotista');
                localStorage.removeItem('app.IDEmpresaFrotistaCentroCusto'); 
                localStorage.removeItem('app.documentoAdminFrotista');
                localStorage.removeItem('app.tokenAdminFrotista');
                localStorage.removeItem('app.fingerPrint');
                localStorage.removeItem('app.cnpjEmpresaFrotista');
                localStorage.removeItem('app.verDepoisCadastroLeadFrotista');
                
                this.router.navigate(['/login']);
                
                Swal.fire({
                  text: this.translate.instant('SessionExpired'),
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                }).then(() => {
                  window.location.href = window.location.href;
                });                             
              return;
              case 409:               
                Swal.fire({
                  text: this.translate.instant('InvalidConfirmation') ,
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });
                return;     
          }
        }
      }
     ,
     () => this.ngxLoader.stop()
    ));
  }
}