import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { NgbActiveModal, NgbNavChangeEvent, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CartaoListaOpcaoComponent } from '../cartao/cartao-lista-opcao.component';
import { CartaoRetorno } from 'src/app/model/cartao';
import { CartaoService } from '../../service/cartao.service';
import { ClienteService } from '../../service/cliente.service';
import { CreditoService } from '../../service/credito.service';
import { CompraCADCartaoCredito } from 'src/app/model/compraCADCartaoCredito';
import Swal from 'sweetalert2';
import { CompraCADCarteira } from 'src/app/model/compraCADCarteira';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as uuid from 'uuid';
import { FiliaisService } from '../../service/filiais.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-compra-cad',
    templateUrl: './cadastro-cad.component.html',
    providers: [ReCaptchaV3Service]
})

export class CadastroCADComponent implements OnInit {
  
  active;
  disabledCAD = true;
  valorCADSelecionado: any = {};
  valorCADDigitado: number;

  quantidadesCADs: any[]; //[1, 5, 10, 25, 50, 100, 200];

  cartoes: CartaoRetorno[];
  cartao: CartaoRetorno;

  loadAPI: Promise<any>;

  private guidPagamento = uuid.v4();

  public tokenRecaptcha: string;

  @Output()
  compraFinalizada: EventEmitter<any> = new EventEmitter();

    constructor(
      private modalService: NgbModal,
      config: NgbModalConfig,
      public activeModal: NgbActiveModal,
      private translate: TranslateService,
      private cartaoService: CartaoService,
      private clienteService: ClienteService,
      private creditoService: CreditoService,
      private filialService: FiliaisService, 
      private recaptchaV3Service: ReCaptchaV3Service) {

        this.filialService.getOpcoesCompras().subscribe((res: any) => {
          this.quantidadesCADs = res.cads
        });

        config.backdrop = 'static';
        config.keyboard = false;
        this.tokenRecaptcha = '';

        cartaoService.getCartoes(clienteService.obterUsuario().documento).subscribe(
          result => {
            this.cartoes = result;

            this.cartoes.push({
              "DataUltimaAtualizacao": new Date(),
              "bandeira": "Carteira Virtual",
              "portador": "",
              "ultimosDigitos": "",
              "uuidCartaoEstapar": "",
              IsSelecionado: false
            });

            this.cartao = this.cartoes[0];
          },
          fail => {
           
          });
    }
    
  ngOnInit(): void {
    this.loadAPI = new Promise(() => {               
      this.loadScriptGetDevice();
    });
  }

    confirmarCAD(){

      if(this.valorCADSelecionado === null || this.valorCADSelecionado.valor <= 0){
        Swal.fire({
          text:  this.translate.instant('QuantityCadsRequired'),
          titleText: this.translate.instant('AlertInfo'),
          icon: 'info',                 
          confirmButtonColor: '#4a9f42'
        });        
        return;
      }

      //this.spinner.show();
      //this.ngxLoader.start();
      let paramCard = new CompraCADCartaoCredito();
      let paramCardEwallet = new CompraCADCarteira();

      if(this.cartao.uuidCartaoEstapar) {
        //cartão
        paramCard.compra = {
          dispositivo: {
            fingerprint: this.guidPagamento, //"asklhjaklejsketjhioejflskdjg",
            uuid: "iOS 13.3/x86_64#asklhjaklejsketjhioejflskdjg",
            lat: "37.42199800000000000000",
            lng: "-122.08400000000000000000",
            network: {
              ip: "127.0.0.1",
              subnet: "255.255.255.0",
              type: "wifi"
            }
          },
            valor: this.valorCADSelecionado.valor,
            quantidade: this.valorCADSelecionado.exibicao,
            utilizacao: {
              veiculo: {
                tipo: 8
              },
              filial: {
                id: environment.FilialId
              }
            },
            cartao: {
              token: this.cartao.uuidCartaoEstapar,
              nome: "",
              numero: "",
              lembrar: false,
              validade: {
                mes: "",
                ano: ""
              },
              bandeira: "",
              csc: ""
            }
          }

          //this.ngxLoader.start();
          this.recaptchaV3Service.execute('comprarCADcomCartao').subscribe((token: string): void => 
          {
            this.tokenRecaptcha = token; 
          

            this.creditoService.comprarCADcomCartao(this.clienteService.obterUsuario().documento, paramCard, this.tokenRecaptcha)
            .subscribe(
              result => {
                this.activeModal.close();
                //this.spinner.hide();
                //this.ngxLoader.stop();
                Swal.fire({
                  text: result.message,
                  titleText: 'Compra CAD',
                  icon: 'success',                 
                  confirmButtonColor: '#4a9f42'
                })              
                .then((result) => {
                  if(result.value) {                  
                    //window.location.href = window.location.href;
                    this.compraFinalizada.emit();                  
                  }
                });
              },
              fail => {
                //this.spinner.hide();
                //this.ngxLoader.stop();             
                this.activeModal.close();
              }
            );
        });
      } else {
        //e-wallet
        paramCardEwallet.compra = {
          dispositivo: {
            fingerprint:this.guidPagamento, //"asklhjaklejsketjhioejflskdjg",
            uuid: "iOS 13.3/x86_64#asklhjaklejsketjhioejflskdjg",
            lat: "37.42199800000000000000",
            lng: "-122.08400000000000000000",
            network: {
              ip: "127.0.0.1",
              subnet: "255.255.255.0",
              type: "wifi"
            }
          },
          valor: this.valorCADSelecionado.valor,
          quantidade: this.valorCADSelecionado.exibicao,
          utilizacao: {
            veiculo: {
              tipo: 8
            },
            filial: {
              id: environment.FilialId
            }
          }
        }

        //this.ngxLoader.start();
        this.creditoService.comprarCADcomEwallet(this.clienteService.obterUsuario().documento, paramCardEwallet)
          .subscribe(
            result => {
              this.activeModal.close();
              //this.spinner.hide();
              //this.ngxLoader.stop();
              Swal.fire({
                text: result.message,
                titleText: 'Compra CAD',
                icon: 'success',                 
                confirmButtonColor: '#4a9f42'
              })                
            .then((result) => {
              if(result.value) {
               
                //window.location.href = window.location.href;
                this.compraFinalizada.emit();                
              }
            });
            },
            fail => {
              //this.spinner.hide();
              //this.ngxLoader.stop();
              let msgErro: string = "";
              
              if("erro" in fail) {
                if("dicionario" in fail.erro){
                  if(this.translate.currentLang === "pt"){
                    msgErro = fail.erro.dicionario.portugues;
                  } else if(this.translate.currentLang === "en"){
                    msgErro = fail.erro.dicionario.ingles;
                  } else {
                    msgErro = fail.erro.dicionario.portugues;
                  }
                } else if("descricao" in fail.erro) {
                  msgErro = fail.dicionario.descricao;
                } else {
                  msgErro = "Erro ao tentar realizar compra utilizando carteira virtual";
                }                
              } else if("message" in fail){
                msgErro = fail.message;
              } else{
                msgErro = "Erro ao tentar realizar compra utilizando carteira virtual";
              }

              Swal.fire({
                text: msgErro,
                titleText: 'Compra CAD',
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              }).then((result) => {
                if(result.value) {
                  this.activeModal.close();
                }
              });
            }
          );
      }
    }

    openFormaPgto(){
      const modalRef = this.modalService.open(CartaoListaOpcaoComponent, { centered: true, size: 'sm' });

      modalRef.componentInstance.cartoes = this.cartoes;

      modalRef.componentInstance.selectCartao.subscribe(
        result => {
          this.cartao = result;
        },
        fail => {
          
        }
      );
    }

    public loadScriptGetDevice() {
      
      let node = document.createElement('script');
      node.src = environment.UrlGetDevice.replace('{sessao}', this.guidPagamento);
      node.type = 'text/javascript';
      node.async = true;   
      document.getElementsByTagName('body')[0].appendChild(node);
    }   

    onNavChange(changeEvent: NgbNavChangeEvent) {
        if (changeEvent.nextId === 3) {
          changeEvent.preventDefault();
        }
      }
    
      prox(selectTab: number, paramValor: any){
        this.active = selectTab;
        this.valorCADSelecionado = paramValor;
      }
    
      toggleDisabled() {
        this.disabledCAD = !this.disabledCAD;
        if (this.disabledCAD) {
          this.active = 1;
        }
      }
    
      disable(step: number) {
        return step >= this.active;
      }

      cancelar(){
        this.activeModal.close();
        //window.location.href = window.location.href;
      }
    
}