import { VeiculoCad } from './veiculo';

export class UtilizacaoBase {

    public dispositivo: Dispositivo;
    public quantidade: number;
    public valor: number;
    public tempo: number;
    public veiculo: VeiculoCad;
    public filial: Filial;
    public ignoraMaximoCADs: boolean;
}

export class CompraUtilizacaoBase {
    dispositivo: Dispositivo;
    quantidade: number;
    valor: number;    
    constructor(lat: string,
                lng: string,
                qtd: number,                
                valor: number) {
                    
        this.dispositivo = new Dispositivo(lat, lng);                    
        this.quantidade = qtd;
        this.valor = valor;
    }        
}

export class CompraUtilizacaoCartaoBase extends CompraUtilizacaoBase{
    cartao: CartaoCompraUtilizacao;  
    constructor(lat: string,
                lng: string,
                qtd: number,                
                valor: number,
                tokenCartao: string){
        super(lat, lng, qtd, valor);
        this.cartao = new CartaoCompraUtilizacao(tokenCartao);
    } 
}

class UtilizacaoFrotista extends UtilizacaoBase {
    public frotista: FrotistaUtilizacao;
    constructor(){super();}   
}

class FrotistaUtilizacao {
    public IDEmpresaFrotistaCentroCusto: number;
    constructor(idEmpresaFrotistaCentroCusto: number){
        this.IDEmpresaFrotistaCentroCusto = idEmpresaFrotistaCentroCusto;        
    }    
}

class Frotista {
    public IDEmpresaFrotistaCentroCusto: number;
    public documentoAdminFrotista: string;
    constructor(idEmpresaFrotistaCentroCusto: number, documentoAdminFrotista: string = ""){
        this.IDEmpresaFrotistaCentroCusto = idEmpresaFrotistaCentroCusto;
        this.documentoAdminFrotista = documentoAdminFrotista;
    }    
}

class Dispositivo {
    public fingerprint: string; 
    private uuid: string; 
    public  lat: string; 
    public  lng: string; 
    private network: Network;
    constructor(lat: string, lng: string){
        this.lat = lat;
        this.lng = lng;
        this.fingerprint = "";
        this.uuid = "iOS 13.3/x86_64#asklhjaklejsketjhioejflskdjg";
        this.network = new Network()
    }    
}

class Network {
    private ip: string;
    private subnet: string;
    private type: string;
    constructor() {       
        this.ip = "127.0.0.1";
        this.subnet =  "255.255.255.0";
        this.type = "wifi";
    }
}

export class Filial {
    id: number;
    setor: number;
    area: number;
    vaga: number;
    constructor(id: number, setor: number, area: number, vaga: number){
        this.id = id;
        this.setor = setor;
        this.area = area;
        this.vaga = vaga;
    }
}

export class UtilizacaoCad {
    utilizacao: UtilizacaoBase;
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                ignoraMaximoCADs: boolean = false) {
        this.utilizacao = new UtilizacaoBase();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial; 
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs; 
    }    
}

export class UtilizacaoCadFrotista {
    utilizacao: UtilizacaoFrotista;
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                idEmpresaFrotistaCentroCusto: number,
                documentoAdminFrotista: string = "",
                ignoraMaximoCADs: boolean = false) {
        this.utilizacao = new UtilizacaoFrotista();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.frotista = new Frotista(idEmpresaFrotistaCentroCusto, documentoAdminFrotista);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial;  
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs;
    }    
}

export class CartaoCompraUtilizacao {
    public token: string;
    constructor(token: string){
        this.token = token;
    }
}

export class CompraUtilizacaoCartao {
    compra: CompraUtilizacaoComCartao;
    constructor(lat: string, 
        lng: string,
        qtd: number,
        tempo: number, 
        valor: number, 
        veiculo: VeiculoCad,
        filial: Filial,
        tokenCartao: string,
        ignoraMaximoCADs: boolean = false){  

        this.compra = new CompraUtilizacaoComCartao(lat, 
                                                    lng,
                                                    qtd, 
                                                    tempo,                                                    
                                                    valor,
                                                    veiculo,
                                                    filial,
                                                    tokenCartao,
                                                    ignoraMaximoCADs);
    }
}

export class CompraUtilizacaoCartaoFrotista {
    compra: CompraUtilizacaoFrotistaComCartao;    
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                idEmpresaFrotistaCentroCusto: number,
                token: string,
                documentoAdminFrotista: string = "",
                ignoraMaximoCADs: boolean = false){

        this
            .compra = new CompraUtilizacaoFrotistaComCartao(lat, 
                                                    lng,
                                                    qtd,
                                                    tempo, 
                                                    valor, 
                                                    veiculo,
                                                    filial,
                                                    idEmpresaFrotistaCentroCusto, 
                                                    token,
                                                    documentoAdminFrotista,
                                                    ignoraMaximoCADs);
    }
}

export class CompraUtilizacaoCarteira{
    compra: CompraUtilizacaoSemCartao;
    constructor(lat: string, 
        lng: string,
        qtd: number,
        tempo: number, 
        valor: number, 
        veiculo: VeiculoCad,
        filial: Filial,
        ignoraMaximoCADs: boolean = false){  

        this.compra = new CompraUtilizacaoSemCartao(lat, 
                                                    lng,
                                                    qtd, 
                                                    tempo,                                                    
                                                    valor,
                                                    veiculo,
                                                    filial,
                                                    ignoraMaximoCADs);
}}

export class CompraUtilizacaoCarteiraFrotista {
    compra: CompraUtilizacaoFrotistaSemCartao;     
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                idEmpresaFrotistaCentroCusto: number,
                documentoAdminFrotista: string = "",
                ignoraMaximoCADs: boolean = false){  
        this
            .compra = new CompraUtilizacaoFrotistaSemCartao(lat, 
                                                            lng,
                                                            qtd,
                                                            tempo, 
                                                            valor, 
                                                            veiculo,
                                                            filial,
                                                            idEmpresaFrotistaCentroCusto,
                                                            documentoAdminFrotista,
                                                            ignoraMaximoCADs);
    }
}

export class CompraUtilizacaoFrotistaComCartao extends CompraUtilizacaoCartaoBase {    
    utilizacao: UtilizacaoFrotista;
    frotista: Frotista;     
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                idEmpresaFrotistaCentroCusto: number,
                tokenCartao: string,
                documentoAdminFrotista: string = "",
                ignoraMaximoCADs: boolean = false) {
        super(lat, lng, qtd, valor, tokenCartao);               
        this.frotista = new Frotista(idEmpresaFrotistaCentroCusto, documentoAdminFrotista);
        this.utilizacao = new UtilizacaoFrotista();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial;  
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs;
    }        
}

export class CompraUtilizacaoComCartao extends CompraUtilizacaoCartaoBase {    
    utilizacao: UtilizacaoBase;       
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                tokenCartao: string,
                ignoraMaximoCADs: boolean = false) {
        super(lat, lng, qtd, valor, tokenCartao); 
        this.utilizacao = new UtilizacaoFrotista();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial;  
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs;
    }        
}

export class CompraUtilizacaoSemCartao extends CompraUtilizacaoBase{
    utilizacao: UtilizacaoBase;        
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                ignoraMaximoCADs: boolean = false) {
        super(lat, lng, qtd, valor); 
        this.utilizacao = new UtilizacaoBase();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial; 
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs; 
    }        
}

export class CompraUtilizacaoFrotistaSemCartao extends CompraUtilizacaoBase {    
    utilizacao: UtilizacaoFrotista;
    frotista: Frotista;     
    constructor(lat: string, 
                lng: string,
                qtd: number,
                tempo: number, 
                valor: number, 
                veiculo: VeiculoCad,
                filial: Filial,
                idEmpresaFrotistaCentroCusto: number,
                documentoAdminFrotista: string = "",
                ignoraMaximoCADs: boolean = false) {
        super(lat, lng, qtd, valor);               
        this.frotista = new Frotista(idEmpresaFrotistaCentroCusto, documentoAdminFrotista);
        this.utilizacao = new UtilizacaoFrotista();                    
        this.utilizacao.dispositivo = new Dispositivo(lat, lng);
        this.utilizacao.quantidade = qtd;
        this.utilizacao.tempo = tempo;
        this.utilizacao.valor = valor;
        this.utilizacao.veiculo = veiculo;
        this.utilizacao.filial = filial; 
        this.utilizacao.ignoraMaximoCADs = ignoraMaximoCADs; 
    }        
}

export class SetorFilter {
    public Id: number;
    public Descricao: string;
    public DescricaoSetor: string;
    public Bairro: string;
    Latitude: string;
    Longitude: string;
    IdArea?: number;
    constructor(id: number, descricao: string, latitude: string, longitude: string, descricaoSetor: string, bairro: string, idArea?: number){
        this.Id = id;
        this.Descricao = descricao;
        this.Bairro = bairro;
        this.Latitude = latitude;
        this.Longitude = longitude;
        this.DescricaoSetor = descricaoSetor;
        this.IdArea = idArea;
    }
}

export class SetorTarifa {

    public IdSetor: number;
    public Tarifas: Tarifa[];
    public Horarios: Horario[];
    constructor(idSetor: number, tarifas: Tarifa[], horarios: Horario[]){
        this.IdSetor = idSetor;
        this.Tarifas = tarifas;
        this.Horarios = horarios;
    }
}

export class Horario {
    public Descricao: string;
    public Dia: number;
    public Inicio: any;
    public Fim: string;
    public Permitido: boolean;
    constructor(descricao: string, dia: number, inicio: any, fim: string, permitido: boolean){
        this.Descricao = descricao;
        this.Dia = dia;
        this.Inicio = inicio;
        this.Fim = fim;
        this.Permitido = permitido;        
    }
}

export class Tarifa {
    public Tempo: number;
    public Valor: number;
    public Quantidade: number;
    public TipoVeiculo: number;
    public DescricaoRegra: string;
    Selected: Boolean;
    constructor(tempo: number, 
                valor: number, 
                quantidade: number, 
                tipoVeiculo: number, 
                descricaoRegra: string, 
                selected?: Boolean){
        this.Tempo = tempo;
        this.Valor = valor;
        this.Quantidade = quantidade;
        this.TipoVeiculo = tipoVeiculo;
        this.DescricaoRegra = descricaoRegra;
        this.Selected = selected == null ? false : selected;
    }   
} 