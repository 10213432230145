<div class="card border-0 shadow-sm mb-2">
    <div class="col py-2 pr-0 mb-2" style="border-bottom: solid 1px #4C4E51;">
        <div class="card-header border-0 bg-none" style="padding: 0px 15px;">
            <div class="row">
                <div class="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 align-self-center">
                    {{ 'Registration Data' | translate }}
                </div>
                <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 align-self-center">
                    <div class="dropdown" ngbDropdown>
                        <button [hidden]="!verFuncionalidade(funcionalidades.EditarCadastro)" class="btn btn-sm btn-link py-0 active" (click)="open()">
                            <i class="material-icons">more_horiz</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col col-sm-12 col-md-12 col-lg-12 col-xl-12 align-self-center mb-2">
                <p class="fs15">
                    {{(cliente !== undefined && cliente.nome !== undefined ? 'Full Name' : 'Nome Fantasia') | translate}}
                    <br>
                    <small class="text-template-primary-light">{{cliente !== undefined && cliente.nome !== undefined ? cliente.nome + " " + cliente.sobrenome : (cliente !== undefined && cliente.nomeFantasia != undefined ? cliente.nomeFantasia : "") }}</small>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                <p class="fs15">{{'Email' | translate}}<br><small class="text-template-primary-light">{{ (cliente != undefined ? cliente.email : "") | lowercase }}</small></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md mb-2">
                <p class="fs15">{{'Document' | translate}}<br><small class="text-template-primary-light">{{ (cliente != undefined ? cliente.documento.numero  : "").length == 11 ? 
                    ((cliente != undefined ? cliente.documento.numero  : "") | cpf) : (((cliente != undefined ? cliente.documento.numero  : "").length == 14) ?
                    ((cliente != undefined ? cliente.documento.numero  : "") | cnpj) : (cliente != undefined ? cliente.documento.numero  : "")) }}</small>
                </p>
            </div>
        </div>
        <div class="row" [hidden]="cliente === undefined || cliente.dataNascimento === undefined">
            <div class="col-12 col-md mb-2">
                <p class="fs15">{{'DateOfBirth' | translate}}<br><small class="text-template-primary-light">
                    {{ cliente?.dataNascimento?.substr(6,2) }}/{{ cliente?.dataNascimento?.substr(4,2) }}/{{ cliente?.dataNascimento?.substr(0,4) }}
                </small></p>
            </div>
        </div>
    </div>
</div>