<div class="modal-header">
    <h4 class="modal-title">{{'Movement' | translate}}</h4>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="relatorioForm">
      <div class="container form-group">
        <div class="row mb-3">
          <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
            <label for="periodo">{{'Period' | translate}}:</label>
            <select class="form-control" id="periodo" formControlName="periodo" >
                <option></option>
                <option *ngFor='let periodo of periodos' value="{{periodo.qtdDias}}">{{periodo.desc}}</option>
            </select>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
            <label for="periodoInicial">{{'FromPeriod' | translate}}:</label>
            <div class="form-inline">
                <div class="form-group">
                    <div class="input-group">
                        <input  [disabled]="relatorioForm.get('periodo').value !== '0' ? 'true' : 'false'"
                                (keyup)="dateMask($event)" maxlength="10" formControlName="periodoInicial" class="form-control" placeholder="dd/mm/aaaa"
                                [required]="relatorioForm.get('periodo').value === '0'" name="periodoInicial" id="periodoInicial" [minDate]="{year: 1900, month:1, day: 1}" ngbDatepicker #periodoInicial="ngbDatepicker"
                                [ngClass]="{'diabled-class': relatorioForm.get('periodo').value !== '0' ? true : false }" />
                        <div class="input-group-append">
                            <button [disabled]="relatorioForm.get('periodo').value !== '0' ? true : false" class="btn  btn-outline-secondary calendar" (click)="periodoInicial.toggle()" type="button"></button>
                        </div>
                    </div>                            
                </div>                            
            </div> 
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
            <label for="periodoFinal">{{'ToPeriod' | translate}}:</label>
            <div class="form-inline">
                <div class="form-group">
                    <div class="input-group">                                
                        <input  [disabled]="relatorioForm.get('periodo').value !== '0' ? 'true' : 'false'"
                                (keyup)="dateMask($event)" maxlength="10" formControlName="periodoFinal" class="form-control" placeholder="dd/mm/aaaa"
                                [required]="relatorioForm.get('periodo').value === '0'" name="periodoFinal" id="periodoFinal" [minDate]="{year: 1900, month:1, day: 1}" ngbDatepicker #periodoFinal="ngbDatepicker"
                                [ngClass]="{'diabled-class': relatorioForm.get('periodo').value !== '0' ? true : false }" />
                        <div class="input-group-append">
                            <button [disabled]="relatorioForm.get('periodo').value !== '0' ? true : false"
                                    class="btn btn-outline-secondary calendar" (click)="periodoFinal.toggle()" type="button"></button>
                        </div>
                    </div>                            
                </div>                            
            </div> 
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-1 col-xl-1">
            
            <label for="documentoCliente">{{'Credit' | translate}}:</label>
            <input type="checkbox" class="form-control form-control-sm" formControlName="tipoTransacaoCredito">
            
          </div>

          <div class="col-12 col-sm-12 col-md-4 col-lg-1 col-xl-1">
            
            <label for="documentoCliente">{{'Utilizations' | translate}}:</label>
            <input type="checkbox" class="form-control form-control-sm" formControlName="tipoTransacaoUtilizacao">
            
          </div>
       
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <label for="placa">{{ 'LicensePlate' | translate }}:</label>
                <input class="form-control" id="placa" placeholder="{{ 'LicensePlate' | translate }}" formControlName="placa" [textMask]="{mask: maskPlaca}" style="text-transform: uppercase" />
            </div>
            <div class="col-md-3">
                <label for="cpfUsuario">{{ 'ClientIdentificationDocument' | translate }}:</label>
                <input class="form-control" id="cpfUsuario" placeholder="{{ 'ClientIdentificationDocument' | translate }}" formControlName="cpfUsuario" />
            </div>
            <div class="col-md-2">
              <label for="placa">&nbsp;</label>
              <button type="button" 
                      class="btn btn-outline-primary btn-sm btn-relatorio form-control" (click)="consultar(false)">{{ 'Consult' | translate }}</button>
            </div>
            <div class="col-md-2">
              <label for="placa">&nbsp;</label>
              <button type="submit" 
                      class="btn btn-outline-success btn-sm btn-relatorio form-control" 
                      (click)="baixar(true)">{{ 'Export' | translate}}</button>
            </div>
        </div>
        <div class="row">

          <div class="col-12">
            <ngb-pagination
            [(page)]="page" [maxSize]="18" [rotate]="true" [boundaryLinks]="true"                
            [pageSize]="pageSize"
            [collectionSize]="totalRegistros"                
            (pageChange)="loadPage($event)">
      </ngb-pagination>
            <table class="table table-hover">
                <thead>
                    <tr style="cursor: pointer; color: #fff;height: auto; font-weight: 500;font-size: 0.8em;background-color: #4a9f42;">
                        <td style="padding: 03px;">Data</td>
                        <td style="padding: 03px;">Tipo</td>
                        <td style="padding: 03px;">Local</td>
                        <td style="padding: 03px;">Setor/Via</td>
                        <td style="padding: 03px;">Nº Regularização</td>
                        <td style="padding: 03px;">Placa</td>
                        <td style="padding: 03px;">Canal</td>
                        <td style="padding: 03px;">Documento</td>
                        <td style="padding: 03px;">Produto</td>                        
                        <td style="padding: 03px;">Quantidade</td>
                        <td style="padding: 03px;">Cart. Virtual</td>
                        <!-- <td style="padding: 02px;"><i class="material-icons">pageview</i></td> -->
                    </tr>
                </thead>
                <tbody *ngFor="let movimento of movimentacoes">
                    <tr>
                      <td colspan="11" style="font-weight: 500; text-align: left;padding: 04px;background-color: #f1f1f1;color: #000;">
                        Usuário: {{movimento.nome}} -  - {{movimento.documento}}</td>
                    </tr>
                    <ng-container *ngFor="let item of movimento.movimentos;">
                      <tr (click)="detalheMovimento(item, movimento.nome)" style="cursor: pointer;font-size: 0.8em">
                        <td style="color: #000 !important;padding: 03px;">{{item?.data | date:'dd/MM/yyyy hh:mm:ss'}}</td>
                        <td style="color: #000 !important;padding: 03px;">{{item?.tipoTransacao}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.local}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.setor}}/{{item.regra}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.tiqueteAutenticacao}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.placa}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.canal}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.documento}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.produto}}</td>                         
                        <td style="color: #000 !important;padding: 03px;">{{item?.quantidadeCAD}}</td> 
                        <td style="color: #000 !important;padding: 03px;">{{item?.carteiraVirtual | currency:'':''}}</td>
                        <!-- <td style="color: #000 !important;padding: 02px;">
                          <button class="btn btn-sm text-success" (click)="detalheMovimento(item)"><i class="material-icons">search</i></button>                            
                        </td>                                             -->
                    </tr>
                    </ng-container>                  
                </tbody>
            </table>
          </div>


            <!-- <div class="card-body table-responsive shadow-sm scroll-y h-250 p-0">               
                
                <table class="table table-responsive table-hover table-striped">
                    <tbody *ngFor="let movimento of movimentacoes">
                      <tr [hidden]="hide(movimento.movimento.data)">
                        <td colspan="5" style="text-align: right;">{{'Month' | translate }}  {{movimento.movimento.data | date:'MM' | uppercase}} de {{movimento.movimento.data | date:'yyyy'}}</td>
                      </tr> 
                      <tr (click)="detalheMovimento(movimento)" style="cursor: pointer;">
                        <td><i class="material-icons">{{movimento.movimento.tipo === "utilizacao" ? "directions_car" : "monetization_on" }} </i></td>
                        <td>{{getNiceDescription(movimento.data)}}</td>
                        <td>{{movimento.data | date:'dd/MM/yyyy'}}</td>
                        <td>{{movimento.tipoTransacao}}</td>                       
                        <td><i class="material-icons">open_in_new</i></td>
                      </tr>
                    </tbody>
                </table>
            </div> -->
        </div>       
      </div>
      <div class="modal-footer mt-4" style="padding: 0px;">
          <!-- <button type="button" class="btn btn-outline-primary" (click)="consultar(false)">{{ 'Consult' | translate }}</button> -->
          <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate}}</button>
          <!-- <button type="submit" class="btn btn-outline-success" (click)="baixar(true)">{{ 'Export' | translate}}</button> -->
      </div>
    </form>
  </div>