export const environment = {
  production: false,
  UrlInicioService: "https://gtwhml.zadsp.com.br/viprod/xBOZgBvySC1ljpifozaj/",
  UrlBase: "https://gtwhml.zadsp.com.br/viprod/xBOZgBvySC1ljpifozaj/",
  UrlClienteFull: "https://gtwhml.zadsp.com.br/",
  UrlServiceNewToken: "https://gtwhml.zadsp.com.br/broker/services/zad/clientes/",
  UrlCDN: "https://cacheappestapar.blob.core.windows.net/",
  BaseURL: "https://www.gatewayestapar.com.br/vi.qa2/OoPeRfSIATM570ClXPue/", 
  UrlCredito: "https://gtwhml.zadsp.com.br/compras/v1/",
  BaseUrlFunction: "https://apihml.zadsp.com.br/api/",
  baseProd: "https://gtwhml.zadsp.com.br/broker/services/retaguarda/",
  timeout: 150000,
  UrlBaseBroker: 'https://gtwhml.zadsp.com.br', 
  FilialId: 38,
  ChaveCriptografia: "E$T4P4RZ0N44ZULD161T4L@",
  UrlCdn: "https://zadsphml.blob.core.windows.net",
  UrlViaCep: "https://viacep.com.br/ws/{cep}/json/",
  ZendeskKey : "b8b819f7-bcdd-4a9e-908c-abd1e869d88d",
  
  FuncCodeAuthSms: "Tjak2m5GUdLMZa8C5Z3FbNPiUQa/H2rMNEKWZsPiufo5vcYDlOXQpg==",//ok
  FunctionCodeAuthorizationGeoJson: "lUa8YQur7QBQeKPNArArjabA0Ro31C/V8GtacVpD6F8te4H2q0ckcA==",
  FuncCodeAuthAddUsuarioFrotista: "saDwCoWrZVjGiTuwwTo7H/aXZgwcS5f74QEAaugcVMSOrM619q7oIA==",
  FuncCodeAuthRemoveUsuarioFrotista: "pVKAWSxSGV3pFazU21FyA7s7vTU1oo0hVJgxvlDQag1Ixadgun8XVA==",  
  FuncCodeAuthBoleto: "ybpJaawpCbEHY6UKMzx3WyW1V81vGeZhCSNd1uZIOmY56QZeeekwbQ==",
  FuncCodeAuthUsuariosFrotAdmin: "IjPRs8UGOalZlaAb5BtA2qmzQRS2H40staiwAUqG5vXsz3LT5/8AaQ==",
  FuncCodeAuthLeadFrotista: "OwJXzSTIOnLNq1wNGqnLNPKPsTdRIiXrXnqSoNAyVnbGJqqE51OoBw==",
  FuncCodeTrocaSenha: "KWf3GcZk3XzJRWT3C6p-BRq2FxiwhPS70jDvTkCzsBaHAzFu9RVJww==",
  FuncCodeSolicitaTrocaSenha: "sCEmhDGzxcKbj582fzajmnkTaWF20ZjSePotPiFzyn/E6TViwexgwg==",
  FuncCodeRelatorioMovimentacoes: "02O6BerNIAKOZXr6BbAvGEwzNv64l7tdYcdT5A4vK1m9vyVKN1cCsw==",
  FuncCodeGetBoleto: "PxR6g6d/IjYT13UhfOhpgL92sYhFhjQV0IJWWISvoUbGCka7Wpw0jw==",

  AssertivaKey: "haPTAjx3S0lf8Pxcibago5QKXfiGjjoTzMb0SZaZFWVFZkkXzL51ag==",  
  UrlGetBoletosBroker: "https://gatewayhml.estapar.com.br",
  RotaGetBoletosBroker: "/zadsp/boleto",
  Instituicao: 407,
  UrlGetDevice: "https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id={sessao}",
  UrlConsultaAutenticacaoCadCet : "http://appprod.cetsp.com.br/cadcon/ConsAut.aspx",
  DataInicioOperacao : "17/01/2020",
  ComerciosEspeciais: [
    { Cnpj: "04544368000171", Liberado: true }
  ],
  BrokerApplicationIdPortalCliente: "15",
  UrlValidacaoDominio: "https://validadominiohml.zadsp.com.br/AntiFraude/V1/ValidacaoDominio/",
  KeyRecaptcha: '6Lfoo5UlAAAAAEgRPY2oZJ6xT_nwuD7FE9p2vV5K',
  BaseRecaptcha: 'https://antifraudehml.zadsp.com.br/recaptcha/api/Recaptcha'
};