<div class="modal-header page-checkout">
    <h4 class="modal-title">
        <div [ngSwitch]="movimento?.tipo" style="font-size: 1.1em; font-weight: 580;">
            <div *ngSwitchCase="'utilizacao'">
                {{'ActivationOfBlueZone' | translate}} <span style="color:#70d44b;"> {{movimento.quantidade}} CAD </span>
            </div>
            <div *ngSwitchCase="'recarga'">
                {{'RechargeEwallet' | translate}} <span style="color:#70d44b;"> {{movimento.valor | currency:'BRL'}} </span>
            </div>
            <div *ngSwitchCase="'recarga-cad'">
                {{'CadPurchase' | translate}} <span style="color:#70d44b;"> {{movimento.quantidade}} CAD </span>  
            </div>
            <div *ngSwitchCase="'estorno-recarga'">
                {{'ReversalOfPayment' | translate}} <span style="color:#70d44b;"> - {{movimento.valor | currency:'BRL'}} </span>  
            </div>
            <div *ngSwitchCase="'portabilidade'">
                {{'PortabilityCad' | translate}} <span style="color:#70d44b;"> {{movimento.quantidade}} </span>  
            </div>            
        </div>     
    </h4>
</div>
<div class="modal-body">
    <div class="container" *ngIf="movimento === undefined || movimento === null ">
        <h4>{{'TransactionHistoryError' | translate}}</h4>
    </div>
     <div class="container" *ngIf="movimento !== undefined && movimento?.tipo === 'portabilidade'">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento.data }}</span>                
            </div>
        </div>       
    </div>    
    <div class="container" *ngIf="movimento !== undefined && movimento?.tipo === 'estorno-recarga'">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento.data }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Valor</strong><span style="margin-left: 6px;">- {{ movimento.valor | currency:'BRL' }}</span>                
            </div>
        </div>  
    </div>
    <div class="container" *ngIf="movimento !== undefined && movimento?.tipo === 'recarga'">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento.data }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Forma pagamento:</strong>
                <span style="margin-left: 6px;">
                 {{movimento?.formaPagamento}} {{ (movimento?.bandeira?.length >= 1 && movimento?.cartao?.length >= 1 ) ? movimento?.bandeira + ' final: ' + movimento?.cartao  : ''  }}
                </span>                
            </div>            
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Valor</strong><span style="margin-left: 6px;">{{ movimento.valor | currency:'BRL' }}</span>                
            </div>
        </div>  
    </div>
    <div class="container" *ngIf="movimento !== undefined && movimento?.tipo === 'recarga-cad'">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento.data }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Forma pagamento:</strong>
                <span style="margin-left: 6px;">
                 {{movimento?.formaPagamento}}
                 {{ (movimento?.bandeira?.length >= 1 && movimento?.cartao?.length >= 1 ) ? movimento?.bandeira + ' final: ' + movimento?.cartao  : ''  }}
                </span>                
            </div>            
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Valor</strong><span style="margin-left: 6px;">{{ movimento.valor | currency:'BRL' }}</span>                
            </div>
        </div>  
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Quantidade:</strong><span style="margin-left: 6px;">{{ movimento.quantidade }}</span>                
            </div>
        </div>   
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Autenticação:</strong><span style="margin-left: 6px;">{{ movimento.autenticacaoExterna }}</span>                
            </div>
        </div>
    </div>
    <div class="container" *ngIf="movimento !== undefined && movimento?.tipo === 'utilizacao'">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Data:</strong><span style="margin-left: 6px;">{{ movimento.data }}</span>                
            </div>
        </div>        
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Valor</strong><span style="margin-left: 6px;">{{ movimento.valor | currency:'BRL' }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Quantidade:</strong><span style="margin-left: 6px;">{{ movimento.quantidade }}</span>                
            </div>
        </div>  
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Autenticação:</strong><span style="margin-left: 6px;">{{ movimento.autenticacaoExterna }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Início do CAD:</strong><span style="margin-left: 6px;">{{ movimento?.tiquete?.inicio }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Fim do CAD:</strong><span style="margin-left: 6px;">{{ movimento?.tiquete?.fim }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Tempo adiquirido:</strong>
                    <span style="margin-left: 6px;">
                        {{ movimento?.tiquete?.tempo }}
                    </span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Setor:</strong><span style="margin-left: 6px;">{{ movimento.filial.setor }}</span>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>Veículo:</strong><span style="margin-left: 6px;">{{ movimento.placa }}</span>                
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-23" style="border-bottom: solid 1px silver; padding:6px;">
                <strong>{{'RPSNumber' | translate}}</strong>
                <span style="margin-left: 6px;">{{ movimento.rps }}</span>
                <hr />
                <span [innerHTML]="(movimento.ano2021 ? 'RPSDescription2021' : 'RPSDescription') | translate"></span>                
            </div>
        </div>
        
    </div>
    
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
</div>