import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PoliticaPrivacidadeComponent } from '../../components/politicaPrivacidade/politica-privacidade.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lgpd',
  templateUrl: './lgpd.component.html',
  styles: []
})
export class LgpdComponent {
  public propertyTitle: string = "";
  isHidden: boolean = false;

  constructor(
    private modalService: NgbModal
    ,private translate: TranslateService){

      if(localStorage.getItem('app.lgpd') === null){
        localStorage.setItem('app.lgpd', "false");
      }

      this.isHidden = localStorage.getItem('app.lgpd') === "true";
  }

  aceiteLgpd() {
    localStorage.setItem('app.lgpd', "true");
    this.isHidden = true;
  }
}
