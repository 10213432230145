import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { PositionMap } from 'src/app/model/positionMap';

@Component({
  selector: 'app-ativacao-cad-search',
  templateUrl: './ativacao-cad-search.component.html',
})
export class SearchComponent implements OnInit {

  @Output()
  positionChanged: EventEmitter<PositionMap> = new EventEmitter<PositionMap>();

  @ViewChild('search', { static: true })
  searchElementRef: ElementRef;

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement, { types: ['address'] },
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.positionChanged.emit(new PositionMap(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ));
      });
    });
  }
}
