import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable()
export class FAQService extends BaseService {
    constructor(private http: HttpClient) { super() }

    getFAQs(lang: string): Observable<any[]> {
        return this.http
            .get<any[]>("http://localhost:55780/api/faq/" + lang);
    }
}