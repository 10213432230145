<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <label>{{ detalheFrotistaAdmin.descricaoCidade }}</label>
            </div>
            <div class="col-12">
                <label>ID: {{ detalheFrotistaAdmin.guidOperacao}}</label>
            </div>
            <div class="col-12">
                <label>Placa: {{detalheFrotistaAdmin.placaVeiculo}}</label>
            </div>
            <div class="col-12">
                <label>Autenticação: {{detalheFrotistaAdmin.autenticacaoCET}}</label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
</div>