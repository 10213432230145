export class Veiculo {
    "veiculo": {
        "placa": string,
        "tipo": number,
        "favorito": boolean        
    };
    "usuario": {
        "frotista": boolean
    };   
}

export class VeiculoRetorno {
    constructor(ano: number,
                cor: string,
                id: number,
                marca: string,
                modelo: string,
                placa: string,
                tipo: number,
                favorito: boolean = false){
        this.ano = ano;
        this.cor = cor;
        this.id = id;
        this.marca = marca;
        this.modelo = modelo;
        this.placa = placa;
        this.tipo = tipo;
        this.favorito = favorito;
    }
    ano: number;
    cor: string;
    id: number;
    marca: string;
    modelo: string;
    placa: string;
    tipo: number;
    favorito: boolean;
}

export class VeiculoCad {
    placa: string; 
    tipo: number; 
    favorito: boolean;
    constructor(placa: string, tipo: number, favorito: boolean = false){
        this.tipo = tipo; 
        this.placa = placa;
        this.favorito = favorito;
    }
}