import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { timeout, map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BoletoDetalhe, BoletoParam } from 'src/app/model/boleto';
import { environment } from 'src/environments/environment';

@Injectable()
export class CreditoService extends BaseService {
    constructor(private http: HttpClient) { super() }
    
    comprarCredito(documento: string, param, tokenRecaptcha:string){
        return this.http
            .post(this.UrlCredito + documento + "/credito", JSON.stringify(param), {
                headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'Authorization': this.obterTokenUsuario(),'recaptchaToken':tokenRecaptcha })
            }).pipe(
                //timeout(15000),
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    comprarCreditoComBoleto(param: BoletoDetalhe) : Observable<any> {
        return this.http
        .post(`${this.BaseUrlFunction}app/boleto/v1?code=${this.FuncCodeAuthBoleto}`, JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })
        })
        .pipe(
            map(super.extractData),
            catchError(super.serviceError)
        )
    }

    comprarCADcomCartao(documento: string, param, tokenRecaptcha:string){
        return this.http
        .post(this.UrlCredito + documento + "/cartaoCredito", JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'Authorization': this.obterTokenUsuario(),'recaptchaToken':tokenRecaptcha  })
        }).pipe(            
            map(super.extractData),
            catchError(super.serviceError)
        );
    }

    comprarCADcomEwallet(documento: string, param){
        return this.http
        .post(this.UrlCredito + documento + "/carteiraVirtual", JSON.stringify(param), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Applicationid': environment.BrokerApplicationIdPortalCliente, 'Authorization': this.obterTokenUsuario() })
        }).pipe(
            map(super.extractData),
            catchError(super.serviceError)
        );
    }
}