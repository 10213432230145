import { map } from 'rxjs/operators';
import { NgbActiveModal, NgbModalConfig, NgbModal, NgbDatepickerI18n, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { ParamCliente } from 'src/app/model/DTO/paramCliente';
import { Cliente } from 'src/app/model/cliente';
import { ClienteService } from '../../service/cliente.service';
import { TermoUsoComponent } from '../termosUso/termo-uso.component';
import Swal from 'sweetalert2';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { createElementCssSelector } from '@angular/compiler';
import { of } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-cadastro-basico-cliente',
    templateUrl: './cadastro-basico-cliente.component.html',
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }, ReCaptchaV3Service]
})

export class CadastroBasicoClienteComponent {
    cadastroBasicoClienteForm: FormGroup;
    clientePost: ParamCliente = new ParamCliente();
    dateNull?: NgbDate;

    public aceiteTermoUso: boolean = false;
    msgConfirmacaoSmsCode: string = this.translate.instant('CodeConfirmCellPhone');
    htmlMsgCodigoInvalidoSms: string = "<br /><br /> <b><span style='color:red'>Código inválido ou houve algum erro na confirmação, tente digitar novamente</span></b>";

    public mask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    public tokenRecaptcha: string;

    get repetirSenha() { return this.cadastroBasicoClienteForm.get('repetirSenha') };
    get celularCliente() { return this.cadastroBasicoClienteForm.get('celularCliente') };
    get telefones(): FormArray {
        return this.cadastroBasicoClienteForm.get("telefones") as FormArray
    };

    constructor(
        private modalService: NgbModal
        , config: NgbModalConfig
        , public activeModal: NgbActiveModal
        , private translate: TranslateService
        , private clienteService: ClienteService
        , private fb: FormBuilder
        , private recaptchaV3Service: ReCaptchaV3Service) {

        config.backdrop = 'static';
        config.keyboard = false;

        let email = new FormControl('', [Validators.required, Validators.email]);
        let celularCliente = new FormControl('', [Validators.required]); //let confirmEmail = new FormControl('', [Validators.required, Validators.email, this.confirmacaoEmailValidator()]);

        let senha = new FormControl('', [Validators.required]);
        let repetirSenha = new FormControl('', [Validators.required, this.confirmacaoSenhaValidator()]);

        let razaoSocial = new FormControl('', []);
        let nomeFantasia = new FormControl('', []);

        this.cadastroBasicoClienteForm = this.fb.group({
            nome: ['', []],
            sobrenome: ['', []],
            razaoSocial: razaoSocial,
            nomeFantasia: nomeFantasia,
            documento: new FormGroup({
                numero: new FormControl('', [Validators.required]),
                tipo: new FormControl('', [])
            }),
            dataNascimento: [this.dateNull, []
            ],
            email: email,
            telefones: this.fb.array([]),
            celularCliente: celularCliente,
            senha: senha,
            repetirSenha: repetirSenha
        }, { updateOn: 'change' });

        this.cadastroBasicoClienteForm.reset();
    }

    novoTelefone(ddd: number, numero: number): FormGroup {
        return this.fb.group({
            telefone: {
                tipo: '1',
                ddd: ddd,
                numero: numero,
                isPrincipal: true
            }
        })
    };

    adicionarCel(ddd: number, numero: number) {
        this.telefones.push(this.novoTelefone(ddd, numero));
    };

    validarDataFutura(): boolean {
        const dataNascimento = this.cadastroBasicoClienteForm.get('dataNascimento').value;
        return new Date() >= new Date(dataNascimento.year, dataNascimento.month - 1, dataNascimento.day);
    }

    private _validarFormularioCadastro(): boolean {
        if (!this.cadastroBasicoClienteForm.valid) {
            Swal.fire({
                text: this.translate.instant('RequiredFields'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42',
                width: 'auto'
            });
            return false;;
        }

        if (this.onlyNumber(this.cadastroBasicoClienteForm.value.celularCliente.replace(/\D/g, ''))?.length < 10
            || this.onlyNumber(this.cadastroBasicoClienteForm.value.celularCliente.replace(/\D/g, ''))?.length > 11) {
            Swal.fire({
                text: this.translate.instant('CellPhoneInvalid'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42'
            });
            return false;
        }

        if (!this.aceiteTermoUso) {
            Swal.fire({
                text: 'Obrigatório aceitar os termos de uso...',
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42',
                width: 'auto'
            });
            return false;
        }

        if (this.cadastroBasicoClienteForm.get('documento.tipo').value !== 'CNPJ'
            && !this.validarDataFutura()) {
            Swal.fire({
                text: `Data de nascimento maior à HOJE!...`,
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42',
                width: 'auto'
            });
            return false;
        }

        if (!this._validarForcaDaSenha()) {
            debugger;
            Swal.fire({
                html: this.translate.instant('CriterionPassword'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',
                confirmButtonColor: '#4a9f42',
                width: 'auto'
            });
            return false;
        }

        return true;
    }

    salvar() {
        this.cadastroBasicoClienteForm.patchValue({
            dataNascimento: this.cadastroBasicoClienteForm.get('documento.tipo').value !== 'CNPJ' ? this.getDatePost(this.cadastroBasicoClienteForm.get('dataNascimento').value) : null,
            documento: {
                numero: this.onlyLetterNumber(this.cadastroBasicoClienteForm.get('documento.numero').value),
                tipo: (this.onlyLetterNumber(this.cadastroBasicoClienteForm.get('documento.numero').value).length == 11) ? "CPF" : (this.onlyLetterNumber(this.cadastroBasicoClienteForm.get('documento.numero').value).length == 14 ? "CNPJ" : (this.onlyLetterNumber(this.cadastroBasicoClienteForm.get('documento.numero').value).length == 8 ? "PASSAPORTE" : ""))
            }
        });

        this.adicionarCel(parseInt(this.cadastroBasicoClienteForm.value.celularCliente.slice(0, 2)),
            parseInt(this.cadastroBasicoClienteForm.value.celularCliente.slice(2)));

        this.clientePost.cliente = JSON.parse(JSON.stringify(Object.assign({}, Cliente, this.cadastroBasicoClienteForm.getRawValue())));

        this.recaptchaV3Service.execute('CadastroCliente').subscribe((token: string): void => {
            this.tokenRecaptcha = token;
            
            if (this.clientePost.cliente.documento.tipo === "CPF" || this.clientePost.cliente.documento.tipo === "PASSAPORTE") {

                this.clienteService.postClientePF(this.clientePost, this.tokenRecaptcha)
                    .subscribe(
                        result => {

                            this.activeModal.close('Close click');
                            Swal.fire({
                                text: result.message,
                                titleText: this.translate.instant('AlertInfo'),
                                icon: 'success',
                                confirmButtonColor: '#4a9f42',
                                width: 'auto'
                            });
                        },
                        fail => {
                            //this.spinner.hide();
                            Swal.fire({
                                text: fail.message,
                                titleText: this.translate.instant('AlertInfo'),
                                icon: 'info',
                                confirmButtonColor: '#4a9f42',
                                width: 'auto'
                            });
                        }
                    )
            } else if (this.clientePost.cliente.documento.tipo === "CNPJ") {

                this.clienteService.postClientePJ(this.clientePost, this.tokenRecaptcha)
                    .subscribe(
                        result => {
                            //this.spinner.hide();

                            this.activeModal.close('Close click');

                            Swal.fire({
                                text: result.message,
                                titleText: this.translate.instant('AlertInfo'),
                                icon: 'success',
                                confirmButtonColor: '#4a9f42',
                                width: 'auto'
                            });
                        },
                        fail => {

                            //this.spinner.hide();

                            Swal.fire({
                                html: fail.message,
                                titleText: this.translate.instant('AlertInfo'),
                                icon: 'info',
                                confirmButtonColor: '#4a9f42',
                                width: 'auto'
                            });
                            //this.activeModal.close('Close click');
                        }
                    )
            }
        });
    }

    private _validarForcaDaSenha(): boolean {
        if (this.cadastroBasicoClienteForm.value.senha.length < 8) return false;
        //if(!this.cadastroBasicoClienteForm.value.senha.match(/[a-z]+/)) return false;
        if (!this.cadastroBasicoClienteForm.value.senha.match(/[A-Z]+/)) return false;
        if (!this.cadastroBasicoClienteForm.value.senha.match(/[0-9]+/)) return false;

        return true;
    }

    blur($event) {
        if (this.cadastroBasicoClienteForm.get('documento.numero')?.value === null
            || this.cadastroBasicoClienteForm.get('documento.numero')?.value === undefined) return;

        if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 0 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 8) {
            this.setarTipoDocumento("PASSAPORTE");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 9 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 11) {
            this.setarTipoDocumento("CPF");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 11) {
            this.setarTipoDocumento("CNPJ");
        }
    }

    keyup(event) {
        if (this.cadastroBasicoClienteForm.get('documento.numero')?.value === null
            || this.cadastroBasicoClienteForm.get('documento.numero')?.value === undefined) return;

        if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 0 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 8) {
            this.setarTipoDocumento("PASSAPORTE");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 9 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 11) {
            this.setarTipoDocumento("CPF");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 11) {
            this.setarTipoDocumento("CNPJ");
        }
    }

    setarTipoDocumento(param: string) {
        this.cadastroBasicoClienteForm.patchValue({
            documento: {
                tipo: param.toUpperCase()
            }
        });
    }

    onPaste(event: ClipboardEvent) {

        if (this.cadastroBasicoClienteForm.get('documento.numero')?.value === null
            || this.cadastroBasicoClienteForm.get('documento.numero')?.value === undefined) return;

        if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 0 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 8) {
            this.setarTipoDocumento("PASSAPORTE");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 9 && this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length <= 11) {
            this.setarTipoDocumento("CPF");
        } else if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 11) {
            this.setarTipoDocumento("CNPJ");
        }

        if (this.cadastroBasicoClienteForm.get('documento.numero').value.replace(/\D/g, '').length > 11) {
            this.mask = [/[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /[/]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
        } else {
            this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
        }
    }

    onlyNumber(param: string) {
        return param.replace(/\D/g, '');
    }

    getDatePost = (data: any) => `${data.year}${this.padLeft(data.month, 2)}${this.padLeft(data.day, 2)}`;

    onlyLetterNumber(param: string) {
        return param.replace(/[^A-Za-z0-9]+/g, '')
    }

    padLeft(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    open() {
        const modalRef = this.modalService.open(TermoUsoComponent, { centered: true, scrollable: true, size: 'md' });
    }

    onChangeSenha() {
        this.cadastroBasicoClienteForm.get('repetirSenha').updateValueAndValidity();
    }

    confirmacaoSenhaValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const form = control.parent;
            const valido = control.value === form?.get('senha')?.value;
            return !valido ? { confirmacaoSenhaInvalido: { value: control.value } } : null;
        };
    }

    confirmacaoEmailValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const form = control.parent;
            const valido = control.value === form?.get('email')?.value;
            return !valido ? { confirmacaoEmailInvalido: { value: control.value } } : null;
        };
    }

    dateMask(event: any) {
        var v = event.target.value;
        if (v.match(/^\d{2}$/) !== null) {
            event.target.value = v + '/';
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            event.target.value = v + '/';
        }
    }

    enviarEmailConfirmacao() {

        this.clienteService.enviarConfirmacaoEmail(this.cadastroBasicoClienteForm.value.email)
            .subscribe(
                resultado => {

                    if (!resultado?.necessitaCodigoEmail) {
                        this.enviarSmsConfirmacao();
                        return;
                    }

                    if (resultado?.emailEnviado === true
                        && !resultado.hasOwnProperty("jaCadastrado")) {
                        this.confirmarCodigoEmail();
                        return;
                    }

                    if (resultado.hasOwnProperty("jaCadastrado") && resultado?.jaCadastrado === true)
                        Swal.fire({
                            text: this.translate.instant('EmailExists'),
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',
                            confirmButtonColor: '#4a9f42',
                            width: 'auto'
                        });
                },
                () => {

                }
            );
    }

    confirmarCodigoEmail() {
        Swal.fire({
            titleText: this.translate.instant('AlertInfo'),
            text: this.translate.instant('MsgConfirmEmailCodeToRegister'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Confirm'),
            showLoaderOnConfirm: true,
            //allowOutsideClick: false,
            preConfirm: (code) => {
                this.clienteService.confirmarCodigoEmail(this.cadastroBasicoClienteForm.value.email, code)
                    .subscribe(
                        () => {
                            this.enviarSmsConfirmacao();
                            return "OK";
                        },
                        error => {
                            return "ERROR";
                        }
                    );
                return "OK";
            },
            allowOutsideClick: () => false
        })
            .then(() => { });
    }

    enviarSmsConfirmacao() {
        this.clienteService
            .enviarConfirmacaoSms(`55${this.cadastroBasicoClienteForm.value.celularCliente.replace(/\D/g, '')}`,
                this.translate.currentLang)
            .subscribe(
                resultado => {

                    if (!resultado.hasOwnProperty("jaCadastrado")) {
                        this.confirmarCodigoSms();
                        return;
                    }

                    if (resultado.hasOwnProperty("jaCadastrado") && resultado?.jaCadastrado === true)
                        Swal.fire({
                            text: this.translate.instant('MobilePhoneNumberExists'),
                            titleText: this.translate.instant('AlertInfo'),
                            icon: 'info',
                            confirmButtonColor: '#4a9f42',
                            width: 'auto'
                        });
                },
                () => { }
            );
    }

    confirmarCodigoSms(msgError: string = "") {

        this.msgConfirmacaoSmsCode = "";
        this.msgConfirmacaoSmsCode = this.translate.instant('CodeConfirmCellPhone') + msgError;

        Swal.fire({
            titleText: this.translate.instant('AlertInfo'),
            html: this.msgConfirmacaoSmsCode,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'on'
            },
            showCancelButton: true,
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Confirm'),
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
                this.clienteService.confirmarCodigoSms(`55${this.cadastroBasicoClienteForm.value.celularCliente.replace(/\D/g, '')}`, code)
                    .subscribe(
                        () => {
                            this.salvar();
                            return "OK";
                        },
                        error => {
                            this.confirmarCodigoSms(this.htmlMsgCodigoInvalidoSms);
                            return "ERROR";
                        }
                    );
                return "OK";
            },
            allowOutsideClick: () => false
        })
            .then(() => { });
    }

    cadastrar() {
        if (!this._validarFormularioCadastro()) return;
        this.enviarEmailConfirmacao();
    }
}