import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class EstadosService extends BaseService {
    constructor(private http: HttpClient) { super() }

    getEstados(): Observable<any[]> {
        return this.http
            .get<any[]>(this.UrlCDN + "brasil/estados.json");
    }
}