import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FiliaisService } from '../../service/filiais.service';

@Component({
    selector: 'app-termo-uso',
    templateUrl: './termo-uso.component.html'
  })

  export class TermoUsoComponent {
      public textoTermoUso: string;
      public htmlTermoUso: any;

      constructor(config: NgbModalConfig
                  ,public activeModal: NgbActiveModal
                  ,private filialService: FiliaisService
                  ,private translate: TranslateService){
                  
            this.filialService.getTermoDeUso(this.translate.currentLang).subscribe(res => {
              this.htmlTermoUso = res;
            })
      }      
  }