<div class="card border-0 shadow-sm mb-4">
    <div class="col py-2 pr-0">
        <div class="card-header border-0 bg-none">
            {{ 'Extract' | translate }} Frotista
        </div>
        <!--<div>
          <label for="dataInicial">Data Inicial: *</label>
          <input type="date" id="dataInicial" class="form-control" placeholder="dd/mm/yyyy" >
        </div>
        <div>
          <label for="dataFinal">Data Final: *</label>
          <input type="date" id="dataFinal" class="form-control" placeholder="dd/mm/yyyy" >
        </div>-->
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-300">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-pills nav-fill" >
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>Todos</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                        <div class="card-body scroll-y h-250 p-0">
                          <div class="card-body table-responsive">
                            <table class="table table-hover table-striped" style="font-size: 10px !important;">
                                <tbody *ngFor="let retorno of retornoExtrato">
                                  <tr [hidden]="hide(retorno.documento)">
                                    <td colspan="11" style="text-align: right;">{{retorno.nome | uppercase}} - {{retorno.documento | cpf}}</td>
                                  </tr>
                                  <tr>
                                    <td>{{retorno.data | date:'dd/MM/yyyy'}}<br />{{retorno.data | date:'HH:mm'}}</td>
                                    <td>{{getDescTipoTransacao(retorno.tipoOperacao)}}</td>
                                    <td>{{retorno.descricaoCidade}}</td>
                                    <td>{{retorno.descricaoSetor}}</td>
                                    <td>{{retorno.numeroVaga}}</td>
                                    <td>{{retorno.numeroRegularizacao}}</td>
                                    <td>{{retorno.placaVeiculo}}</td>
                                    <td>{{retorno.descricaoTipoMovimento}}</td>
                                    <td>{{retorno.idMovimentoRecarga}}</td>
                                    <td>{{retorno.produto}}</td>
                                    <td style="cursor: pointer;"><i class="material-icons" [hidden]="retorno.tipoCobrancaFilialID === 1" (click)="open(retorno)" >open_in_new</i></td>
                                    <!--<td></td>-->
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>Créditos</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                          <div class="card-body scroll-y h-250 p-0">
                            <div class="card-body table-responsive">
                              <table class="table table-hover table-striped" style="font-size: 10px !important;">
                                <tbody *ngFor="let retorno of retornoExtratoCredito">
                                    <tr [hidden]="hide(retorno.documento)">
                                      <td colspan="11" style="text-align: right;">{{retorno.nome | uppercase}} - {{retorno.documento | cpf}}</td>
                                    </tr>
                                    <tr>
                                      <td>{{retorno.data | date:'dd/MM/yyyy'}}<br />{{retorno.data | date:'HH:mm'}}</td>
                                      <td>{{getDescTipoTransacao(retorno.tipoOperacao)}}</td>
                                      <td>{{retorno.descricaoCidade}}</td>
                                      <td>{{retorno.descricaoSetor}}</td>
                                      <td>{{retorno.numeroVaga}}</td>
                                      <td>{{retorno.numeroRegularizacao}}</td>
                                      <td>{{retorno.placaVeiculo}}</td>
                                      <td>{{retorno.descricaoTipoMovimento}}</td>
                                      <td>{{retorno.idMovimentoRecarga}}</td>
                                      <td>{{retorno.produto}}</td>
                                      <td style="cursor: pointer;"><i class="material-icons" [hidden]="retorno.tipoCobrancaFilialID === 1" (click)="open(retorno)" >open_in_new</i></td>
                                      <!--<td></td>-->
                                    </tr>
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                      <a ngbNavLink>Utilização</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                          <div class="card-body scroll-y h-250 p-0">
                            <div class="card-body table-responsive">
                              <table class="table table-hover table-striped" style="font-size: 10px !important;">
                                <tbody *ngFor="let retorno of retornoExtratoUtilizacao">
                                    <tr [hidden]="hide(retorno.documento)">
                                      <td colspan="11" style="text-align: right;">{{retorno.nome | uppercase}} - {{retorno.documento | cpf}}</td>
                                    </tr>
                                    <tr>
                                      <td>{{retorno.data | date:'dd/MM/yyyy'}}<br />{{retorno.data | date:'HH:mm'}}</td>
                                      <td>{{getDescTipoTransacao(retorno.tipoOperacao)}}</td>
                                      <td>{{retorno.descricaoCidade}}</td>
                                      <td>{{retorno.descricaoSetor}}</td>
                                      <td>{{retorno.numeroVaga}}</td>
                                      <td>{{retorno.numeroRegularizacao}}</td>
                                      <td>{{retorno.placaVeiculo}}</td>
                                      <td>{{retorno.descricaoTipoMovimento}}</td>
                                      <td>{{retorno.idMovimentoRecarga}}</td>
                                      <td>{{retorno.produto}}</td>
                                      <td style="cursor: pointer;"><i class="material-icons" style="font-size: 20px;" [hidden]="retorno.tipoCobrancaFilialID === 1" (click)="open(retorno)" >open_in_new</i></td>
                                      <!--<td></td>-->
                                    </tr>
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                  </ul>
                  
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>

            </div>
        </div>
    </div>
</div>