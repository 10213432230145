import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RelatorioFrotistaRequest } from 'src/app/model/relatorio-frotista-request';

@Injectable()
export class ExtratoService extends BaseService {
    constructor(private http: HttpClient) { super() }

    movimentacaoExtratoEvent: EventEmitter<void> = new EventEmitter();

    atualizarExtrato = () => this.movimentacaoExtratoEvent.emit();

    getExtratoPeriodo(documento: string, dataInicio: string, dataFim: string, isFrotista: boolean) : Observable<any>{
        
        const tokenGetExtrato = this.isFrotistaAdminLogado() ? 
                              this.obterTokenAdminFrotista() :
                              this.obterTokenUsuario();
        
        return this.http.get(`${this.UrlClienteFull}clientes/v1/${documento}/extrato?dataInicio=${dataInicio}&dataFim=${dataFim}&isFrotista=${isFrotista}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': tokenGetExtrato, 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        });
    }

    getExtratoDetalhe(documento: string, idMovimento: string) : Observable<any> {
        const tokenGetExtrato = this.isFrotistaAdminLogado() ? 
                              this.obterTokenAdminFrotista() :
                              this.obterTokenUsuario();
        return this.http.get(this.UrlClienteFull + "clientes/v1/" + documento + "/extratoDetalhes?idMovimento=" + idMovimento, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': tokenGetExtrato, 'Applicationid': environment.BrokerApplicationIdPortalCliente})
        });
    }   
}