import { Component, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ClienteService } from '../../service/cliente.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NgbActiveModal, NgbModalConfig, NgbModal, NgbDateParserFormatter, NgbDatepickerI18n, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { EstadosService } from '../../service/estados.service';
import { MunicipiosService } from '../../service/municipios.service';
import { map } from 'rxjs/operators';
import { empty } from 'rxjs';
import { ParamCliente } from 'src/app/model/DTO/paramCliente';
import { Cliente } from 'src/app/model/cliente';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientePJUpdate } from 'src/app/model/clientePJ';
import { ParamClientePJUpdate } from 'src/app/model/DTO/paramClientePJ';
import { CadastroCompletoSenhaComponent } from './cadastro-completo-senha.component';
import { CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '../datePickerCustom/datepicker-i18n';
import { padLeft } from '../../util/string.utils';

@Component({
    selector: 'app-cadastro-completo-cliente-pf',
    templateUrl: './cadastro-completo-cliente-pf.component.html',
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  })
  
  export class CadastroCompletoClientePFComponent {
  
    clienteForm: FormGroup;
    dadosAtualizados: EventEmitter<Cliente> = new EventEmitter();
   
    clienteCadastro: Cliente;
    clienteCadastroPJ: ClientePJUpdate;
    clientePost: ParamCliente = new ParamCliente();
    clientePostPJ: ParamClientePJUpdate = new ParamClientePJUpdate();

    estados: any[];
    cidadesBrasil: any[];
    cidadesUF: any[];

    exibePF: boolean = true;

    public mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    emailCadastrado: any;
    
    constructor(
      private spinner: NgxSpinnerService,
      config: NgbModalConfig,
      private modalService: NgbModal,
      public activeModal: NgbActiveModal,
      toastr: ToastrService,
      private translate: TranslateService,
      private clienteService: ClienteService,
      private estadosService: EstadosService,
      private municipiosService: MunicipiosService,
      private fb: FormBuilder){

        config.backdrop = 'static';
        config.keyboard = false;

        //this.spinner.show();

        this.estadosService.getEstados()
          .subscribe(
            result => {
              this.estados = result.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            },
            fail => { }
          )

          this.obterCliente(this.clienteService.obterUsuario().documento);
    }

    salvarEdicaoCadastro(){     

      if(!this._validarFormularioCadastro()) return; 

      if(this.emailCadastrado !== this.clienteForm.value.email){
        this.enviarEmailConfirmacao();
      }
      else
        this.salvar();   
    }

    salvar(){ 
      
        //this.spinner.show();
            this.clienteForm.patchValue({
                dataNascimento: this.getDatePost(this.clienteForm.get('dataNascimento').value), //(this.clienteForm.get('dataNascimento').value !== undefined && this.clienteForm.get('dataNascimento').value !== null) ? this.onlyNumber(this.clienteForm.get('dataNascimento').value) : '',
                documento: {
                    numero: this.onlyNumber(this.clienteForm.get('documento.numero').value),
                    tipo: (this.clienteForm.get('documento.numero').value.length === 14) ? "CNPJ" : ((this.clienteForm.get('documento.numero').value.length === 11) ? "CPF" : "PASSAPORTE")
                },
                endereco: {
                    cep: this.onlyNumber(this.clienteForm.get('endereco.cep').value)
                }
            });
            
            if(this.clienteForm.get('documento.numero').value.length === 14){

              this.clientePostPJ.cliente = JSON.parse(JSON.stringify(Object.assign({}, this.clienteCadastroPJ, this.clienteForm.value)));
  
              this.clientePostPJ.cliente.endereco.pais = "BRA";             

              this.clienteService.putClientePJ(this.clienteService.obterUsuario().documento, this.clientePostPJ).subscribe(
                result => {
                  //this.spinner.hide();
                  Swal.fire({
                    text: result.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'success',                 
                    confirmButtonColor: '#4a9f42'
                  }).then((result) => {
                    if(result.value) {
                      this.activeModal.dismiss();                     
                      let clienteAtualizado = new Cliente(  
                        this.clienteService.obterUsuario().documento,
                        this.clientePostPJ.cliente.nomeFantasia,
                        this.clientePost.cliente.sobrenome,
                        this.clientePost.cliente.email,
                        this.clienteForm.value.dataNascimento,
                        this.clientePostPJ.cliente.razaoSocial,
                        this.clientePostPJ.cliente.nomeFantasia);                    
                      this.dadosAtualizados.emit(clienteAtualizado);
                    }
                  });

                },
                fail => {
                 
                  //this.spinner.hide();
                  Swal.fire({
                    text: fail.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                  })
                  this.activeModal.dismiss();
                }
            );
            } else{

              this.clientePost.cliente = JSON.parse(JSON.stringify(Object.assign({}, this.clienteCadastro, this.clienteForm.value)));
  
              this.clientePost.cliente.endereco.pais = "BRA";

              this.clienteService.putClientePF(this.clientePost.cliente.documento.numero, this.clientePost).subscribe(
                result => {
                  //this.spinner.hide();
                  Swal.fire({
                    text: result.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'success',                 
                    confirmButtonColor: '#4a9f42'
                  }).then((result) => {
                    if(result.value) {
                      this.activeModal.dismiss();
                      let clienteAtualizado = new Cliente(  
                        this.clientePost.cliente.documento.numero,
                        this.clientePost.cliente.nome,
                        this.clientePost.cliente.sobrenome,
                        this.clientePost.cliente.email,
                        this.clienteForm.value.dataNascimento
                        );
                      
                      this.dadosAtualizados.emit(clienteAtualizado);
                    }
                  });

                },
                fail => {
                 
                  //this.spinner.hide();
                  Swal.fire({
                    text: fail.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                  });                 
                  this.activeModal.dismiss();
                }
            );
            }       
    }

    obterCliente(documento: string){
      
      if(documento.length === 11 || documento.length === 8) {
        if(documento.length === 11){
          this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
        } else if(documento.length === 8){
          this.mask = [/[A-Za-z]/, /[A-Za-z]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        }
        
        this.exibePF = true;

        this.clienteForm = this.fb.group({
          nome: ['', [Validators.required]],
          sobrenome: ['', [Validators.required]],
          razaoSocial: ['', []],
          nomeFantasia: ['', []],
          dataNascimento: [null, [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          documento: new FormGroup({
              numero: new FormControl('', { validators: [Validators.required], updateOn: 'submit' } ),
              tipo: new FormControl('', [])
          }, { validators: [Validators.required], updateOn: 'submit' } ),
          endereco: new FormGroup({
            cep: new FormControl('', [Validators.required]),
            logradouro: new FormControl('', [Validators.required]),
            numeroLogradouro: new FormControl('', [Validators.required]),
            complemento: new FormControl('', []),
            bairro: new FormControl('', []),
            estado: new FormControl('', [Validators.required]),
            cidade: new FormControl('', [Validators.required])
          })
        });

        this.clienteService.getClientePF(this.clienteService.obterUsuario().documento)
        .subscribe(
          result => {
          
            this.municipiosService.getMunicipios()
              .subscribe(
                retorno => {
               
                  this.cidadesBrasil = retorno;
                  this.emailCadastrado = result.cliente.email;

                  this.clienteForm.patchValue({
                    nome: result.cliente.nome,
                    sobrenome: result.cliente.sobrenome,
                    dataNascimento: {
                      year: parseInt(result?.cliente?.dataNascimento?.substr(0,4)),
                      month: parseInt(result?.cliente?.dataNascimento?.substr(4,2)),
                      day: parseInt(result?.cliente?.dataNascimento?.substr(6,2))  
                    },
                    email: result.cliente.email,
                    documento: {
                      numero: result.cliente.documento.numero,
                      tipo: result.cliente.documento.tipo
                    },
                    endereco: {
                      cep: result.cliente?.endereco?.cep,
                      logradouro: result.cliente?.endereco?.logradouro,
                      numeroLogradouro: result.cliente?.endereco?.numeroLogradouro,
                      complemento: result.cliente?.endereco?.complemento,
                      bairro: result.cliente?.endereco?.bairro,
                      estado: result.cliente?.endereco?.estado,
                      cidade: result.cliente?.endereco?.cidade
                    }
                  });

                  this.clienteForm.get('endereco.estado').valueChanges
                          .pipe(
                              map(estado => this.estados.filter(e => e.sigla === estado)),
                              map(estados => estados && estados.length > 0 ? estados[0].sigla : empty())
                          ).subscribe((sigla: string) => this.listaCidadeUF(sigla));

                      this.listaCidadeUF(result.cliente?.endereco?.estado);
                      //this.spinner.hide();
                },
                fail => {
             
                  //this.spinner.hide();
                }
              );
          },
          fail => {
          
            //this.spinner.hide();
          }
        );
      } else if(documento.length === 14) {
        this.mask = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
        this.exibePF = false;

        this.clienteForm = this.fb.group({
          nome: ['', []],
          sobrenome: ['', []],
          razaoSocial: ['', [Validators.required]],
          nomeFantasia: ['', [Validators.required]],
          dataNascimento: ['', []],
          email: ['', [Validators.required, Validators.email]],
          documento: new FormGroup({
              numero: new FormControl('', [Validators.required]),
              tipo: new FormControl('', [])
          }),
          endereco: new FormGroup({
            cep: new FormControl('', [Validators.required]),
            logradouro: new FormControl('', [Validators.required]),
            numeroLogradouro: new FormControl('', [Validators.required]),
            complemento: new FormControl('', []),
            bairro: new FormControl('', []),
            estado: new FormControl('', [Validators.required]),
            cidade: new FormControl('', [Validators.required])
          })
        });

        this.clienteService.getClientePJ(this.clienteService.obterUsuario().documento)
        .subscribe(
          result => {
            this.municipiosService.getMunicipios()
              .subscribe(
                retorno => {
                  this.cidadesBrasil = retorno; 
                  this.emailCadastrado = result.cliente.email;

                  this.clienteForm.patchValue({
                    razaoSocial: result.cliente.razaoSocial,
                    nomeFantasia: result.cliente.nomeFantasia,
                    dataNascimento: result.cliente.dataNascimento,
                    email: result.cliente.email,
                    documento: {
                      numero: result.cliente.documento.numero,
                      tipo: result.cliente.documento.tipo
                    },
                    endereco: {
                      cep: result.cliente?.endereco?.cep,
                      logradouro: result.cliente?.endereco?.logradouro,
                      numeroLogradouro: result.cliente?.endereco?.numeroLogradouro,
                      complemento: result.cliente?.endereco?.complemento,
                      bairro: result.cliente?.endereco?.bairro,
                      estado: result.cliente?.endereco?.estado,
                      cidade: result.cliente?.endereco?.cidade
                    }
                  });

                  // this.form.get('title').clearValidators();

                  this.clienteForm.get('endereco.estado').valueChanges
                          .pipe(
                              map(estado => this.estados.filter(e => e.sigla === estado)),
                              map(estados => estados && estados.length > 0 ? estados[0].sigla : empty())
                          ).subscribe((sigla: string) => this.listaCidadeUF(sigla));

                      this.listaCidadeUF(result.cliente?.endereco?.estado);
                      //this.spinner.hide();
                },
                fail => {
                  //this.spinner.hide();
                }
              );
          },
          fail => {
            //this.spinner.hide();
          }
        );
      }
    }

    keyup(event) {
      if (this.clienteForm.get('documento.numero').value.replace(/\D/g, '').length >= 11 && (event.keyCode >= 48 && event.keyCode <= 57)) {
          this.mask = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      } else {
          this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      }
  }

  onPaste(event: ClipboardEvent)
  {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');

      let numberCartao = this.onlyNumber(pastedText);

      if (numberCartao.replace(/\D/g, '').length > 11) {
          this.mask = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      } else {
          this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      }
  }

  onlyNumber(param: string) {
      return param?.replace(/\D/g, '');
  }
  
  listaCidadeUF(siglaUF: string) {
      this.cidadesUF = this.cidadesBrasil.filter(e => e.microrregiao.mesorregiao.UF.sigla == siglaUF).sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
  }

  consultaCEP() {
    //this.spinner.show();
    this.clienteService.obterCEP(this.onlyNumber(this.clienteForm.get('endereco.cep').value)).subscribe(
        resultado => {
            //this.spinner.hide();
            this.preencheFormularioEndereco(resultado);
        },
        fail => {
          //this.spinner.hide();
        });
}

preencheFormularioEndereco(dados) {
  this.clienteForm.patchValue({
      endereco: {
          cep: this.onlyNumber(this.clienteForm.get('endereco.cep').value),
          logradouro: dados.logradouro,
          numero: "",
          complemento: "",
          bairro: dados.bairro,
          cidade: dados.localidade,
          estado: dados.uf,
          pais: "BR"
      }
  });
}

validarDataFutura() : boolean {
  const dataNascimento = this.clienteForm.get('dataNascimento').value;
  return new Date() >= new Date(dataNascimento.year, dataNascimento.month - 1, dataNascimento.day);
}

getDatePost = (data: any) => !data ? null : `${data.year}${padLeft(data.month, 2)}${padLeft(data.day, 2)}`;

openPassword() {
  const modalRef = this.modalService.open(CadastroCompletoSenhaComponent, { centered: true, size: 'sm'});
  modalRef.componentInstance.areaLogada = true;
}

    private _validarFormularioCadastro(){
      if(!this.clienteForm.valid){
        Swal.fire({
                  text: this.translate.instant('RequiredFields'),
                  titleText: this.translate.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });      
        return false;;
      }
      return true;
    }

    enviarEmailConfirmacao(){

      if(!this._validarFormularioCadastro()) return;

      //this.spinner.show()
          this.clienteService.enviarConfirmacaoEmail(this.clienteForm.value.email)
              .subscribe(
                  resultado => { 
                      //this.spinner.hide();
                      if(!resultado?.necessitaCodigoEmail){
                        this.salvar();
                        return;
                      }                          

                      if(resultado?.emailEnviado === true
                          &&  !resultado.hasOwnProperty("jaCadastrado")){
                              this.confirmarCodigoEmail();
                              return;
                          }                               

                      if(resultado.hasOwnProperty("jaCadastrado") && resultado?.jaCadastrado === true)
                          Swal.fire({
                              text: this.translate.instant('EmailExists'),
                              titleText: this.translate.instant('AlertInfo'),
                              icon: 'info',                 
                              confirmButtonColor: '#4a9f42',
                              width: 'auto'
                          });      
                  },
                  () => {
                      //this.spinner.hide();
                  }
              );          
    }

    confirmarCodigoEmail(){
      Swal.fire({
          titleText: this.translate.instant('AlertInfo'),
          text: this.translate.instant('MsgConfirmEmailCodeToRegister'),
          input: 'text',
          inputAttributes: {
              autocapitalize: 'on'
          },
          showCancelButton: true,
          cancelButtonText: this.translate.instant('Cancel'),
          confirmButtonText: this.translate.instant('Confirm'),
          showLoaderOnConfirm: true,
          preConfirm: (code) => {     
              this.clienteService.confirmarCodigoEmail(this.clienteForm.value.email, code) 
                  .subscribe(
                      () => {                                
                          this.salvar();
                          return "OK";                           
                      },
                      error => {
                          return "ERROR";
                      }                          
                  );
              return "OK";
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then( () => {});
    }

}