export class ParamMovimentacaoFrotistaAdmin {
    public Documento: string;
    public DataInicial: string;
    public DataFinal: string;
    public FilialID: string;
    public TipoRelatorio: number;
    public TipoOperacao: number;
    public Frotista: boolean;
    public CpfFrotista: string;
    public Placa: string;
    public TipoGasto: number;
    public DocumentoEmpresa: string;
    public CentroCusto: string;
    public IdProdutoMovimentacao: number;
    public PageNumber: number;
    public PageSize: number;
}