import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";

import { BaseService } from '../shared/service/base.service';
import { Login } from '../model/login';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

@Injectable()
export class LoginService extends BaseService {
    constructor(private http: HttpClient) {
        super()
    }

    login(document: string, password: string) {
        let param = new Login();

        param.senha = password;
        param.appData = { "bundleName": "br.com.estapar.sp.site" };

        var hash = super.getHashStorageDevice(document);
        if (hash != null) {
            param.hashStorageDevice = hash;
        }

        param.deviceUUID = super.getDeviceUUID();
        if (param.deviceUUID == null)
            super.setDeviceUUID(uuid.v4());

        param.deviceUUID = super.getDeviceUUID();

        param.idAplicacao = parseInt(environment.BrokerApplicationIdPortalCliente);

        param.sistemaOperacional = navigator.userAgent + "/" + navigator.platform;;

        return this.http
            .post(this.UrlClienteFull + "autenticacao/v1/" + document, JSON.stringify(param))
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    validToken(token: string) {
        return this.http
            .get(this.UrlClienteFull + "autenticacao/v1/validtoken/" + token)
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

    persistirUserApp(response: any, comercioEspecial: boolean) {

        localStorage.setItem('app.comercioEspecial', comercioEspecial.toString());
        localStorage.setItem('app.frotista', response.usuario.frotista);
        localStorage.setItem('app.adminFrotista', response.usuario.adminFrotista);
        localStorage.setItem('app.ativo', response.usuario.ativo);

        super.setSelectFrotista(response.usuario.frotista);
        if (response.usuario.frotista)
            super.setCentroDeCustoFrotista(
                response?.usuario?.IDEmpresaFrotistaCentroCusto !== undefined
                    && response?.usuario?.IDEmpresaFrotistaCentroCusto !== null
                    && response?.usuario?.IDEmpresaFrotistaCentroCusto !== ''
                    && response?.usuario?.IDEmpresaFrotistaCentroCusto !== ' ' ?
                    response?.usuario?.IDEmpresaFrotistaCentroCusto :
                    response?.usuarioAdmin?.IDEmpresaFrotistaCentroCusto);
        else {
            localStorage.setItem('app.user', JSON.stringify(response.usuario));
            localStorage.setItem('app.token', response.usuario.token);
        }
    }
}