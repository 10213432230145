<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'Buy CAD' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelar()" style="color: #fff; opacity: 1;">
        <span aria-hidden="true" style="font-size: 2rem;">&times;</span>
    </button>
</div>
    <div class="modal-body">
       <div class="container" style="padding: 0px;">
            <div class="row">
                <div class="col-12 mb-4">
                     <ul ngbNav #nav01="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav nav-pills nav-fill">
                       <li [ngbNavItem]="1" [disabled]="disable(1)">
                            <a ngbNavLink>CADs</a>
                            <ng-template ngbNavContent>
                                <!-- <div class="list-group-item status-border border-warning" style="cursor: pointer;">
                                    <div class="row">
                                        <div class="col-auto align-self-center">
                                            <i class="material-icons">pin_drop</i>
                                        </div>
                                        <div class="col pl-0" style="margin: auto !important;">
                                            <p style="margin: 0px !important;">
                                                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorCADDigitado" placeholder="{{ 'EnterTheQuantity' | translate }}" numbersOnly/>
                                            </p>
                                        </div>
                                        <div class="col-auto pr-0 align-self-center" (click)="prox(2, valorCADDigitado)">
                                            <button class="btn btn-sm"><i class="material-icons">navigate_next</i></button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="list-group roudned-0">
                                    <div *ngFor="let cad of quantidadesCADs" class="list-group-item status-border border-warning" (click)="prox(2, cad)" style="cursor: pointer;">
                                        <div class="row">
                                            <div class="col-auto align-self-center">
                                                <i class="material-icons">pin_drop</i>
                                            </div>
                                            <div class="col pl-0" style="margin: auto !important;">
                                                <p style="margin: 0px !important;">{{cad.exibicao }} CADs
                                                    <!-- <span style="margin-left: 10px;font-weight: bold;">{{cad * 5.0 | currency:'BRL' }}</span> -->
                                                </p>
                                            </div>
                                            <div class="col-auto pr-0 align-self-center">
                                                <span style="margin-left: 10px;font-weight: bold;">{{cad.valor | currency:'BRL' }}</span>
                                                <button class="btn btn-sm"><i class="material-icons">navigate_next</i></button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </ng-template>
                        </li>
    
                         <li [ngbNavItem]="2" [disabled]="disable(2)">
                            <a ngbNavLink>{{ 'PaymentMethod' | translate }}</a>
                            <ng-template ngbNavContent>
                                <div class="content">
                                    <div class="formaPagamentoSelecionadaDados">
                                        <div class="row">
                                            <div class="col-12">
                                                {{valorCADSelecionado.exibicao}} CADs <span style="margin-left: 10px;font-weight: bold;">{{valorCADSelecionado.valor | currency:'BRL'}}</span>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-2"><i class="material-icons">credit_card</i></div>
                                                    <div class="col-4">{{cartao.bandeira}}</div>
                                                    <div class="col-4" style="text-align: right;">{{cartao.ultimosDigitos}}</div>
                                                    <div class="col-2">
                                                        <button class="btn btn-sm" (click)="openFormaPgto()"><i class="material-icons">edit</i></button>                                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top: 20px;" class="float-right">
                                        <button style="margin: 4px;" class="btn btn-sm btn-light" (click)="nav01.select(1)">{{'Previous' | translate}}</button>
                                        <button class="btn btn-sm btn-next" (click)="nav01.select(3)">{{'Next' | translate}}</button>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
    
                        <li [ngbNavItem]="3" [disabled]="disable(3)">
                            <a ngbNavLink>{{'Confirmation' | translate}}</a>
                            <ng-template ngbNavContent>
                                <div class="resumoConfirmacaoPagamento">
                                    <div class="dados">
                                        <h3 style="margin-bottom: 0px;">
                                            <span style="font-weight: bold;">{{valorCADSelecionado.exibicao}} CADs:</span> 
                                            {{valorCADSelecionado.valor | currency:'BRL'}}
                                        </h3>                                           
                                    </div>                                                                  
                                </div>
                                <div style="margin-top: 20px;" class="float-right">
                                    <button style="margin: 4px;" class="btn btn-light btn-sm" (click)="nav01.select(2)">Anterior</button>
                                    <button class="btn btn-next btn-sm" (click)="confirmarCAD()">Confirmar</button>
                                </div>

                                <!-- <p>
                                    {{valorCADSelecionado}} CADs <span style="margin-left: 10px;font-weight: bold;">{{valorCADSelecionado * 5.0 | currency:'BRL'}}</span>
                                </p>
                                
                                <button class="btn btn-sm btn-outline-primary ml-2" (click)="nav01.select(2)">Anterior</button>
                                        <button class="btn btn-sm btn-outline-primary" (click)="confirmarCAD()">Confirmar</button> -->
                            </ng-template>
                        </li>
                    </ul>
                    
                    <div [ngbNavOutlet]="nav01" class="mt-2"></div>
    
                </div>
            </div>
        </div>
    </div>