import { Component, Output, EventEmitter, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbActiveModal, NgbNavChangeEvent, NgbDateParserFormatter, NgbDatepickerI18n, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CartaoService } from '../../service/cartao.service';
import { ClienteService } from '../../service/cliente.service';
import { Cartao, CartaoRetorno, CartaoValidacaoBinRetorno } from 'src/app/model/cartao';
import { Compra, CompraFrotista } from 'src/app/model/compra';
import { CreditoService } from '../../service/credito.service';
import Swal from 'sweetalert2';
import { CartaoListaOpcaoComponent } from '../cartao/cartao-lista-opcao.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BoletoDetalhe, BoletoParam } from 'src/app/model/boleto';
import { environment } from 'src/environments/environment';
import { I18n, CustomDatepickerI18n, CustomDateParserFormatter } from '../datePickerCustom/datepicker-i18n';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
import { FiliaisService } from '../../service/filiais.service';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: "app-compra-credito",
  templateUrl: "compra-credito.component.html",
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: ReCaptchaV3Service }]
})

export class CompraCreditoComponent implements OnInit {

  @ViewChild("numero") nameFieldNumeroCartao: ElementRef;

  active;
  disabledTab = true;
  currentJustify = 'fill';
  valorSelecionado: number = 0;
  valorDigitado: number;
  formaPgto: string;
  dataMinimaPagBoleto: NgbDateStruct;
  public validandoCard: boolean;
  private numeroCartaoElement: any;

  public cssBandeira: string = "icon-bank-cards-white.png";
  public exibeIcon: boolean;

  boletoDetalhe: BoletoDetalhe;
  boletoParam: BoletoParam;

  valoresCredito: number[];

  public maskMonth = [/[0-1]/, /[0-9]/];
  public maskYear = [/[0-9]/, /[0-9]/];

  cartoes: CartaoRetorno[] = [];
  cartao: CartaoRetorno;
  cartaoNovo: CartaoValidacaoBinRetorno = null;
  cartaoForm: FormGroup;
  boletoForm: FormGroup;

  private guidPagamento = uuid.v4();
  public tokenRecaptcha: string;

  @Output()
  compraFinalizada: EventEmitter<any> = new EventEmitter();

  loadAPI: Promise<any>;
  msgValorValidacao: string;
  mask: RegExp[];

  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private cartaoService: CartaoService,
    private clienteService: ClienteService,
    private creditoService: CreditoService,
    private translate: TranslateService,
    private filiaisService: FiliaisService,
    private el: ElementRef,
    private recaptchaV3Service: ReCaptchaV3Service) {

    //TODO refatorar...
    this.filiaisService.getOpcoesCompras().subscribe((res: any) => {
      this.valoresCredito = res.creditos;

      cartaoService.getCartoes(clienteService.obterUsuario().documento).subscribe(
        result => {

          //this.spinner.hide();
          if (!this.clienteService.getComercioEspecial())
            this.cartoes = result;

          if (this.cartoes.length === 0 && !this.clienteService.getComercioEspecial())
            this.cartoes.push({
              DataUltimaAtualizacao: new Date(),
              bandeira: 'Credit Card',
              portador: "",
              ultimosDigitos: "",
              uuidCartaoEstapar: "",
              IsSelecionado: false
            })

          this.cartoes.push({
            DataUltimaAtualizacao: new Date(),
            bandeira: "BOLETO",
            portador: "",
            ultimosDigitos: "BOLETO",
            uuidCartaoEstapar: "",
            IsSelecionado: false
          })

          this.selectCard(this.cartoes.find(x => x.uuidCartaoEstapar !== "" && x.uuidCartaoEstapar !== " "));
          //  
        },
        error => {
          Swal.fire({
            text: error.message,
            titleText: this.translate.instant('RechargeEwallet'),
            icon: 'info',
            confirmButtonColor: '#4a9f42'
          }).then(() => {
            //window.location.href = window.location.href
            this.activeModal.close();
          })
        });
    },
      error => {
        Swal.fire({
          text: error.message,
          titleText: this.translate.instant('RechargeEwallet'),
          icon: 'info',
          confirmButtonColor: '#4a9f42'
        }).then(() => {
          this.activeModal.close();
        })
      }
    );

    config.backdrop = 'static';
    config.keyboard = false;

    this.tokenRecaptcha = '';

    const dataMinimaBoleto = new Date();
    dataMinimaBoleto.setDate(dataMinimaBoleto.getDate() + 1);
    this.dataMinimaPagBoleto = new NgbDate(dataMinimaBoleto.getFullYear(), dataMinimaBoleto.getMonth() + 1, dataMinimaBoleto.getDate());

    this.cartaoForm = this.fb.group({
      numero: ['', [Validators.required, Validators.minLength(10)]],
      portador: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
      mesExpiracao: ['', [Validators.required, Validators.max(12)]],
      anoExpiracao: ['', [Validators.required]],
      bandeira: ['', [Validators.required]],
      lembrar: [true, [Validators.required]],
      documentoTitular: ['', [Validators.required]]
    });

    this.boletoForm = this.fb.group({
      dataVencimentoBoleto: [this.dataMinimaPagBoleto, [Validators.required]]
    })

    //this.spinner.show();

  }

  ngOnInit() {
    this.loadAPI = new Promise(() => {
      this.loadScriptGetDevice();
    });
  }

  get ultimosDigitos() {
    if (this.formaPgto === 'novoCartao')
      return this.cartaoForm.value.numero.substr(this.cartaoForm.value.numero.length - 4);
    else return this.cartao.ultimosDigitos;
  }

  get descricaoCartaoPagamento() {
    if (this.formaPgto === 'novoCartao')
      return this.cartaoNovo.bandeira;
    else return this.cartao.bandeira;
  }

  get dataVencimentoBoletoFormatada() {
    const data = this.boletoForm.get('dataVencimentoBoleto').value;
    return `${data.day}/${data.month}/${data.year}`;
  }

  validateDataMinima() {
    const dataHoje = new Date();
    const dataBoleto = this.boletoForm.get('dataVencimentoBoleto').value;

    if (new Date(dataBoleto.year, dataBoleto.month - 1, dataBoleto.day) < new Date(dataHoje.getFullYear(), dataHoje.getMonth(), dataHoje.getDate(), 0, 0, 0)) {
      Swal.fire({
        text: 'Não é permitido uma data menor que hoje para vencimento do Boleto',
        titleText: 'Compra de Crédito via Boleto',
        icon: 'info',
        confirmButtonColor: '#4a9f42'
      });
      this.boletoForm.patchValue({ "dataVencimentoBoleto": this.dataMinimaPagBoleto });
      return false;
    }
    return true;
  }

  dateMask(event: any) {
    var v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

  get dataMinimaBoleto() {
    const dataMinimaBoleto = new Date();
    dataMinimaBoleto.setDate(dataMinimaBoleto.getDate() + 1);
    return { year: dataMinimaBoleto.getFullYear(), month: dataMinimaBoleto.getMonth(), day: dataMinimaBoleto.getDay() }
  }

  getIconeFormaPagamento(bandeira: string): string {
    switch (bandeira.toUpperCase()) {
      case 'VISA':
      case 'VISA ELECTRON':
        return 'assets/images/logo/icon-visa.png';
      case 'MASTER':
      case 'MASTERCARD':
      case 'MAESTRO':
        return 'assets/images/logo/icon-mastercard.png';
      case 'AMEX':
        return 'assets/images/logo/icon-mastercard.png';
      case 'AMEX':
        return 'assets/images/logo/icon-american-black.png';
      case 'ELO':
        return 'assets/images/logo/bandeira-elo.png';
      case 'BOLETO':
        return 'assets/images/logo/icon-boleto.png';
      default: return 'assets/images/logo/icon-bank-cards-white.png';
    }
  }

  texto(param: string): string {
    return param.replace(/\s/g, "");;
  }

  sortCards() {
    let cards = document.querySelectorAll(".account:not(.is-selected)");

    let index = 1;
    let paramTop = 30;

    for (let i = 0; i < cards.length; i++) {
      let a = document.getElementById(cards[i].id);

      a.style.top = paramTop + 'px';

      if (cards.length === 2) {
        paramTop = paramTop + 150;
      } else {
        paramTop = (180 / (cards.length - 1)) * (index + 1);

        index++;
      }
    }
  }

  selectCard(param) {

    if (this.cartoes.length === 1) {
      this.cartao = this.cartoes[0];
      this.cartao.IsSelecionado = true;
      return;
    }

    this.cartoes.forEach(c => c.IsSelecionado = false);

    this.cartao = this.cartoes
      .find(x => x.ultimosDigitos === (param === undefined || param?.ultimosDigitos === undefined
        || param?.ultimosDigitos === null
        || param?.ultimosDigitos === "" ? "" : param?.ultimosDigitos));


    this.cartao.IsSelecionado = true;

  }

  confirmar() {

    const compra = this._criarRequestCompra();
    this.recaptchaV3Service.execute('CompraCredito').subscribe((token: string): void => {
      this.tokenRecaptcha = token;

      this
        .creditoService
        .comprarCredito(
          this.clienteService.obterUsuario().documento,
          compra, this.tokenRecaptcha)
        .subscribe(
          result => {
            //this.spinner.hide();
            this.activeModal.close();
            this.compraFinalizada.emit();
            this.clienteService.atualizarSaldo();

            Swal.fire({
              text: result.message,
              titleText: this.translate.instant('RechargeEwallet'),
              icon: 'success',
              confirmButtonColor: '#4a9f42'
            }).then(() => {
              //window.location.href = window.location.href
              this.activeModal.close();
            });
          },
          error => {
            //this.spinner.hide();
            Swal.fire({
              text: error.message,
              titleText: this.translate.instant('RechargeEwallet'),
              icon: 'info',
              confirmButtonColor: '#4a9f42'
            })
            //this.activeModal.close();
          }
        );
      }); 
    }    

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  confirmarBoleto() {

    const tipoUsuario = this.clienteService.isFrotista()
      && this.clienteService.getPerfilUsuario() === 'frotista' ? "frotista" : "comum";

    if (tipoUsuario === "frotista") {

      const documentoGetSaldo = this.clienteService.isFrotistaAdminLogado() ?
        this.clienteService.obterDocumentoOriginalAdminFrotista() :
        this.clienteService.obterUsuario().documento;

      this
        .clienteService
        .getSaldos(
          documentoGetSaldo,
          tipoUsuario)
        .subscribe(
          result => {
            this.GerarBoleto(result?.saldos[0]?.centroCusto);
          },
          fail => {

          }
        );
    }
    else {
      this.GerarBoleto();
    }
  }

  private GerarBoleto(centroDeCusto: string = "") {

    const dataBoleto = this.boletoForm.get('dataVencimentoBoleto').value;

    let documento = this.creditoService.obterUsuario().documento;
    this.boletoDetalhe = {
      documento: documento,
      valor: this.valorSelecionado,
      desconto: 0.00,
      tipo: 1,
      centrocusto: centroDeCusto,
      instituicao: environment.Instituicao,
      recno: null,
      data: `${dataBoleto.year}-${this.pad(dataBoleto.month, 2)}-${this.pad(dataBoleto.day, 2)}T00:00:00`,
      idEmpresaFrotistaCentroCusto: centroDeCusto != null && centroDeCusto != "" ? this.clienteService.getCentroDeCustoFrotista() : null,
      documentoAdminFrotista: centroDeCusto != null && centroDeCusto != "" ? this.clienteService.obterDocumentoOriginalAdminFrotista() : null
    };
    this.boletoParam = {
      params: this.boletoDetalhe
    };

    this.creditoService.comprarCreditoComBoleto(this.boletoDetalhe).subscribe(
      res => {

        const urlParamsRoute = `${documento}/${this.boletoDetalhe.tipo}/${environment.Instituicao}/${res.numeroDocumento}`;
        const urlQueryStrings = `?code=${environment.FuncCodeGetBoleto}&valor=${this.boletoDetalhe.valor}&desconto=${this.boletoDetalhe.desconto}&centrocusto=${this.boletoDetalhe.centrocusto}`;

        var fileWindow = window.open(`${environment.BaseUrlFunction}site/getboleto/v1/${urlParamsRoute}${urlQueryStrings}`, "boleto", "width=960,height=800,scrollbars=1");
        fileWindow.open();

        this.activeModal.close();
        this.compraFinalizada.emit();
      },
      fail => {
        Swal.fire({
          text: fail.message,
          titleText: 'Compra de Crédito via Boleto',
          icon: 'info',
          confirmButtonColor: '#4a9f42'
        })
          .then(() => {
            //window.location.href = window.location.href
            //this.activeModal.close();
          })
        //this.spinner.hide();            
      }
    );
  }

  onPasteDocumento(event: ClipboardEvent) {

    if (this.cartaoForm.get('documentoTitular').value.replace(/\D/g, '').length > 11) {
      this.mask = [/[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /[/]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
    } else {
      this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }

  next(selectTab: number, paramValor: number, formaPgto: string) {

    if (selectTab === 3 && formaPgto === 'novoCartao') {

      if (!this.cartaoForm.valid) {
        Swal.fire({
          text: this.translate.instant('RequiredFields'),
          titleText: this.translate.instant('AlertInfo'),
          icon: 'info',
          confirmButtonColor: '#4a9f42'
        });
        return;
      }

      if (this.cartaoForm.value.bandeira === null
        || this.cartaoForm.value.bandeira === ""
        || this.cartaoForm.value.bandeira === undefined) {
        Swal.fire({
          text: this.translate.instant('CreditCardNotSupported'),
          titleText: this.translate.instant('AlertInfo'),
          icon: 'info',
          confirmButtonColor: '#4a9f42'
        });
        return;
      }
    }

    if (selectTab === 3 && formaPgto === 'boleto') {
      if (!this._dadosBoletoValido()) return;
    }

    if (paramValor > 0) {

      let valorCad = this.valoresCredito[0]["valor"];

      //A segunda condição só é utilizada em situação onde o valor de retorno da divisão é 0.99999999999 ou algo do tipo
      //em caso de problemas teste a divisão e verifique se o mesmo gera valor igual a primeira casa mais .0 e N decimais
      if (Math.round(paramValor % valorCad) === 0 || Math.round(paramValor % valorCad) == Math.round(valorCad)) {
        this.active = selectTab;
        this.valorSelecionado = parseFloat(paramValor.toString());
        this.formaPgto = formaPgto;
        this.msgValorValidacao = "";
      }
      else {
        let msg = this.translate.instant('MultipleValueFive');

        msg = msg.replace('{valor}', valorCad.toString());

        let exemplosMsg = (1 * valorCad).toString() + ", " + (3 * valorCad).toFixed(2).toString() + ", " + (60 * valorCad).toFixed(2).toString() + ", " + (111 * valorCad).toFixed(2).toString();

        msg = msg.replace('{exemplos}', exemplosMsg.toString());

        this.msgValorValidacao = msg;
      }

    }
    else
      this.msgValorValidacao = this.translate.instant('EnterWithValue');
  }

  private _dadosBoletoValido() {
    if (!this.boletoForm.valid) {
      Swal.fire({
        text: this.translate.instant('RequiredFields'),
        titleText: this.translate.instant('AlertInfo'),
        icon: 'info',
        confirmButtonColor: '#4a9f42'
      });
      return false;
    }
    if (!this.validateDataMinima()) return false;

    return true;
  }

  toggleDisabled() {
    this.disabledTab = !this.disabledTab;
    if (this.disabledTab) {
      this.active = 1;
    }
  }

  disable(step: number) {
    return step >= this.active;
  }

  openCards() {
    const modalRef = this.modalService.open(CartaoListaOpcaoComponent, { centered: true, size: 'sm' });

    modalRef.componentInstance.cartoes = this.cartoes;

    modalRef.componentInstance.selectCartao.subscribe(
      result => {
        this.cartao = result;
      },
      () => {

      }
    );
  }

  setaBandeira(event: any) {

    if (event.target.value.replace(" ", "").length >= 6 && this.cartaoNovo === null) {

      this.validandoCard = true;
      this.cartaoService.getValidarBinCartao(event.target.value.replace(" ", "").substring(0, 6)).subscribe(res => {

        this.validandoCard = false;
        setTimeout(() => {
          this.nameFieldNumeroCartao.nativeElement.focus();
        }, 0);

        if (res.tipo === "DEBITO") {
          Swal.fire({
            text: this.translate.instant('OnlyCreditCardPayment'),
            titleText: this.translate.instant('AlertInfo'),
            icon: 'info',
            confirmButtonColor: '#4a9f42'
          });
          this.cartaoNovo = null;
          this.cartaoForm.patchValue({ "bandeira": "" });
          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
          return;
        }

        if (res.bandeira.length > 0) {
          if (res.bandeira.toLowerCase() === "visa") this.cssBandeira = "logo-visa.jpg";
          else if (res.bandeira.toLowerCase() === "maestro") this.cssBandeira = "logo-master.jpg";
          else this.cssBandeira = `logo-${res.bandeira.toLowerCase()}.jpg`;
          this.cartaoNovo = res;
          this.cartaoForm.patchValue({ "bandeira": res.idBandeira });
          return;
        }
        else {
          this.cssBandeira = "icon-bank-cards-white.png";
          this.cartaoForm.patchValue({ "bandeira": "" });
          this.cartaoNovo = null;
          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
        }
      },
        error => {
          this.validandoCard = false;
          this.el.nativeElement.querySelector('[formcontrolname="numero"]').focus();

          Swal.fire({
            text: error.message,
            titleText: this.translate.instant('RechargeEwallet'),
            icon: 'info',
            confirmButtonColor: '#4a9f42'
          });

          this.cartaoNovo = null;
          this.cssBandeira = "icon-bank-cards-white.png";
          this.cartaoForm.patchValue({ "bandeira": "" });

          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
        });
    }
    else if (event.target.value.replace(" ", "").length < 6) {
      this.cartaoNovo = null;
    }
  }

  onPaste(event: ClipboardEvent) {

    if (event.clipboardData.getData('text') != this.cartaoForm.value.numero) {
      this.cartaoNovo = null;
      this.cartaoForm.patchValue({ "bandeira": "" });
      this.cartaoForm.patchValue({ "portador": "" });
      this.cartaoForm.patchValue({ "cvv": "" });
      this.cartaoForm.patchValue({ "mesExpiracao": "" });
      this.cartaoForm.patchValue({ "anoExpiracao": "" });
      this.cartaoForm.patchValue({ "documentoTitular": "" });
    }
  }

  onlyNumber(param: string) {
    return param.replace(/\D/g, '');
  }

  private _criarRequestCompra(): any {

    if (this.clienteService.isFrotistaAdminLogado()
      && this.clienteService.getCentroDeCustoFrotista() > 0) {

      let compra = new CompraFrotista();

      compra.compra = {
        "dispositivo": {
          "fingerprint": this.guidPagamento, //"asklhjaklejsketjhioejflskdjg",
          "uuid": "iOS 13.3/x86_64#asklhjaklejsketjhioejflskdjg",
          "lat": "-11111321",
          "lng": "746543",
          "network": {
            "ip": "127.0.0.1",
            "subnet": "255.255.255.0",
            "type": "wifi"
          }
        },
        frotista: {
          IDEmpresaFrotistaCentroCusto: this.clienteService.getCentroDeCustoFrotista()
        },
        "valor": this.valorSelecionado, //parseFloat(this.valorSelecionado.toString().replace(/\./g, '').replace(/\,/g, '.')),
        "cartao": {
          "token": this.cartao.uuidCartaoEstapar !== "" && this.cartao.uuidCartaoEstapar !== undefined ? this.cartao.uuidCartaoEstapar : "",
          "nome": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.portador : "",
          "numero": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.numero : "",
          "lembrar": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.lembrar : false,
          "validade": {
            "mes": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.mesExpiracao : "",
            "ano": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.anoExpiracao : "",
          },
          "bandeira": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.bandeira : "",
          "csc": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.cvv : ""
        }
      }

      return compra;
    }
    else {
      let compra = new Compra();

      compra.compra = {
        "dispositivo": {
          "fingerprint": this.guidPagamento, //"asklhjaklejsketjhioejflskdjg",
          "uuid": "iOS 13.3/x86_64#asklhjaklejsketjhioejflskdjg",
          "lat": "-11111321",
          "lng": "746543",
          "network": {
            "ip": "127.0.0.1",
            "subnet": "255.255.255.0",
            "type": "wifi"
          }
        },
        "valor": this.valorSelecionado, //parseFloat(this.valorSelecionado.toString().replace(/\./g, '').replace(/\,/g, '.')),
        "cartao": {
          "token": this.cartao.uuidCartaoEstapar !== "" && this.cartao.uuidCartaoEstapar !== undefined ? this.cartao.uuidCartaoEstapar : "",
          "nome": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.portador : "",
          "numero": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.numero : "",
          "lembrar": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.lembrar : false,
          "validade": {
            "mes": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.mesExpiracao : "",
            "ano": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.anoExpiracao : "",
          },
          "bandeira": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.bandeira : "",
          "csc": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.cvv : "",
          "portador": this.cartao.uuidCartaoEstapar === "" || this.cartao.uuidCartaoEstapar === undefined ? this.cartaoForm.value.documentoTitular : ""
        }
      }

      return compra;
    }
  }

  public loadScriptGetDevice() {

    let node = document.createElement('script');
    node.src = environment.UrlGetDevice.replace('{sessao}', this.guidPagamento);
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('body')[0].appendChild(node);
    //document.getElementById('scriptDevice').appendChild(node); //scriptDevice
  }

  cancelar() {
    this.activeModal.close();
    //window.location.href = window.location.href;
  }

  validarCartao() {

    if (this.cartao.uuidCartaoEstapar !== "" && this.cartao.uuidCartaoEstapar !== undefined) {
      this.confirmar();
      return;
    }

    let cartao = new Cartao();

    cartao.numero = this.onlyNumber(this.cartaoForm.value.numero);
    cartao.portador = this.cartaoForm.value.portador;
    cartao.bandeira = this.cartaoForm.value.bandeira;
    cartao.cvv = this.cartaoForm.value.cvv;
    cartao.mesExpiracao = this.cartaoForm.value.mesExpiracao;
    cartao.anoExpiracao = this.cartaoForm.value.anoExpiracao;
    cartao.cpfCnpj = this.cartaoForm.value.documentoTitular;

    this.cartaoService.getValidarCartao(cartao, this.cartaoNovo.idBandeira).subscribe(res => {
      this.confirmar();
    },
      fail => {
        Swal.fire({
          text: fail.message,
          titleText: this.translate.instant('AlertInfo'),
          icon: 'info',
          confirmButtonColor: '#4a9f42'
        });
      });
  }

  verificaCartaoCampo(event) {

    if (event.target.value.replace(" ", "").length < 6) {
      this.cartaoNovo = null;
    }
  }

  get informarValorCredito(): boolean {
    return this.clienteService.funcionalidadePermitida(Funcionalidades.InformarValorCompraCredito);
  }

  get ehComercioEspecial(): boolean {
    return this.clienteService.funcionalidadePermitida(Funcionalidades.ComercioEspecial);
  }
}