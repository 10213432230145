<div class="card border-0 shadow-sm mb-2">
    <div class="col py-2 pr-0" style="border-bottom: solid 1px #4C4E51;">
        <div class="card-header border-0 bg-none" style="padding: 0px 15px;">
            <div class="row">
                <div class="col align-self-center">
                    {{'My Cars'|translate}}
                </div>
                <div class="col-auto">
                    <div class="dropdown" ngbDropdown>
                        <button [hidden]="!cadastrarVeiculo" class="btn dropdown-toggle btn-sm btn-link py-0 active" (click)="open();">
                            <i class="material-icons">add</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-300">
                <div class="card-body scroll-y scroll-x h-250 p-0">
                    <div class="list-group roudned-0">
                        <div *ngFor="let veiculo of veiculos" class="list-group-item status-border border-warning">
                            <div class="row">
                                <div class="col-auto align-self-center">
                                    <i class="material-icons">{{getIcon(veiculo.tipo)}}</i>
                                </div>
                                <div class="col pl-0 align-self-center" style="margin: auto !important;">
                                    <p style="margin: 0px !important;">{{getPlacaFormatada(veiculo.placa)}}</p>
                                </div>
                                <div class="col-auto pr-0 align-self-center">
                                    <button class="btn btn-sm" 
                                            (click)="favoritarToogle(veiculo.placa, veiculo.favorito)">
                                                <i [ngClass]=" veiculo.favorito ? 'material-icons' : 'material-icons-outlined'" 
                                                   [ngStyle]="{'color': veiculo.favorito ? 'rgb(219, 219, 21)' : 'rgb(151, 151, 140)' }">grade</i>
                                    </button>
                                    <button [disabled]="!excluirVeiculo" 
                                            [hidden]="!excluirVeiculo" 
                                            class="btn btn-sm text-danger" 
                                            (click)="deletar(veiculo.placa, veiculo.tipo)"><i class="material-icons">delete_forever</i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="list-group roudned-0">
                        <div *ngFor="let veiculo of veiculos" class="list-group-item status-border border-warning">
                            <div class="row">
                                <div class="col-1 align-self-center">
                                    <i class="material-icons">{{getIcon(veiculo.tipo)}}</i>
                                </div>
                                <div class="col-3 align-self-center" style="font-size: 11px;">
                                    <p style="margin: 0px !important;">{{veiculo.placa}}</p>
                                </div>
                                <div class="col-1 align-self-center">
                                    <button class="btn btn-sm text-danger" (click)="deletar(veiculo.placa, veiculo.tipo)"><i class="material-icons">delete_forever</i></button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>