<div class="modal-header">

    <div class="container" style="padding: 6px;">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <span style="color: #fff;">
                    {{ 'Registered vehicles' | translate }} 
                    </span>
            </div>            
        </div>
    </div>    

</div>
<div class="modal-body" style="padding-top: 0px; padding-bottom: 0px;">
    <div class="container" style="padding: 0px;">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <form class="search-form-full-width">
                    <mat-form-field class="example-full-width" appearance="legacy">
                      <input type="text"                 
                             aria-label="Number"
                             matInput
                             [formControl]="myControl"
                             [matAutocomplete]="auto" (keyup)="onKeyUp($event)" >
                             <mat-placeholder class="placeholderBuscaPlacas">{{'SearchVehicleUseCad' | translate}}</mat-placeholder>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPlacaSelected($event.option.value)">
                        <mat-option *ngFor="let option of filteredOptionsPlacas | async | slice : 0 : 4" [value]="option.placa">
                          {{option.placa}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                   </form>
            </div>
        </div>      
        <div class="row" style="padding-top: 16px;padding-left: 6px;padding-right: 6px;">
            <div class="col-12" style="padding: 0px;">
                <div class="border-0 shadow-sm overflow-hidden h-300">
                    <div class="card-body scroll-y h-250 p-0">
                        <div class="list-group roudned-0">
                            <div *ngIf="veiculos.length === 0" style="width: 100%;background-color: cadetblue;padding: 6px;font-weight: bold;" class="text-center">Não há veículos cadastrados</div>
                            <div *ngFor="let veiculo of veiculoSelecionado" class="list-group-item status-border border-warning">
                                <div class="row">
                                    <div class="col-auto align-self-center">
                                        <i class="material-icons">{{getIcon(veiculo.tipo)}}</i>
                                    </div>
                                    <div class="col pl-0" style="margin: auto !important;">
                                        <p style="margin: 0px !important;">{{veiculo.placa}}</p>
                                    </div>
                                    <div class="col-auto pr-0 align-self-center">
                                        <button class="btn btn-sm" (click)="favoritarToogle(veiculo.placa, veiculo.favorito)">
                                            <i [ngClass]=" veiculo.favorito ? 'material-icons' : 'material-icons-outlined'" 
                                               [ngStyle]="{'color': veiculo.favorito ? 'rgb(219, 219, 21)' : 'rgb(151, 151, 140)' }">grade</i></button>
                                        <button class="btn btn-sm text-danger" (click)="selecionar(veiculo)"><i class="material-icons">check</i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer bg-ligth">    
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate }}</button>    
</div>