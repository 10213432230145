import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import templateRegraZadInfo from '../components/regraZadInformativo/regra-zad-info-html-template';

@Injectable()
export class HelperService {
    constructor(private http: HttpClient) { }

    getRegraTextHelp(lang: string): string {
        return templateRegraZadInfo;
    }
}