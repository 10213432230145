export class CompraCADCartaoCredito {
    compra: {
        dispositivo: {
            fingerprint: string,
            uuid: string,
            lat: string,
            lng: string,
            network: {
                ip: string,
                subnet: string,
                type: string
            }
        },
        valor: number,
        quantidade: number,
        utilizacao: {
            veiculo: {
                tipo: number
            },
            filial: {
                id: number
            }
        },
        cartao: {
            token: string,
            nome: string,
            numero: string,
            lembrar: boolean,
            validade: {
                mes: string,
                ano: string
            },
            bandeira: string,
            csc: string
        }
    }
}