<div class="card border-0 shadow-sm">
    <div class="col py-0" style="border-bottom: solid 1px #4C4E51;margin-bottom: 10px;">
        <div class="card-header border-0 bg-none">
            <div class="row">
              <div class="col py-0 align-self-center">
                {{ 'Extract' | translate }}
              </div>
              <br /><br />
            </div>
        </div>
    </div>
    <div class="card-body">
      <div class="row" style="margin-bottom: 0px;">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <!-- <form class="form-inline"> -->
            <div class="form-group">
              <div class="input-group input-group-sm">
                <label for="dataInicial" style="color: white;font-weight: 100;margin-right: 4px;margin-top:4px;">De: </label>
                <input (keyup)="dateMask($event)" maxlength="10" (blur)="getExtrato()" (dateSelect)="getExtrato()" class="form-control form-control-sm" placeholder="yyyy-mm-dd"
                       name="dataInicial" style="border-radius: 6px;" [minDate]="{year: 1900, month:1, day: 1}" [(ngModel)]="dataInicial" ngbDatepicker #dIni="ngbDatepicker">
                <div class="input-group-append">
                  <a (click)="dIni.toggle()" class="filtroData">
                    <i class="material-icons">date_range</i>
                  </a>                
                </div>
              </div>
            </div>
          <!-- </form> -->
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"><!-- col-xs-12 col-sm-6 col-md-6 col-lg-4 -->
          <!-- <form class="form-inline"> -->
            <div class="form-group">
              <div class="input-group input-group-sm">
                <label for="dataFinal" style="color: white;font-weight: 100;margin-right: 4px;margin-left:4px;margin-top:4px;"> Até: </label>
                <input (keyup)="dateMask($event)" maxlength="10" (blur)="getExtrato()" (dateSelect)="getExtrato()" class="form-control form-control-sm" placeholder="dd/mm/aaaa"
                       name="dataFinal" style="border-radius: 6px;" [minDate]="{year: 1900, month:1, day: 1}" [(ngModel)]="dataFinal" ngbDatepicker #dFim="ngbDatepicker">
                <div class="input-group-append">
                  <a (click)="dFim.toggle()" class="filtroData">
                    <i class="material-icons">date_range</i>
                  </a>
                </div>
              </div>
            </div>
          <!-- </form> -->
        </div>   
        
        <div [hidden]="!usuarioCorporativo" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <button class="btn btn-sm btn-success btn-block btn-rounded" 
                   (click)="gerarRelatorioMovimentacoesModal()">{{ 'ReportFull' | translate}}
                <i class="material-icons">view_list</i>
            </button>
        </div>
      
      </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-300">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav nav-pills nav-fill" >
                    <li [ngbNavItem]="'todos'" style="border-color: white!important;">
                      <a ngbNavLink>{{'AllTransactions' | translate}}</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                        <div class="card-body scroll-y h-250 p-0" #scrolItensExtrato (scroll)="onScroll()">
                          <div class="card-body table-responsive">
                            <!-- <ngb-pagination
                            [(page)]="pageTodos" [maxSize]="8" [rotate]="true" [boundaryLinks]="true"
                            
                            [pageSize]="pageSize"
                            [collectionSize]="totalRegistrosTodos"
                            
                            (pageChange)="loadPage($event, 'todos')"></ngb-pagination> -->
                            <table class="table table-hover table-striped">
                                <tbody *ngFor="let movimento of movimentacoes">
                                  <!-- <tr [hidden]="hide(movimento.movimento.data)">
                                    <td colspan="5" style="text-align: right;">{{'Month' | translate }}  {{movimento.movimento.data | date:'MM' | uppercase}} de {{movimento.movimento.data | date:'yyyy'}}</td>
                                  </tr> -->
                                  <tr (click)="open(movimento.movimento.id)" style="cursor: pointer;">
                                    <td><i class="material-icons">{{movimento.movimento.tipo === "utilizacao" ? "directions_car" : "monetization_on" }} </i></td>
                                    <td>{{getNiceDescription(movimento.movimento.tipo)}}</td>
                                    <td> {{(movimento.movimento.tipo === 'estorno-recarga' ? '-' : '')}} {{movimento.movimento.valor | currency:'BRL'}}</td>
                                    <td>{{movimento.movimento.data | date:'dd/MM/yyyy'}}</td>
                                    <td><i class="material-icons">open_in_new</i></td>
                                  </tr>
                                </tbody>
                            </table>                            
                          </div>
                          
                        </div>
                      </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="'creditos'" style="border-color: white!important;">
                      <a ngbNavLink>{{'Credits' | translate}}</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                          <div class="card-body scroll-y h-250 p-0" #scrolItensExtrato (scroll)="onScroll()">
                            <div class="card-body table-responsive">
                              <!-- <ngb-pagination
                              [(page)]="pageCreditos"
                              [pageSize]="pageSize"
                              [collectionSize]="totalRegistrosCreditos"
                              [rotate]="true"
                              (pageChange)="loadPage($event, 'creditos')"></ngb-pagination> -->
                              <table class="table table-hover table-striped">
                                  <tbody *ngFor="let movimento of movimentacoesCredito">
                                    <!-- <tr [hidden]="hide(movimento.movimento.data)">
                                      <td colspan="5" style="text-align: right;">{{movimento.movimento.data | date:'LLLL' | uppercase}} de {{movimento.movimento.data | date:'yyyy'}}</td>
                                    </tr> -->
                                    <tr (click)="open(movimento.movimento.id)" style="cursor: pointer;">
                                      <td><i class="material-icons">{{movimento.movimento.tipo === "utilizacao" ? "directions_car" : "monetization_on" }} </i></td>
                                      <td>{{getNiceDescription(movimento.movimento.tipo)}}</td>
                                      <td>{{movimento.movimento.valor | currency:'BRL'}}</td>
                                      <td>{{movimento.movimento.data | date:'dd/LLL'}}</td>
                                      <td><i class="material-icons">open_in_new</i></td>
                                    </tr>
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="'cads'" style="border-color: white!important;">
                      <a ngbNavLink>CADs</a>
                      <ng-template ngbNavContent>
                        <div class="border-0 shadow-sm overflow-hidden h-300">
                          <div class="card-body scroll-y h-250 p-0">
                            <div class="card-body table-responsive">
                              <!-- <ngb-pagination
                              [(page)]="pageCads"
                              [pageSize]="pageSize"
                              [collectionSize]="totalRegistrosCads"
                              [rotate]="true"
                              (pageChange)="loadPage($event, 'cads')"></ngb-pagination> -->
                              <table class="table table-hover table-striped">
                                  <tbody *ngFor="let movimento of movimentacoesCAD">
                                    <!-- <tr [hidden]="hide(movimento.movimento.data)">
                                      <td colspan="5" style="text-align: right;">{{movimento.movimento.data | date:'LLLL' | uppercase}} de {{movimento.movimento.data | date:'yyyy'}}</td>
                                    </tr> -->
                                    <tr (click)="open(movimento.movimento.id)" style="cursor: pointer;">
                                      <td><i class="material-icons">{{movimento.movimento.tipo === "utilizacao" ? "directions_car" : "monetization_on" }} </i></td>
                                      <td>{{getNiceDescription(movimento.movimento.tipo)}}</td>
                                      <td>{{movimento.movimento.valor | currency:'BRL'}}</td>
                                      <td>{{movimento.movimento.data | date:'dd/MM/yyyy'}}</td>
                                      <td><i class="material-icons">open_in_new</i></td>
                                    </tr>
                                  </tbody>
                              </table>
                             
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                </ul>                  
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
          <button class="btn btn-sm btn-success btn-block btn-rounded" (click)="carregarMaisItensPeriodo()" [disabled]="!carregarMaisEnabled" >{{ 'MoreItensHistory' | translate}} &nbsp;<i class="material-icons">add_circle</i></button>
        </div>
    </div>
</div>
<br />