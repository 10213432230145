import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

import Swal from 'sweetalert2';
import { ClienteService } from '../../service/cliente.service';

@Component({
    selector: 'app-edicao-frotista',
    templateUrl: './edicaoFrotista.component.html'
})

export class EdicaoFrotistaComponent {

    @Output()
    configuracaoUsuarioFrotistaAtualizada: EventEmitter<any> = new EventEmitter();

    frotistaForm: FormGroup;

    TipoLimiteFrotista: {id: string, desc: string}[] = [
        { id: "", desc: "Sem limite -Saldo total disponível"}, 
        { id: "1", desc: "Mensal" },
        { id: "2", desc: "Semanal" },
        { id: "3", desc: "Diário" }
    ];

    public mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    
    @Input()
    public usuarioCorporativo : any;

    @Input()
    public tipoLimite: any[];

    isReadonly = true;

    constructor(//private modalService: NgbModal,
                private spinner: NgxSpinnerService,
                config: NgbModalConfig,
                private clienteService: ClienteService,
                public activeModal: NgbActiveModal,
                private fb: FormBuilder,
                private translate: TranslateService){
            config.backdrop = 'static';
            config.keyboard = false;            
    }

    public setDados(){       
        this.frotistaForm = this.fb.group({
            cpf: [this.usuarioCorporativo.documentoUsuario, [Validators.required]],
            tipoLimite: [this.usuarioCorporativo.idTipoLimiteCorporativo === null ? "" : this.usuarioCorporativo.idTipoLimiteCorporativo, []],
            limiteMaximo: [this.usuarioCorporativo.limite, []],
            tipoUsuarioFrotista: [this.usuarioCorporativo.idTipoUsuarioFrotista === 1 ? false : true, []],
            ativo: [this.usuarioCorporativo.ativo, []]
          });
    }

    salvar(){

        if(!this.frotistaForm.valid){
            Swal.fire({
              text: this.translate.instant('RequiredFields'),
              titleText: this.translate.instant('AlertInfo'),
              icon: 'info',                 
              confirmButtonColor: '#4a9f42'
            });
            return;
          }

          this.clienteService.postAdicionarUsuarioFrotista({
            DocumentoAdminEmpresaFrotista: this.clienteService.obterDocumentoOriginalAdminFrotista(),
            IdTipoLimite: this.frotistaForm.value.tipoLimite,
            LimiteMaximo: this.frotistaForm.value.limiteMaximo,
            TipoUsuarioFrotista: this.frotistaForm.value.tipoUsuarioFrotista ? 2 : 1,
            Ativo: this.frotistaForm.value.ativo,
            DocumentoUsuarioFrotista: this.frotistaForm.value.cpf.replace(/\D+/g, '')
          })
          .subscribe(
                result => {
                //this.spinner.hide();
                Swal.fire({
                    text: result.message,
                    titleText: this.translate.instant('AlertInfo'),
                    icon: 'success',                 
                    confirmButtonColor: '#4a9f42'
                }).then((result) => {
                    if(result.value) {
                    //this.compraFinalizada.emit();
                    this.activeModal.dismiss();
                    this.configuracaoUsuarioFrotistaAtualizada.emit();
                    }
                });

                },
                fail => {
                
                //this.spinner.hide();
                Swal.fire({
                    text: fail.message,
                    titleText: '',
                    icon: 'info',                 
                    confirmButtonColor: '#4a9f42'
                });               
                this.activeModal.dismiss();
                }
           );
    }
}