export enum Funcionalidades {
    VerCadastro,
    EditarCadastro,
    ListarVeiculos,
    IncluirVeiculo,
    ListarTelefones,
    IncluirTelefone,
    VisualizarSaldoCarteira,
    VisualizarSaldoCad,
    ComprarCredito,
    AtivarCad,
    VerHistoricoTransacoes,
    VerHistoricoBoletos,
    ListarCartoes,
    IncluirCartoes,
    UsuariosCorporativos,
    ExcluirVeiculo,    
    InformarValorCompraCredito,
    ComercioEspecial,
    ExibirCentroDeCusto
}