<div class="modal-body">
    <table>
        <tbody>            
            <tr>
                <td><strong>CPF / CNPJ:</strong></td>
                <td>{{boletoPago.CpfCnpj}}</td>
            </tr>
            <tr>
                <td><strong>NUMERO PEDIDO / DOCUMENTO:</strong></td>
                <td>{{boletoPago.NumeroDocumento}}</td>
            </tr>
            <tr>
                <td><strong>Nosso Número</strong></td>
                <td>{{boletoPago.NossoNumero}}</td>
            </tr>
            <tr>
                <td><strong>Valor Pago</strong></td>
                <td>{{boletoPago.Valor | currency:'BRL'}}</td>
            </tr>
            <tr>
                <td><strong>Data Pagamento:</strong></td>
                <td>{{formatData(boletoPago.DataBaixa) | date:'dd/MM/yyyy'}}</td>
            </tr>
            <tr>
                <td><strong>Situação Compra</strong></td>
                <td>PAGO</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
</div>