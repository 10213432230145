<router-outlet></router-outlet>

<ngx-ui-loader fgsColor="#6FD44A" pbColor="#6FD44A" fgsSize="80" fgsType="cube-grid"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'disponibilidadeSpinner'" fgsColor="#6FD44A" fgsSize="80" fgsType="cube-grid"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'filialSpinner'" fgsColor="#6FD44A" fgsSize="80" fgsType="cube-grid"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'ativacaoCad'" fgsColor="#6FD44A" fgsSize="80" fgsType="cube-grid"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'esqueceuSenhaLoader'" fgsColor="#6FD44A" fgsSize="80" fgsType="cube-grid"></ngx-ui-loader>
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.9)" size = "large" color = "#6FD44A" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > {{ 'Carregando' }} </p></ngx-spinner> -->
<ngx-spinner name="disponibilidadeSpinner" bdColor = "rgba(0, 0, 0, 0.9)" size = "large" color = "#6FD44A" type = "cube-transition" [fullScreen] = "true"><p style="color: white" > {{ 'Carregando Mapa...' }} </p></ngx-spinner>
<ngx-spinner name="filialSpinner" bdColor = "rgba(0, 0, 0, 0.9)" size = "large" color = "#6FD44A" type = "cube-transition" [fullScreen] = "true"><p style="color: white" > {{ 'Carregando Mapa...' }} </p></ngx-spinner>

