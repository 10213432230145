<div class="modal-header">
    <h4 class="modal-title">{{'Registration Data' | translate}}</h4>
  </div>
  <div class="modal-body">
      <form novalidate [formGroup]="cadastroBasicoClienteForm">
          <div class="container form-group">
            <div class="row mb-4">
                <div class="col-md-6" formGroupName="documento" style="border-left:none;">
                    <label for="documentoCliente">{{'Document' | translate}}: *</label>
                    <input required 
                           class="form-control" 
                           id="documentoCliente" 
                           placeholder="{{'Document' | translate}}" 
                           formControlName="numero" 
                           matInput mask="000.000.000-00||00.000.000/0000-00||AA.000.000" 
                           (blur)="blur($event)" 
                           (keydown)="keyup($event)" 
                           (paste)="onPaste($event)" />                                                         
                </div>
                <div class="col-md-6" [hidden]="cadastroBasicoClienteForm.get('documento.tipo').value === 'CNPJ'">
                    <label for="dataNascimento">{{'DateOfBirth' | translate}}: *</label>
                    <div class="form-inline">
                        <div class="form-group">
                            <div class="input-group">                                
                                <input (keyup)="dateMask($event)" maxlength="10" formControlName="dataNascimento" class="form-control" placeholder="dd/mm/aaaa"
                                      [required]="cadastroBasicoClienteForm.get('documento.tipo').value === 'CPF'" name="dataNascimento" id="dataNascimento" [minDate]="{year: 1900, month:1, day: 1}" ngbDatepicker #dataNascimento="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn  btn-outline-secondary calendar" (click)="dataNascimento.toggle()" type="button"></button>
                                </div>
                            </div>                            
                        </div>                            
                    </div> 
                </div>
            </div>
            <div class="row mb-4" [hidden]="cadastroBasicoClienteForm.get('documento.tipo').value == 'CNPJ'">
                <div class="col-md-4">
                    <label for="nomeCliente">{{ 'First Name' | translate }}: *</label>
                    <input [required]="cadastroBasicoClienteForm.get('documento.tipo').value !== 'CNPJ'" class="form-control" id="nomeCliente" placeholder="{{ 'First Name' | translate }}" formControlName="nome"
                    [ngClass]="{'is-invalid': cadastroBasicoClienteForm.get('nome')?.errors?.length > 0}" />
                </div>
                <div class="col-md-8">
                    <label for="validationCustom02">{{ 'LastName' | translate}}: *</label>
                    <input [required]="cadastroBasicoClienteForm.get('documento.tipo').value !== 'CNPJ'"
                           class="form-control" placeholder="{{ 'LastName' | translate}}" formControlName="sobrenome"
                    [ngClass]="{'is-invalid': cadastroBasicoClienteForm.get('sobrenome')?.errors?.length > 0}" />
                </div>
            </div>
            <div class="row mb-4" [hidden]="cadastroBasicoClienteForm.get('documento.tipo').value != 'CNPJ'">
                <div class="col-md-12">
                    <label for="">{{'Razao Social' | translate}}</label>
                    <input [required]="cadastroBasicoClienteForm.get('documento.tipo').value !== 'CPF'" 
                            class="form-control" placeholder="" formControlName="razaoSocial" />
                </div>
            </div>
            <div class="row mb-4" [hidden]="cadastroBasicoClienteForm.get('documento.tipo').value != 'CNPJ'">
                <div class="col-md-12">
                    <label for="">{{'Nome Fantasia' | translate}}</label>
                    <input [required]="cadastroBasicoClienteForm.get('documento.tipo').value !== 'CPF'" class="form-control" placeholder="" formControlName="nomeFantasia" />
                </div>
            </div>          
            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="emailCliente">{{'Email' | translate}}: *</label>
                    <input autocomplete="off" 
                           required class="form-control" 
                           id="emailCliente" 
                           placeholder="{{'Email' | translate}}" 
                           type="text" 
                           formControlName="email"
                           [ngClass]="{'is-invalid': cadastroBasicoClienteForm.get('email')?.errors?.length > 0}" 
                           style="text-transform: lowercase" />
                </div>
                <div class="col-md-6">
                    <div formArrayName="telefones" [hidden]="true">                        
                    </div>
                    <label for="celularCliente">{{'Celular' | translate}}: *</label>
                    <input required class="form-control" 
                           id="celularCliente" 
                           placeholder="(xx) xxxxx-xxxx" 
                           formControlName="celularCliente"
                           mask="(00) 00000-0000||(00) 0000-0000"
                           [ngClass]="{'is-invalid': celularCliente.invalid && (celularCliente.dirty || celularCliente.touched) }" />
                    <div class='form-text error' *ngIf="celularCliente.invalid && (celularCliente.dirty || celularCliente.touched) ">
                        Celular.
                    </div>
                </div>               
            </div>
         
            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="senha">{{'Password' | translate }}: *</label>
                    <input required minlength="8" (keyup)="onChangeSenha()" type="password" class="form-control" id="senha" formControlName="senha"
                     />
                </div>
                <div class="col-md-6">
                    <label for="repetirSenha">{{'ConfirPassword' | translate}}: *</label>
                    
                    <input required autocomplete="off" minlength="8" type="password" class="form-control" id="repetirSenha" formControlName="repetirSenha"
                    [ngClass]="{'is-invalid': repetirSenha.invalid && repetirSenha?.errors?.confirmacaoSenhaInvalido}" />
                    <div class='form-text error' *ngIf="repetirSenha.invalid && repetirSenha?.errors?.confirmacaoSenhaInvalido">
                        Confirmação de Senha deve ser igual a Senha.
                    </div>
                </div>
            </div>
            <div class="my-3 row">
                <div class="col-12 col-md py-1">
                    <div class="custom-control custom-checkbox" style="cursor:pointer !important;">
                        <input required type="checkbox" class="custom-control-input" id="customCheck2" [checked]="aceiteTermoUso" (change)="aceiteTermoUso = !aceiteTermoUso" />
                        <label class="custom-control-label" for="customCheck2">{{ 'AgreeTermsOfUse' | translate }} </label> <a (click)="open()" style="cursor: pointer; color:rgba(8,132,54,1) !important;"><strong> {{ 'Terms of Use' | translate }}</strong></a>
                    </div>
                </div>
            </div>
          </div>
      </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate }}</button>
    <!-- <button type="submit" class="btn btn-outline-success" (click)="salvar()">{{ 'Save' | translate}}</button> -->
    <button type="submit" class="btn btn-outline-success" (click)="cadastrar()">{{ 'Save' | translate}}</button>
  </div>