import { Component } from "@angular/core";
import { AtivacaoCADComponent } from './ativacao-cad.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { FiliaisService } from '../../service/filiais.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SetorFilter } from 'src/app/model/utilizacao-cad';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-utilizacao-cad',
    templateUrl: './utilizacao-cad.component.html'
})

export class UtilizacaoCADComponent {

    constructor(
        private modalService: NgbModal,
        private translationService: TranslateService
        ,config: NgbModalConfig
        ,private filialService: FiliaisService
        ,private ngxLoader: NgxUiLoaderService){

            config.backdrop = 'static';
            config.keyboard = false;
        }

        private _OperacaoIniciada() : boolean {          
          var today = new Date();
          today.setHours(0, 0, 0, 0);
          const inicioOperacaoConfig = environment.DataInicioOperacao.split('/');
          let inicioOperacaoDate = new Date(parseInt(inicioOperacaoConfig[2]), 
                                            parseInt(inicioOperacaoConfig[1]),
                                            parseInt(inicioOperacaoConfig[0]),0,0,0); 
          inicioOperacaoDate.setHours(0, 0, 0, 0);
  
          if(today >= inicioOperacaoDate){ 
              return true;
          }
          else{
              Swal.fire({
                  text: this.translationService.instant('OperationNotStarted'),
                  titleText: this.translationService.instant('AlertInfo'),
                  icon: 'info',                 
                  confirmButtonColor: '#4a9f42'
                });  
              return false;
          }            
      }    

    openAtivacaoCAD(){

      if(!this._OperacaoIniciada()){
        return false;
      }        
    
      const modalRef = this.modalService.open(AtivacaoCADComponent, { centered: true, scrollable: true, size: 'xl' });
      
      modalRef.componentInstance.cadUtilizado
         .subscribe(
           result => {
                         
             const dataValidadeCad = new Date(result.compra.utilizacao.fim);
             
             const dataTransacao = result?.compra?.utilizacao?.criacao?.split(" ")[0]?.split("-");
             const dataTransacaoTime = result.compra.utilizacao.criacao.split(" ")[1];

             const dataInicio = result.compra.utilizacao.inicio.split(" ")[0]?.split("-");;
             const dataInicioTime = result.compra.utilizacao.inicio.split(" ")[1];

             const dataFim = result.compra.utilizacao.fim.split(" ")[0]?.split("-");;
             const dataFimTime = result.compra.utilizacao.fim.split(" ")[1];            

             const dataTransacaoFormatada = `${dataTransacao[2]}/${dataTransacao[1]}/${dataTransacao[0]} ${dataTransacaoTime.substr(0,8)}`;
             const dataInicioFormatada = `${dataInicio[2]}/${dataInicio[1]}/${dataInicio[0]} ${dataInicioTime.substr(0,8)}`;
             const dataFimFormatada = `${dataFim[2]}/${dataFim[1]}/${dataFim[0]} ${dataFimTime.substr(0,8)}`;

             this._mensagemConfirmacaoCad(result, 
                                         dataValidadeCad, 
                                         dataTransacaoFormatada, 
                                         dataInicioFormatada,
                                         dataFimFormatada);
           },
           () => {
           }
         ); 
    }

    private _mensagemConfirmacaoCad(result, 
                                    dataValidadeCad, 
                                    dataTransacaoFormatada, 
                                    dataInicioFormatada,
                                    dataFimFormatada){
      Swal.fire({
        titleText: '',
        html: `${result.message} <br />

         <strong>Seu Cad vale até às ${this.pad(dataValidadeCad.getHours(),2)}:${this.pad(dataValidadeCad.getMinutes(), 2)}:${this.pad(dataValidadeCad.getSeconds(), 2)} </strong>
         <br />
         <br />
         <div class="card bg-light" style="font-size: 0.7em;color: green;">
           <div class="card-header">
             <div class="row">
               <div class="col ">
                  <strong> ${result.compra.utilizacao.veiculo.placa} </strong>
               </div>
               <div class="col">
                   ${(result.compra.utilizacao.tempo / 60 < 1) ? result.compra.utilizacao.tempo + ' ' + this.translationService.instant('MinuteCad') : result.compra.utilizacao.tempo / 60} ${this.translationService.instant('HourCad')}
               </div>
             </div>                   
           </div>
           <ul class="list-group list-group-flush">
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Data:</b></div>
                   <div class="col">${dataTransacaoFormatada}</div>
                 </div>
             </li>  
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Início:</b></div>
                   <div class="col">${dataInicioFormatada}</div>
                 </div>
             </li>    
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Fim:</b></div>
                   <div class="col">${dataFimFormatada}</div>
                 </div>
             </li>    
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Tempo adiquirido:</b></div>
                   <div class="col">${this.calcularTempoCad(result.compra.utilizacao.tempo)}</div>
                 </div>
             </li>  
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Setor:</b></div>
                   <div class="col">${result.compra.utilizacao.setor}</div>
                 </div>
             </li>
             <li class="list-group-item" style="color: green;">
                 <div class="row">
                   <div class="col-sm-4 col-md-4 col-lg-4"><b>Autenticação:</b></div>
                   <div class="col">${result.compra.transacao.autenticacaoExterna}</div>
                 </div>
             </li>                        
           </ul>
         </div>
     `,           
       icon: 'success',
       confirmButtonColor: '#4a9f42'
       })  
    }

    pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    calcularTempoCad(minutos: number){
      return ( (minutos / 60) < 1 ) ? `${minutos} ${this.translationService.instant('MinuteCad')}` : `${minutos / 60} ${this.translationService.instant('HourCad')}`;
    }
}