<div class="card border-0 shadow-sm mb-2">
    <div class="col py-2 pr-0" style="border-bottom: solid 1px #4C4E51;">
        <div class="card-header border-0 bg-none" style="padding: 0px 15px;">
            <div class="row">
                <div class="col align-self-center">
                    {{ 'Credit Card' | translate}}
                </div>
                <div class="col-auto">
                    <div class="dropdown" ngbDropdown>
                        <button [hidden]="!cartaoIncluir" class="btn dropdown-toggle btn-sm btn-link py-0 active" (click)="open()">
                            <i class="material-icons">add</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-100">
                <div class="card-body scroll-y h-150 p-0">
                    <div class="list-group roudned-0">
                        <div *ngFor="let cartao of cartoes" class="list-group-item status-border border-warning" style="padding-left: 0px; padding-right: 0px; font-size: 10px;">
                            <div class="row">
                                <div class="col-2 col-auto align-self-center">
                                    <i class="material-icons">credit_card</i>
                                    <!--col-auto align-self-center-->
                                </div>
                                <div class="col-5" style="padding: 0px; margin: auto !important;">
                                    <p style="margin: 0px !important; font-size: 11px;">**** **** **** {{cartao.ultimosDigitos}}</p>
                                    <!--col pl-0-->
                                </div>
                                <div class="col-3" style="padding: 0px; margin: auto !important;font-size: 11px;">
                                    <p style="margin: 0px !important;">{{cartao.bandeira}}</p>
                                </div>
                                <div class="col-2 col-auto pr-0 align-self-center" style="padding: 0px;">
                                    <button class="btn btn-sm text-danger" (click)="deletar(cartao.uuidCartaoEstapar)"><i class="material-icons">delete_forever</i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>