export class Cartao {
    public numero: string;
    public bandeira: string;
    public portador: string;
    public mesExpiracao: string;
    public anoExpiracao: string;
    public cpfCnpj: string;
    public cvv: string;    
}

export class CartaoRetorno {
    DataUltimaAtualizacao: Date;
    bandeira: string;
    portador: string;
    ultimosDigitos: string;
    uuidCartaoEstapar: string;
    IsSelecionado: boolean; 
}

export class CartaoValidacaoBinRetorno {
    tipo: string;
    bandeira: string;
    idBandeira: number;
}