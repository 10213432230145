<div class="modal-header page-checkout">
    <h4 class="modal-title">{{ 'Buy Credit' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelar()" style="color: #fff; opacity: 1;">
        <span aria-hidden="true" style="font-size: 2rem;">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="height: auto;background-image: linear-gradient(#fff, #cecaca);">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav nav-pills nav-fill">
                    <li [ngbNavItem]="1" [disabled]="disable(1)">
                        <a ngbNavLink>{{'Credit'|translate}}</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-sm-12 c                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ol-md-12 col-lg-12 col-xl-12">
                                    <div class="list-group roudned-0" style="padding: 0px;">
                                        <div [hidden]="!informarValorCredito && !ehComercioEspecial">
                                            <div class="d-flex justify-content-between align-items-center p-2 items rounded" style="cursor: pointer;">
                                                <div  class="d-flex flex-row"><i class="material-icons">payment</i>
                                                    <div class="ml-0" style="text-align: left;">
                                                        <span class="d-block">{{'CreditOf'|translate}}</span>                                            
                                                        <span class="spec font-weight-bold">{{'EnterWithValueLabel' | translate}}:</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-row align-items-center">
                                                    <span style="margin-right: 8px;color: red;font-weight:bold;">{{msgValorValidacao}}</span>
                                                    <input size="14%" type="text" [(ngModel)]="valorDigitado" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                                                    <button [hidden]="!informarValorCredito && !ehComercioEspecial" (click)="next(2, valorDigitado, '')" style="background-color: transparent; border: none;">
                                                        <i class="material-icons ml-2 text-black-50">navigate_next</i>
                                                    </button>                                        
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div *ngFor="let opcaoCredito of valoresCredito" class="d-flex justify-content-between align-items-center p-2 items rounded" (click)="next(2, opcaoCredito.valor, '')" style="cursor: pointer;">
                                            <div class="d-flex flex-row">
                                                <i class="material-icons">payment</i>
                                                <div class="ml-0" style="text-align: left;">
                                                    <span class="d-block">{{'CreditOf'|translate}}</span>
                                                    <span class="spec font-weight-bold">{{opcaoCredito.valor | currency:'BRL' }}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-row align-items-center">
                                                <span class="d-block">&nbsp;</span>
                                                <span class="d-block ml-5 font-weight-bold">{{opcaoCredito.valor | currency:'BRL' }}</span>
                                                <i class="material-icons ml-3 text-black-50">navigate_next</i>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" [disabled]="disable(2)">
                        <a ngbNavLink>{{'PaymentMethod' | translate}}</a>
                        <ng-template ngbNavContent>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="max-height: 350px; height:auto;overflow-y: scroll;">
                                        <div *ngFor="let cartao of cartoes;" style="margin: 10px;">
                                            <div [ngClass]="cartao.IsSelecionado ? 'formaPagamentoSelecionada' : 'formaPagamento'"
                                                 (click)="selectCard(cartao)" class="card bg-light cartao-credito-forma-pagamento">
                                                <div class="card-body">
                                                  <b>{{ (cartao.bandeira === 'Credit Card') ? ('New Credit Card' | translate) : cartao.bandeira }}</b>
                                                  <img src="{{getIconeFormaPagamento(cartao.bandeira)}}" width="20" height="20" style="margin-top: -1px;margin-left:6px;">
                                                  <h5 class="card-title">
                                                    {{ (cartao.bandeira !== "BOLETO" && cartao.bandeira !== "Credit Card") ? '**** **** **** ' + cartao.ultimosDigitos : cartao.bandeira | translate | uppercase }}
                                                  </h5>                                         
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                                        <div style="margin: 10px;">
                                            <div [ngSwitch]="cartao?.bandeira">
                                                <div *ngSwitchCase="'Credit Card'">
                                                    <form [formGroup]="cartaoForm">
                                                        <div class="content">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <h1>{{ 'New Credit Card' | translate}}</h1>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="numero">{{ 'Card Number' | translate }} 
                                                                            <!-- <span *ngIf="cartaoForm.get('numero').hasError('minlength')">Mínimo 16 números</span> -->
                                                                             </label>
                                                                        <div class="input-group">
                                                                            <input #numero
                                                                                   required 
                                                                                   maxlength="19"
                                                                                   tabindex="0" 
                                                                                   [attr.disabled]="validandoCard ? true : null"
                                                                                   style="text-transform: uppercase;"
                                                                                   class="form-control" 
                                                                                   name="numero" 
                                                                                   formControlName="numero" 
                                                                                   placeholder="{{'Card Number' | translate}}" 
                                                                                   autocomplete="cc-number" 
                                                                                   (keyup)="setaBandeira($event)"
                                                                                   (blur)="verificaCartaoCampo($event)"
                                                                                   (paste)="onPaste($event)"                                                                                    
                                                                                    mask="0000 0000 0000 0000"
                                                                                    />
                                                                            <span class="input-group-addon">
                                                                                <!-- <i [hidden]="true" class="material-icons" style="margin-top: 6px;">credit_card</i> -->
                                                                               <div><img src="assets/images/logo/{{cssBandeira}}" style="margin-top: -5px;"></div>                                                   
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label>{{ 'Card Holder' | translate }}</label>
                                                                        <input required class="form-control" placeholder="{{ 'Card Holder' | translate }}" formControlName="portador" textOnly  style="text-transform: uppercase;"/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                                                    <div class="form-group">
                                                                        <label for="cardExpiry"><span class="hidden-xs">{{'CardValidDate' | translate}}</span></label>
                                                                        <input type="tel" required maxlength="3" class="form-control" name="cardExpiryMonth" formControlName="mesExpiracao" placeholder="MM" autocomplete="cc-exp-month" [textMask]="{mask: maskMonth}" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                                                    <div class="form-group">
                                                                        <label for="cardExpiry"><span class="hidden-xs" style="visibility: hidden;">_</span></label>
                                                                        <input type="tel" required maxlength="3" class="form-control" name="cardExpiryYear" formControlName="anoExpiracao" placeholder="{{'anoCartaoPlaceHolder' | translate}}" autocomplete="cc-exp-year" [textMask]="{mask: maskYear}" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pull-right">
                                                                    <div class="form-group">
                                                                        <label>{{ 'CodeSecurityCardLabel' | translate}}</label>
                                                                        <input required type="tel" class="form-control" name="cardCVC" placeholder="{{'CVV' | translate}}" autocomplete="cc-csc" formControlName="cvv" numbersOnly />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group" style="margin-top: 6px;">
                                                                        <label>{{ 'DocumentTitularCard' | translate }}</label>
                                                                        <input required class="form-control" 
                                                                               id="documentoCliente" 
                                                                               placeholder="{{'Document' | translate}}" 
                                                                               formControlName="documentoTitular" 
                                                                               matInput mask="000.000.000-00||00.000.000/0000-00"                                                                               
                                                                               (paste)="onPasteDocumento($event)" 
                                                                               style="text-transform: uppercase"/>  
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group" style="margin-top: 6px;">
                                                                        <label>{{ 'SaveCardQuestion' | translate }}</label>
                                                                        <input type="checkbox" class="form-control form-control-sm" formControlName="lembrar" />                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div style="margin-top: 20px; margin-bottom: 10px;" class="float-right">
                                                        <button style="margin: 4px;" class="btn btn-sm btn-light" (click)="nav.select(1)">{{'Previous' | translate}}</button>
                                                        <button class="btn btn-sm btn-next" (click)="next(3, valorSelecionado, 'novoCartao')">{{'Next' | translate}}</button>
                                                    </div> 
                                                </div>
                                                <div *ngSwitchCase="'BOLETO'">
                                                    <div class="formaPagamentoSelecionadaDados">
                                                        <form [formGroup]="boletoForm">
                                                            <div class="col-12">
                                                                <!-- <h1>{{'DueDate'| translate}}</h1>
                                                                <input type="date" id="dataNascimentoCliente" class="form-control" placeholder="dd/mm/yyyy" formControlName="dataVencimentoBoleto" /> -->
                                                                <label for="dataVencimentoBoleto" style="font-weight: bold;">{{'DueDate'| translate}}: *</label>
                                                                <div class="form-inline">
                                                                    <div class="form-group">
                                                                        <div class="input-group">                                
                                                                            <input (blur)="validateDataMinima()" (keyup)="dateMask($event)" maxlength="10" formControlName="dataVencimentoBoleto" class="form-control" placeholder="dd/mm/aaaa"
                                                                                required name="dataVencimentoBoleto" id="dataVencimentoBoleto" [minDate]="dataMinimaPagBoleto" ngbDatepicker #dataVencimentoBoleto="ngbDatepicker">
                                                                            <div class="input-group-append">
                                                                                <button class="btn  btn-outline-secondary calendar" (click)="dataVencimentoBoleto.toggle()" type="button"></button>
                                                                            </div>
                                                                        </div>                            
                                                                    </div>                            
                                                                </div> 
                                                                
                                                            </div>
                                                        </form>
                                                    </div> 
                                                    <div style="margin-top: 20px; margin-bottom: 10px;" class="float-right">
                                                        <button style="margin: 4px;" class="btn btn-sm btn-light" (click)="nav.select(1)">{{'Previous' | translate}}</button>
                                                        <button class="btn btn-sm btn-next" (click)="next(3, valorSelecionado, 'boleto')">{{'Next' | translate}}</button>
                                                    </div>                                                   
                                                </div>
                                                <div *ngSwitchDefault>
                                                    <div class="formaPagamentoSelecionadaDados">
                                                        <h2>{{'Credit Card' | translate}}</h2>
                                                        <h3>{{cartao.bandeira}}</h3>
                                                        <h3>**** **** **** {{cartao.ultimosDigitos}}</h3>                                                       
                                                    </div>
                                                    <div style="margin-top: 20px;" class="float-right">
                                                        <button style="margin: 4px;" class="btn btn-sm btn-light" (click)="nav.select(1)">{{'Previous' | translate}}</button>
                                                        <button class="btn btn-sm btn-next" (click)="next(3, valorSelecionado, cartao.uuidCartaoEstapar)">{{'Next' | translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>                            
                                                       
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" [disabled]="disable(3)">
                        <a ngbNavLink>{{'Confirmation' | translate}}</a>
                        <ng-template ngbNavContent>
                            <div [ngSwitch]="formaPgto">
                                <div *ngSwitchCase="'boleto'">
                                    <div class="resumoConfirmacaoPagamento">
                                        <div class="dados">
                                            <h3 style="margin-bottom: 0px;">
                                                <span style="font-weight: bold;">{{'Value' | translate}}:</span> 
                                                {{valorSelecionado | currency:'BRL'}}
                                            </h3>                                           
                                        </div>
                                        <div class="dados">
                                            <h3 style="margin-bottom: 0px">
                                                <span style="font-weight: bold;">{{'BankSlip' | translate}}:</span>
                                                {{dataVencimentoBoletoFormatada}}
                                            </h3>
                                        </div>                                        
                                    </div>
                                    <div style="margin-top: 20px;" class="float-right">
                                        <button style="margin: 4px;" class="btn btn-light btn-sm " (click)="nav.select(2)">{{'Previous' | translate}}</button>
                                        <button class="btn btn-next btn-sm" (click)="confirmarBoleto()">{{'Confirm' | translate}}</button>
                                    </div>                                  
                                </div>
                                <div *ngSwitchDefault>

                                    <div class="resumoConfirmacaoPagamento">
                                        <div class="dados">
                                            <h3 style="margin-bottom: 0px">
                                                <span style="font-weight: bold;">{{'Value' | translate}}:</span> 
                                                {{valorSelecionado | currency:'BRL'}}
                                            </h3>                                           
                                        </div>
                                        <div class="dados">
                                            <h3 style="margin-bottom: 0px">
                                                <span style="font-weight: bold;">{{'Card' | translate}}:</span>
                                                {{ descricaoCartaoPagamento }} / Final {{ ultimosDigitos }}
                                            </h3>
                                        </div>                                        
                                    </div>
                                    <div style="margin-top: 20px;" class="float-right">
                                        <button style="margin: 4px;" class="btn btn-light btn-sm" (click)="nav.select(2)">{{'Previous' | translate}}</button>
                                        <button class="btn btn-sm btn-next" (click)="validarCartao()">{{'Confirm' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class=""></div>
            </div>
        </div>
        <!-- <div class="modal-footer mt-4" style="padding: 0px;">
              <button type="button" class="btn btn-outline-secondary" (click)="cancelar()">{{ 'Cancel' | translate}}</button>
          </div> -->
    </div>
</div>
<div id="scriptDevice">

</div>
<!-- <script type="text/javascript" id="" 
      src="{{urlSessionPagamento}}">
</script> -->