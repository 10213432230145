import { Component } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteService } from '../../service/cliente.service';
import { TelefoneCadastroComponent } from './telefone-cadastro.component';
import Swal from 'sweetalert2';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-telefone-lista',
    templateUrl: './telefones-lista.component.html'
  })
  
export class TelefonesListaComponent {
  public telefones: any[] = [];
  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    config: NgbModalConfig,
    private clienteService: ClienteService,
    private translate: TranslateService)
      {
        config.backdrop = 'static';
        config.keyboard = false;

        this.obterTelefones(this.clienteService.obterUsuario().documento);
      }
      
      open() {
        const modalRef = this.modalService.open(TelefoneCadastroComponent, { centered: true, size: 'lg'});
        
        modalRef.componentInstance.addPhone.subscribe(
          result => {
            this.obterTelefones(this.clienteService.obterUsuario().documento)
          },
          fail => {
          
          }
        );

        //this.teste(modalRef.componentInstance.addPhone);
      }

      deletar(id: number, telefone: string) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            //confirmButton: 'btn btn-success',
            //cancelButton: 'btn btn-danger'
          },
          //buttonsStyling: false
        })
  
        swalWithBootstrapButtons.fire({
          title: this.translate.instant('AlertInfo'),
          text: `${this.translate.instant('ConfirmDeletePhone')}?`,
          icon: 'question',
          confirmButtonColor: '#4a9f42',
          confirmButtonText: 'OK',
          cancelButtonText: this.translate.instant('Cancel'),
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            //this.spinner.show();
            this.clienteService.deleteTelefone(this.clienteService.obterUsuario().documento, id)
            .subscribe(
            result => {
              //this.spinner.hide();
              const index: number = this.telefones.findIndex(x => x.telefone.ddd == telefone.substring(0, 2) && x.telefone.numero === telefone.substring(2));
              if (index !== -1) {
                this.telefones.splice(index, 1);
              }
              Swal.fire({
                text: this.translate.instant('PhoneDeleted'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'success',                 
                confirmButtonColor: '#4a9f42'
              });              
            },
            fail => {
              //this.spinner.hide();
              Swal.fire({
                text: fail?.message,
                titleText: this.translate.instant('AlertInfo'),
                icon: 'warning',                 
                confirmButtonColor: '#4a9f42'
              });       
            }
          );
            
          }
        })
      }

      obterTelefones(documento: string){

        this.clienteService.getTelefones(documento)
         .subscribe(
          resultado => {
            if(resultado?.telefones?.length > 0 ){
              this.telefones = resultado.telefones;
            }           
          },
          fail =>{

          }
        );
        // if(documento.length == 11 || documento.length == 8){          

        // this.clienteService.getClientePF(this.clienteService.obterUsuario().documento)
        // .subscribe(
        //   resultado => {
        //     if("telefones" in resultado.cliente){
        //       this.telefones = resultado.cliente.telefones;
        //     }
        //   },
        //   fail =>{

        //   }
        // );
        // } else if(documento.length == 14) {
        //   this.clienteService.getClientePJ(documento)
        //     .subscribe(
        //       result => {
        //         if("telefones" in result.cliente){
        //           this.telefones = result.cliente.telefones;
        //         }
        //       },
        //       fail => {
              
        //       }
        //     );
        // }
      }
      
      get cadastrarTelefone() : boolean {
        return this.clienteService.funcionalidadePermitida(Funcionalidades.IncluirTelefone);
      }      
}