export class Telefone {
    telefone: TelefoneItem;
    // {
    //     tipo: string;
    //     ddd: number;
    //     numero: number;
    //     isPrincipal: boolean;
    // }
    constructor(tipo?: string, ddd?: number, numero?: number, isPrincipal?: boolean){
        this.telefone = new TelefoneItem(tipo, ddd, numero, isPrincipal);
        // this.telefone.tipo = tipo; 
        // this.telefone.ddd = ddd;
        // this.telefone.numero = numero;
        // this.telefone.isPrincipal = isPrincipal;
    }
}

export class TelefoneItem {
    tipo: string;
    ddd: number;
    numero: number;
    isPrincipal: boolean;
    constructor(tipo?: string, ddd?: number, numero?: number, isPrincipal?: boolean){
        this.tipo = tipo; 
        this.ddd = ddd;
        this.numero = numero;
        this.isPrincipal = isPrincipal;
    }
}

export class Telefones {
    telefones: Telefone[]
}
