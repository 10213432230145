<div class="modal-body">
    <div class="content">
        <form [formGroup]="frotistaForm">
        <div class="row">
            <div class="col-12 mb-3">
                <label for="documentoCliente">CPF:</label>
                <input class="form-control" formControlName="cpf" ngModel={{usuarioCorporativo.documentoUsuario}} [readonly]="isReadonly" [textMask]="{mask: mask}" >
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <label for="documentoCliente">Tipo Limite:</label>
                <select class="form-control" id="tipoLimite" formControlName="tipoLimite" >
                    <!-- <option [selected]="frotistaForm.get('tipoLimite').value === ''" value="">Sem Limite Definido</option> -->
                    <option *ngFor="let tipoLimiteFrotista of TipoLimiteFrotista" value="{{tipoLimiteFrotista.id}}">{{tipoLimiteFrotista.desc}}</option>
                </select>
            </div>
        </div>
        <div class="row" [hidden]="frotistaForm.get('tipoLimite').value === '' ">
            <div class="col-12">
                <label for="documentoCliente">Limite Máximo:</label>
                <input [required]="frotistaForm.get('tipoLimite').value !== ''" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" type="text" class="form-control" formControlName="limiteMaximo">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label for="documentoCliente">Admin?:</label>
                <input type="checkbox" class="form-control form-control-sm" formControlName="tipoUsuarioFrotista">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label for="documentoCliente">Ativo?:</label>
                <input type="checkbox" class="form-control form-control-sm" formControlName="ativo">
            </div>
        </div>
       </form>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate }}</button>
  <button type="submit" class="btn btn-outline-success" (click)="salvar()">{{ 'Save' | translate}}</button>
</div>