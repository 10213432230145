import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { ClienteService } from '../shared/service/cliente.service';
import { Funcionalidades } from '../model/funcionalidades';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})

 export class DashboardComponent implements OnInit {
  
  usuario: any;
  mostraFrotista: boolean = false;
  adminFrotista: boolean = false;
  frotista: boolean = false;
  perfilFrotistaSelecionado: boolean = false;   

    constructor(
      private router: Router,
      private clienteService: ClienteService) {
      
    }
    
    ngOnInit() {}   

    onLogout() {
      localStorage.removeItem('app.comercioEspecial');
      localStorage.removeItem('isLoggedin');
      localStorage.removeItem('app.token');
     
      localStorage.removeItem('app.userOLD');
      localStorage.removeItem('app.tokenOLD');
      localStorage.removeItem('app.frotista');
     
      localStorage.removeItem('app.adminFrotista');
      localStorage.removeItem('app.ativo');
      localStorage.removeItem('app.user');
      localStorage.removeItem('app.perfilUsuario');
      localStorage.removeItem('app.selectFrotista');
      localStorage.removeItem('app.IDEmpresaFrotistaCentroCusto');   
      localStorage.removeItem('app.documentoAdminFrotista'); 
      localStorage.removeItem('app.tokenAdminFrotista');
      localStorage.removeItem('app.fingerPrint');
      localStorage.removeItem('app.cnpjEmpresaFrotista');
      localStorage.removeItem('app.verDepoisCadastroLeadFrotista');

      this.router.navigate(['/login']);
      window.location.href = window.location.href;      
    }   

    get listarTelefones() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.ListarTelefones);
    }

    get cartaoListar() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.ListarCartoes);
    }

    get usuarioCorporativo() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.UsuariosCorporativos);
    }    

    get ehComercioEspecial() : boolean {
      return this.clienteService.funcionalidadePermitida(Funcionalidades.ComercioEspecial);
    }
 }