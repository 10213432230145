import { Component, Inject, LOCALE_ID } from "@angular/core";
import { formatNumber } from '@angular/common';
import { BoletoService } from '../../service/boleto.service';
import { Boleto, BoletoImprimir, BoletoDetalhe, BoletoParam } from 'src/app/model/boleto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoletoPagoComponent } from './boleto-pago.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ClienteService } from "../../service/cliente.service";

@Component({
    selector: 'app-boleto-lista',
    templateUrl: './boleto-lista.component.html'
})

export class BoletoListaComponent {
    boletoDetalhe: BoletoDetalhe;
    boletoParam: BoletoParam;
    boletos: Boleto[];

    constructor(private boletoService: BoletoService
                ,private clienteService: ClienteService
                ,private modalService: NgbModal
                ,private translate: TranslateService        
                ,@Inject(LOCALE_ID) private locale: string){
       
        this.getBoletos();               

        boletoService.movimentacaoBoletoEvent.subscribe(() => {
            this.getBoletos();
        });    
    }

    private getBoletos(){
        this.boletoService.getBoletos(this.boletoService.obterUsuario().documento)
            .subscribe(
                result => {
                    this.boletos = result;
                },
                fail => {
                    
                }
            );
    }

    formataNumero(valor: number) {
        return formatNumber(valor,this.locale,'1.2-2');
    }

    formatData (data) {
        
        if(data?.trim().length == 8){
            let ano = data.toString().substring(0, 4);
            let mes = data.toString().substring(4, 6);
            let dia = data.toString().substring(6, 8);

            let novaData = new Date(ano, parseInt(mes) - 1, dia);
            return novaData; 
        } else {
            return null;
        }
    }    

    open(boleto: BoletoImprimir) {   
        
        const tipoUsuario = this.clienteService.isFrotista()
                          && this.clienteService.getPerfilUsuario() === 'frotista' ? "frotista" : "comum";
      
      if(tipoUsuario === "frotista" 
            && (this.clienteService.centroCustoAdminFrotista === null
                || this.clienteService.centroCustoAdminFrotista?.length === 0)
        ){
            Swal.fire({
                text: 'Aguarde até o centro de custo ser carregado para impressão do boleto',
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });  
              return;            
      } 
        
        this.boletoDetalhe = {
            documento: this.boletoService.obterUsuario().documento,
            valor: boleto.Valor + (1 * 100 / 100000000),
            desconto: 0.00  + (1 * 100 / 100000000),
            tipo: 1,
            centrocusto: this.clienteService.centroCustoAdminFrotista === undefined ||
                            this.clienteService.centroCustoAdminFrotista === null ? "" :
                            this.clienteService.centroCustoAdminFrotista,
            instituicao: environment.Instituicao,
            recno: boleto.Recno,
            data: null,
            idEmpresaFrotistaCentroCusto: this.clienteService.getCentroDeCustoFrotista(),
            documentoAdminFrotista: this.clienteService.obterDocumentoOriginalAdminFrotista()
        };  
        
        const urlParamsRoute = `${this.boletoDetalhe.documento}/${this.boletoDetalhe.tipo}/${environment.Instituicao}/${this.boletoDetalhe.recno}`;
        const urlQueryStrings = `?code=${environment.FuncCodeGetBoleto}&valor=${this.boletoDetalhe.valor}&desconto=${this.boletoDetalhe.desconto}&centrocusto=${this.boletoDetalhe.centrocusto}`;

        var fileWindow = window.open(`${environment.BaseUrlFunction}site/getboleto/v1/${urlParamsRoute}${urlQueryStrings}`, "boleto", "width=960,height=800,scrollbars=1");
        fileWindow.open();          
      }

      visualizar(boleto: BoletoImprimir){
        
        const modalRef = this.modalService.open(BoletoPagoComponent, { centered: true, size: 'md' });

        modalRef.componentInstance.boletoPago = boleto;
      }
}