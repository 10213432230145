import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CompraCreditoComponent } from '../credito/compra-credito.component';
import { CadastroCADComponent } from '../cad/cadastro-cad.component';
import { ClienteService } from '../../service/cliente.service';
import { ExtratoService } from '../../service/extrato.service';
import { Funcionalidades } from 'src/app/model/funcionalidades';
import { CartaoService } from '../../service/cartao.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-saldo-lista',
    templateUrl: './saldo-lista.component.html'
})

export class SaldoListaComponent {
    saldoCredito: number = 0.00;
    saldoCAD: number = 0;
    centroDeCusto: string;
    
    constructor(
        private modalService: NgbModal
        ,config: NgbModalConfig
        ,private clienteService: ClienteService,
        private extratoService: ExtratoService,
        private cartaoService: CartaoService,
        private translate: TranslateService){

            config.backdrop = 'static';
            config.keyboard = false;
            
            this.obterSaldos();
            this.clienteService.movimentacaoSaldoEvent.subscribe(res => {               
                this.obterSaldos();
            });
    }

    private _OperacaoIniciada() : boolean {        
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        const inicioOperacaoConfig = environment.DataInicioOperacao.split('/');
        let inicioOperacaoDate = new Date(parseInt(inicioOperacaoConfig[2]), 
                                          parseInt(inicioOperacaoConfig[1]),
                                          parseInt(inicioOperacaoConfig[0]),0,0,0); 
        inicioOperacaoDate.setHours(0, 0, 0, 0);

        if(today >= inicioOperacaoDate){ 
            return true;
        }
        else{
            Swal.fire({
                text: this.translate.instant('OperationNotStarted'),
                titleText: this.translate.instant('AlertInfo'),
                icon: 'info',                 
                confirmButtonColor: '#4a9f42'
              });  
            return false;
        }            
    }
    
    openCredit(){

        // if(!this._OperacaoIniciada()){
        //     return;
        // } 

        const modalRefCredito = this.modalService.open(CompraCreditoComponent, { centered: true, scrollable: false, size: 'lg' });

        modalRefCredito.componentInstance.compraFinalizada
            .subscribe(
                result => {
                    this.obterSaldos();
                    this.extratoService.atualizarExtrato();
                    this.cartaoService.atualizarCartoes();
                },
                fail => {
                  
                }
            );
    }

    openCAD(){

        // if(!this._OperacaoIniciada()){
        //     return;
        // } 

        const modalRefCAD = this.modalService.open(CadastroCADComponent, { centered: true, scrollable: true, size: 'md' });

        modalRefCAD.componentInstance.compraFinalizada
            .subscribe(
                result => {
                    this.obterSaldos();
                    this.extratoService.atualizarExtrato();                   
                },
                fail => {
                  
                }
            );
    }

    obterSaldos(){        
        
        const documentoGetSaldo = this.clienteService.isFrotistaAdminLogado() ?
                                  this.clienteService.obterDocumentoOriginalAdminFrotista() : 
                                  this.clienteService.obterUsuario().documento;
        
        const tipoUsuario = this.clienteService.isFrotista()
                            && this.clienteService.getPerfilUsuario() === 'frotista' ? "frotista" : "comum";

        this
            .clienteService
                .getSaldos(
                    documentoGetSaldo, 
                    tipoUsuario )
        .subscribe(
            result => {
                if(this.clienteService.obterUsuario().frotista
                    && this.clienteService.getPerfilUsuario() === 'frotista') {

                        this.saldoCredito = result?.saldos[0]?.valor; 
                        this.centroDeCusto = result?.saldos[0]?.centroCusto;
                        this.saldoCAD = result?.saldos[0]?.quantidadeCad;
                        this.clienteService.centroCustoAdminFrotista = this.centroDeCusto;                                           
                } else {
                    this.saldoCredito = result.saldo;
                    if(("cartoes" in result)){                        
                        this.saldoCAD = result.cartoes.find(x => x.desc === "CAD")?.quantidade;    
                    }
                }
            },
            fail => {
              
            }
        );
    }

    get saldoCad() : boolean {
        return this.clienteService.funcionalidadePermitida(Funcionalidades.VisualizarSaldoCad);
    }

    get exibirCentroDeCusto() : boolean {
        return this.clienteService.funcionalidadePermitida(Funcionalidades.ExibirCentroDeCusto);
    }

    get ehComercioEspecial() : boolean {
        return this.clienteService.funcionalidadePermitida(Funcionalidades.ComercioEspecial);
    }

    get comprarCredito(): boolean {
        return this.clienteService.funcionalidadePermitida(Funcionalidades.ComprarCredito);
    }
}