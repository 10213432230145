import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { CartaoService } from '../../service/cartao.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cartao, CartaoValidacaoBinRetorno } from 'src/app/model/cartao';
import { ClienteService } from '../../service/cliente.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cartao-cadastro',
  templateUrl: './cartao-cadastro.component.html',
  providers: [ReCaptchaV3Service]
})

export class CartaoCadastroComponent {

  @ViewChild("numero") nameFieldNumeroCartao: ElementRef;

  cartao: Cartao;
  cartaoNovo: CartaoValidacaoBinRetorno = null;
  cartaoForm: FormGroup;
  public cssBandeira: string = "icon-bank-cards-white.png";
  public exibeIcon: boolean;
  public validandoCard: boolean;

  public mask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public pattern = ["AZaz"];

  public maskMonth = [/[0-1]/, /[0-9]/];
  public maskYear = [/[0-9]/, /[0-9]/];

  public tokenRecaptcha: string;

  @Output()
  selectCartao: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal
    , private cartaoService: CartaoService
    , private clienteService: ClienteService
    , private fb: FormBuilder
    , private translateService: TranslateService
    , private el: ElementRef
    , private recaptchaV3Service: ReCaptchaV3Service) {

    this.cartaoForm = this.fb.group({
      numero: ['', [Validators.required, Validators.minLength(10)]],
      portador: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
      mesExpiracao: ['', [Validators.required, Validators.max(12)]],
      anoExpiracao: ['', [Validators.required]],
      bandeira: ['', [Validators.required]],
      documentoTitular: ['', Validators.required]
    });

    this.tokenRecaptcha = '';
  }

  async salvar() {
    if (!this.cartaoForm.valid) {
      Swal.fire({
        text: this.translateService.instant('RequiredFields'),
        titleText: this.translateService.instant('AlertInfo'),
        icon: 'info',
        confirmButtonColor: '#4a9f42'
      });
      return;
    }

    if (this.cartaoForm.value.bandeira === null
      || this.cartaoForm.value.bandeira === ""
      || this.cartaoForm.value.bandeira === undefined) {
      Swal.fire({
        text: this.translateService.instant('InvalidTypeCardCredit'),
        titleText: this.translateService.instant('AlertInfo'),
        icon: 'info',
        confirmButtonColor: '#4a9f42'
      });
      return;
    }

    this.cartao = new Cartao();

    let documento = this.onlyNumber(this.clienteService.obterUsuario().documento);
    this.cartao.numero = this.onlyNumber(this.cartaoForm.value.numero);
    this.cartao.portador = this.cartaoForm.value.portador;
    this.cartao.bandeira = this.cartaoForm.value.bandeira;
    this.cartao.cvv = this.cartaoForm.value.cvv;
    this.cartao.mesExpiracao = this.cartaoForm.value.mesExpiracao;
    this.cartao.anoExpiracao = this.cartaoForm.value.anoExpiracao;
    this.cartao.cpfCnpj = this.cartaoForm.value.documentoTitular;

    this.recaptchaV3Service.execute('CadastroCartao').subscribe((token: string): void => {
      this.tokenRecaptcha = token;
      this.cartaoService.postCartao(this.cartao, documento, this.tokenRecaptcha)
        .subscribe(
          result => {

            Swal.fire({
              text: result.message,
              titleText: this.translateService.instant('AlertInfo'),
              icon: 'success',
              confirmButtonColor: '#4a9f42'
            });

            this.activeModal.close();
            this.selectCartao.emit(this.cartao);
          },
          fail => {

            Swal.fire({
              text: fail.message,
              titleText: this.translateService.instant('AlertInfo'),
              icon: 'info',
              confirmButtonColor: '#4a9f42'
            });

            this.activeModal.close();
          }
        )
    });
  }

  setaBandeira(event: any) {

    if (event.target.value.replace(" ", "").length >= 6 && this.cartaoNovo === null) {
      this.validandoCard = true;
      this.cartaoService.getValidarBinCartao(event.target.value.replace(" ", "").substring(0, 6)).subscribe(res => {

        this.validandoCard = false;
        setTimeout(() => {
          this.nameFieldNumeroCartao.nativeElement.focus();
        }, 0);

        if (res.tipo === "DEBITO") {
          Swal.fire({
            text: this.translateService.instant('OnlyCreditCardPayment'),
            titleText: this.translateService.instant('AlertInfo'),
            icon: 'info',
            confirmButtonColor: '#4a9f42'
          });
          this.cartaoNovo = null;
          this.cartaoForm.patchValue({ "bandeira": "" });
          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
          return;
        }

        if (res.bandeira.length > 0) {
          if (res.bandeira.toLowerCase() === "visa") this.cssBandeira = "logo-visa.jpg";
          else if (res.bandeira.toLowerCase() === "maestro") this.cssBandeira = "logo-master.jpg";
          else this.cssBandeira = `logo-${res.bandeira.toLowerCase()}.jpg`;
          this.cartaoNovo = res;
          this.cartaoForm.patchValue({ "bandeira": res.idBandeira });
          return;
        }
        else {
          this.cssBandeira = "icon-bank-cards-white.png";
          this.cartaoForm.patchValue({ "bandeira": "" });
          this.cartaoNovo = null;
          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
        }
      },
        error => {
          this.validandoCard = false;
          Swal.fire({
            text: error.message,
            titleText: this.translateService.instant('RechargeEwallet'),
            icon: 'info',
            confirmButtonColor: '#4a9f42'
          });

          this.cartaoNovo = null;
          this.cssBandeira = "icon-bank-cards-white.png";
          this.cartaoForm.patchValue({ "bandeira": "" });

          if (this.cartaoForm.value.numero.replace(" ", "").length > 6)
            this.cartaoForm.patchValue({ "numero": this.cartaoForm.value.numero.replace(" ", "").substring(0, 6) });
        });
    }
    else if (event.target.value.replace(" ", "").length < 6) {
      this.cartaoNovo = null;
    }
  }

  onPaste(event: ClipboardEvent) {

    if (event.clipboardData.getData('text') != this.cartaoForm.value.numero) {
      this.cartaoNovo = null;
      this.cartaoForm.patchValue({ "bandeira": "" });
      this.cartaoForm.patchValue({ "portador": "" });
      this.cartaoForm.patchValue({ "cvv": "" });
      this.cartaoForm.patchValue({ "mesExpiracao": "" });
      this.cartaoForm.patchValue({ "anoExpiracao": "" });
      this.cartaoForm.patchValue({ "documentoTitular": "" });
    }
  }

  verificaCartaoCampo(event) {
    if (event.target.value.replace(" ", "").length < 6) {
      this.cartaoNovo = null;
    }
  }

  onPasteDocumento(event: ClipboardEvent) {

    if (this.cartaoForm.get('documentoTitular').value.replace(/\D/g, '').length > 11) {
      this.mask = [/[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /[/]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
    } else {
      this.mask = [/[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /./, /[0-9]/, /[0-9]/, /[0-9]/, /-/, /[0-9]/, /[0-9]/];
    }
  }

  onlyNumber(param: string) {
    return param.replace(/\D/g, '');
  }
}