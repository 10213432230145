import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent {

  constructor(private translate: TranslateService,
    public router: Router) { }

  changeLang(language: string){
    this.translate.use(language);    
  }

  onLogout() {
      localStorage.removeItem('app.comercioEspecial');
      localStorage.removeItem('usuario');
      localStorage.removeItem('isLoggedin');
      localStorage.removeItem('app.token');
      localStorage.removeItem('app.user');

      localStorage.removeItem('app.userOLD');
      localStorage.removeItem('app.tokenOLD');
      localStorage.removeItem('app.frotista');
     
      localStorage.removeItem('app.adminFrotista');
      localStorage.removeItem('app.ativo');
      localStorage.removeItem('app.user');
      localStorage.removeItem('app.perfilUsuario');
      localStorage.removeItem('app.selectFrotista');
      localStorage.removeItem('app.IDEmpresaFrotistaCentroCusto');
      localStorage.removeItem('app.documentoAdminFrotista');
      localStorage.removeItem('app.tokenAdminFrotista');
      localStorage.removeItem('app.fingerPrint');
      localStorage.removeItem('app.cnpjEmpresaFrotista');
      localStorage.removeItem('app.verDepoisCadastroLeadFrotista');
    
      this.router.navigate(['/login']);
      window.location.href = window.location.href;
  }

  usuarioLogado(){
    if(localStorage.getItem('isLoggedin')){
      return true;
    } else {
      return false;
    }
  }
  
}
