import { Component, ViewChild } from "@angular/core";
import { NgbPanelChangeEvent, NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { FAQService } from '../../service/faq.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html'
})

export class FAQComponent {
  faqs = [];

    constructor(config: NgbAccordionConfig,
      private translate: TranslateService
      ,private faqService: FAQService){

        config.closeOthers = true;
        config.type = 'info';
      
        faqService.getFAQs(translate.currentLang).subscribe(
          result => {
            this.faqs = result;
          },
          fail => {
          
          }
        );
    }
    
    public beforeChange($event: NgbPanelChangeEvent) {
      
    }
}