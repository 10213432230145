<div class="modal-header mb-4">
    <h4 class="modal-title">{{ 'Terms of Use' | translate}}</h4>
</div>
<div class="modal-body" style="font-size: 12px;">
    <div class="container">
        <div [innerHTML]="htmlTermoUso" ></div>
        <!-- <div [innerHTML]="'Textos.Terms of Use' | translate">

        </div> -->
    </div>
</div>
<div class="modal-footer mt-4">
    <button type="submit" class="btn btn-outline-success" (click)="activeModal.close('Close click')">OK</button>
    <!--!saveUsername &&-->
</div>