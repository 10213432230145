import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageTranslationModule } from '../shared/modules/language-translation/language-translation.module';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginService } from './login.service';
import { CustomModule } from '../shared/modules/custom-module/custom.module';
import { ClienteService } from '../shared/service/cliente.service';

import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
    validation: false,
  };

@NgModule({
    imports: [
        CommonModule
        ,HttpClientModule
        ,LanguageTranslationModule
        ,TranslateModule
        ,RouterModule
        ,LoginRoutingModule
        ,NgbModule
        ,FormsModule
        ,ReactiveFormsModule
        ,FontAwesomeModule
        ,CustomModule
        ,TextMaskModule
        ,NgxMaskModule.forRoot(maskConfig)
    ],
    declarations: [
        LoginComponent
    ],
    exports:[
        LoginComponent
    ],
    providers: [
        LoginService
        ,ClienteService
    ],
    entryComponents: []
})
export class LoginModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(faSquare, faCheckSquare);
      }
}