<div class="card border-0 shadow-sm mb-4">
    <div class="col py-2 pr-0" style="border-bottom: solid 1px #4C4E51;">
        <div class="card-header border-0 bg-none" style="padding: 0px 15px;">
            <div class="row">
                <div class="col align-self-center">
                    {{ 'Phones' | translate}}
                </div>
                <div class="col-auto">
                    <div class="dropdown" ngbDropdown>
                        <button [hidden]="!cadastrarTelefone" class="btn dropdown-toggle btn-sm btn-link py-0 active" (click)="open()">
                            <i class="material-icons">add</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden h-100">
                <div class="card-body scroll-y h-150 p-0">
                    <div class="list-group roudned-0">
                        <div *ngFor="let telefone of telefones" class="list-group-item status-border" [ngClass]="telefone.tipo === 'Fixo' ? 'border-success': 'border-primary'">
                            <div class="row">
                                <div class="col-auto align-self-center" style="font-size: 11px;">
                                    <i class="material-icons">{{ telefone.telefone.tipo === "Fixo" ? "local_phone" : "smartphone"}} </i>
                                </div>
                                <div class="col pl-0" style="margin: auto !important;font-size: 11px;">
                                    <p style="margin: 0px !important;">{{(telefone.telefone.ddd + "" + telefone.telefone.numero) | mask:"(00) 00000-0000 || (00) 0000-0000" }}</p>
                                </div>
                                <div class="col-auto pr-0 align-self-center">
                                    <button class="btn btn-sm text-danger" (click)="deletar(telefone.telefone.id, telefone.telefone.ddd + '' + telefone.telefone.numero)" ><i class="material-icons">delete_forever</i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>