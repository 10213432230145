<div class="card border-0 shadow-sm mb-2">
    <div class="col py-2 pr-0 mb-2" style="border-bottom: solid 1px #4C4E51;">
        <div class="card-header border-0 bg-none" style="padding: 0px 15px;">
            <div class="row">
                <div class="col align-self-center">
                    {{ 'Balance' | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border-0 shadow-sm overflow-hidden">
                <div class="card-body p-1">
                    <div class="col-12 text-left">
                        <label>{{ (saldoCredito == 0) ? "R$ 0,00" : (saldoCredito < 0 ? '-' + (saldoCredito | currency:'BRL') : saldoCredito | currency:'BRL')  }} </label>
                        <button [disabled]="!comprarCredito" [hidden]="!comprarCredito" class="btn btn-sm btn-success btn-block btn-rounded" (click)="openCredit()">{{ 'Buy Credit' | translate}} &nbsp;<i class="material-icons">add_shopping_cart</i></button>
                    </div>
                    <div class="col-12">&nbsp;</div>
                    <div class="col-12 text-left" [hidden]="ehComercioEspecial">
                        <label>{{ saldoCAD }} CADs</label>
                        <button [hidden]="!saldoCad" class="btn btn-sm btn-success btn-block btn-rounded" 
                                (click)="openCAD()">{{ 'Buy CAD' | translate}}
                                <i class="material-icons">call_to_action</i>
                        </button> 
                    </div>
                    <div class="col-12 text-left" [hidden]="!exibirCentroDeCusto || ehComercioEspecial">
                        <label>Centro de Custo: <span style="font-weight: 500;margin-left: 10px;">{{ centroDeCusto }}</span> </label>                       
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 text-center" [hidden]="!frotista || (frotista && adminFrotista)">
            <label>{{ (saldoCredito == 0) ? "R$ 0,00" : (saldoCredito | currency:'BRL')}}</label>
        </div> -->
    </div>
</div>