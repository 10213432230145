import * as internal from "events";

export class Login {
    senha: string;
    appData: {
        bundleName: string
    };
    documento: string;
    deviceUUID: string;
    idAplicacao: number;
    sistemaOperacional: string;
    hashStorageDevice: string;   
}