<div class="modal-header">
    <h4 class="modal-title">{{'Registration Data' | translate}}</h4>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="clienteForm">
      <div class="container form-group">
        <div class="row mb-3" [hidden]="!exibePF">
          <div class="col-md-4">
              <label for="nomeCliente">{{ 'First Name' | translate }}: *</label>
              <input [required]="exibePF" class="form-control" id="nomeCliente" placeholder="{{ 'First Name' | translate }}" formControlName="nome">
          </div>
          <div class="col-md-8">
              <label for="validationCustom02">{{ 'LastName' | translate}}: *</label>
              <input [required]="exibePF" class="form-control" placeholder="{{ 'LastName' | translate}}" formControlName="sobrenome" />
          </div>
        </div>
        <div class="row mb-3" [hidden]="exibePF">
            <div class="col-md-12">
                <label for="nomeCliente">{{ 'Razao Social' | translate }}: *</label>
                <input [required]="!exibePF" class="form-control" id="nomeCliente" placeholder="{{ 'Razao Social' | translate }}" formControlName="razaoSocial">
            </div>            
        </div>
        <div class="row mb-3"  [hidden]="exibePF">
            <div class="col-md-12">
                <label for="nomeCliente">{{ 'Nome Fantasia' | translate }}: *</label>
                <input [required]="!exibePF" class="form-control" id="nomeCliente" placeholder="{{ 'Nome Fantasia' | translate }}" formControlName="nomeFantasia">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6" formGroupName="documento" style="border-left:none;">
                <label for="documentoCliente">{{'Document' | translate}}: *</label>
                <input required [disabled]="'true'" class="form-control" id="documentoCliente" placeholder="{{'Document' | translate}}" formControlName="numero" [textMask]="{mask: mask}" disabled="disabled"/>
            </div>
            <div class="col-md-6" [hidden]="!exibePF">
                <label for="dataNascimento">{{'DateOfBirth' | translate}}: *</label>
                    <div class="form-inline">
                        <div class="form-group">
                            <div class="input-group">                                
                                <input [required]="exibePF" formControlName="dataNascimento" class="form-control" placeholder="dd/mm/aaaa"
                                    [minDate]="{year: 1900, month:1, day: 1}" name="dataNascimento" ngbDatepicker #dataNascimento="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn  btn-outline-secondary calendar" (click)="dataNascimento.toggle()" type="button"></button>
                                </div>
                            </div>
                            
                        </div>                            
                    </div> 
                <!-- <label for="dataNascimentoCliente">{{'DateOfBirth' | translate}}: *</label>
                <input type="date" id="dataNascimentoCliente" class="form-control" placeholder="dd/mm/yyyy" formControlName="dataNascimento" /> -->
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <label for="emailCliente">{{'Email' | translate}}: *</label>
                <input required class="form-control" id="emailCliente" placeholder="{{'Email' | translate}}" formControlName="email" style="text-transform: lowercase" />
            </div>
        </div>
        <div formGroupName="endereco" style="border-left:none;">
          <div class="row mb-4">
            <div class="col-md-4 ">
                <label for="cepEnderecoCliente">{{'ZipCode' | translate }}: *</label>
                <input required class="form-control" id="cepEnderecoCliente" matInput mask="00000-000" placeholder="" formControlName="cep" (blur)="consultaCEP()" />
            </div>
            <div class="col-md-8 ">
                <label for="logradouroEnderecoCliente">{{'Address' | translate}}: *</label>
                <input required class="form-control" id="logradouroEnderecoCliente" placeholder="Endereço" formControlName="logradouro"  />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4">
                <label for="numeroEnderecoCliente">{{'Number' | translate }}: *</label>
                <input required class="form-control" placeholder="Número" formControlName="numeroLogradouro" />
            </div>
            <div class="col-md-4">
                <label for="complementoEnderecoCliente">{{'Complement' | translate}}</label>
                <input class="form-control" placeholder="" formControlName="complemento" />
            </div>
            <div class="col-md-4">
                <label for="bairroEnderecoCliente">{{'Neighborhood' | translate}}: *</label>
                <input required class="form-control" placeholder="" formControlName="bairro" />
            </div>
        </div>
        <div class="row">
          <div class="col-md-4">
              <label for="UFEnderecoCliente">{{'State' | translate}}: *</label>
              <select required class="form-control" id="UFEnderecoCliente" formControlName="estado" >
                  <option></option>
                  <option *ngFor='let estado of estados' value="{{estado.sigla}}">{{estado.nome}}</option>
              </select>
          </div>
          <div class="col-md-8">
              <label for="municipioEnderecoCliente">{{'City' | translate}}: *</label>
              <select required class="form-control" id="municipioEnderecoCliente" formControlName="cidade" >
                  <option></option>
                  <option *ngFor="let cidade of cidadesUF" value="{{cidade.nome}}">{{cidade.nome}}</option>
              </select>
          </div>
        </div>
        </div>
      </div>
      <div class="modal-footer mt-4" style="padding: 0px;">
        <button type="button" class="btn btn-outline-primary" (click)="openPassword()">{{ 'Password' | translate }}</button>
          <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">{{ 'Cancel' | translate}}</button>
          <button type="submit" class="btn btn-outline-success" (click)="salvarEdicaoCadastro()">{{ 'Save' | translate}}</button>
      </div>
    </form>
  </div>